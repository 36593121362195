import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import { List, ListItem, ListItemText } from "@mui/material";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import useSession from "../../../../hooks/useSession";
import defaultAddressOption from "../../../../helpers/defaultAddressOption";
import { useEffect, useMemo, useRef, useState } from "react";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

export default function LocationInput({
  value,
  setValue,
  inputValue,
  setInputValue,
  error,
  helperText,
  formKey,
  setFormValue,
  setZipCode,
  className,
  hideDefaultAddresses = false
}) {
  const [options, setOptions] = useState([]);
  const loaded = useRef(false);
  const { user } = useSession();
  const defaultAddress = user?.address?.fullAddress;
  const userAddresses = user?.addresses;

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(
          { ...request, language: "en" },
          callback
        );
      }, 200),
    []
  );

  const getPlaceDetails = (placeId) => {
    return new Promise((resolve, reject) => {
      if (!placesService.current) {
        reject(new Error("Places service not initialized"));
        return;
      }

      placesService.current.getDetails(
        {
          placeId: placeId,
          fields: [
            "address_component",
            "adr_address",
            "formatted_address",
            "geometry",
            "name",
            "place_id",
            "types",
          ],
        },
        (place, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(place);
          } else {
            reject(new Error(`Error getting place details: ${status}`));
          }
        }
      );
    });
  };

  const getAddressComponents = (place) => {
    const components = {};

    if (place && place.address_components) {
      place.address_components.forEach((component) => {
        const types = component.types;
        const longName = component.long_name;

        if (types.includes("street_number"))
          components.street_number = longName;
        if (types.includes("route")) components.route = longName;
        if (types.includes("street_address"))
          components.street_address = longName;
        if (types.includes("locality")) components.city = longName;
        if (types.includes("administrative_area_level_1"))
          components.state = longName;
        if (types.includes("administrative_area_level_2"))
          components.level2 = longName;
        if (types.includes("country")) components.country = longName;
        if (types.includes("postal_code")) components.zip = longName;
        if (types.includes("sublocality_level_1"))
          components.neighborhood = longName;
        if (types.includes("premise")) components.unit = longName;
      });

      if (
        !components.street_address &&
        components.street_number &&
        components.route
      ) {
        components.street = `${components.street_number} ${components.route}`;
        if (components.unit) components.street += ` ${components.unit}`;
      } else if (components.street_address) {
        components.street = components.street_address;
      }
    }
    return components;
  };

  useEffect(() => {
    if (!hideDefaultAddresses){
      const defaultAddressOptionValue = defaultAddressOption(defaultAddress);
      const userAddressesOptionValue = userAddresses?.map(({fullAddress})=>defaultAddressOption(fullAddress));
      if (defaultAddressOptionValue) {
        setOptions([defaultAddressOptionValue]);
      }
      if (userAddressesOptionValue) {
        setOptions(prev => [...prev, ...userAddressesOptionValue]);
      }
    }
  }, [defaultAddress, userAddresses]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }

    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );
    }

    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      if (!hideDefaultAddresses) {
        const defaultAddressOptionValue = defaultAddressOption(defaultAddress);
        const userAddressesOptionValue = userAddresses?.map(({fullAddress})=>defaultAddressOption(fullAddress));
        setOptions(defaultAddressOptionValue ? [defaultAddressOptionValue] : []);
        setOptions(prev => userAddressesOptionValue ? [...prev, ...userAddressesOptionValue] : prev);
      } else {
        setOptions([]);
      }
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (!hideDefaultAddresses) {
          const defaultAddressOptionValue = defaultAddressOption(defaultAddress);
          if (defaultAddressOptionValue) {
            newOptions.push(defaultAddressOptionValue);
          }
          const userAddressesOptionValue = userAddresses?.map(({fullAddress})=>defaultAddressOption(fullAddress));
          if (userAddressesOptionValue) {
            newOptions = [...newOptions, ...userAddressesOptionValue];
          }
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, defaultAddress, userAddresses]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: "100%" }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        if (newValue && newValue.place_id) {
          getPlaceDetails(newValue.place_id).then((placeDetails) => {
            const addressComponents = getAddressComponents(placeDetails);
            const completeAddress = { ...newValue, ...addressComponents };
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(completeAddress);
            setFormValue(formKey, {fullAddress: completeAddress?.description, ...completeAddress});
            setZipCode?.(addressComponents?.zip);
          });
        } else {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          const newValueInfo = newValue?.description === user?.address?.fullAddress ? user?.address : user?.addresses.find(({fullAddress})=> fullAddress === newValue?.description);
          setFormValue(formKey, {...newValue, ...newValueInfo});
          setZipCode?.(newValueInfo?.zip);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      className={className}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Add a location"
          error={error}
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        if (!!!option?.structured_formatting?.main_text_matched_substrings) {
          return;
        }
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <List style={{ width: "100%", textAlign: "start" }} {...props}>
            <ListItem
              container
              style={{
                textAlign: "start",
                width: "100%",
              }}
              alignItems="center"
            >
              <ListItemText
                primary={parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                secondary={
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                }
              />
              <ListItemText />
            </ListItem>
          </List>
        );
      }}
    />
  );
}
