export const faqs = [
  {
    id: 1,
    title: "How Does Payment Work?",
    content:
      "Helpful Heroes operates as a cashless platform, partnering with Stripe to provide secure and reliable credit card processing. Your card will only be charged once the job is completed and the firefighter confirms the work is finished. This ensures a safe, seamless transaction process for all parties involved.",
  },
  {
    id: 2,
    title: "Who Determines The Price?",
    content:
      "The price of the service is mutually agreed upon between you and the firefighter. After discussing the details of the project, you’ll work together to settle on a fair price before the work begins, ensuring transparency and satisfaction for both parties.",
  },
  {
    id: 3,
    title: "Is My Job Insured?",
    content:
      "Your job is fully insured. Helpful Heroes provides both liability and occupational accident insurance to ensure that you're protected in case of any unforeseen issues. This insurance coverage is in place to give you peace of mind, knowing that both you and the firefighter are safeguarded during the duration of the service.",
  },
  {
    id: 4,
    title: "Do I Have To Use My Credit Card?",
    content:
      "Yes. We require payment by credit card because it provides an official record of the transaction, which can be used if proof is needed for insurance purposes. When you pay with cash, there's no documented trail, meaning the transaction will not be covered by insurance. Using a credit card ensures both you and the service are properly protected.",
  },
  {
    id: 5,
    title: "Can You Explain The $50 Hold On My Credit Card?",
    content:
      "After selecting your firefighter and scheduling a date and time, a $50 hold will be placed on your credit card. This isn’t an actual charge but a temporary hold, which reduces your available balance. The funds will only be transferred to Helpful Heroes once they are applied toward the final cost of your project.",
  },
  {
    id: 6,
    title: "Why Have A $50 Hold In The First Place?",
    content:
      "By placing a $50 hold on your card when you choose a firefighter, you're ensuring their commitment to your project. It reserves their limited time away from the station, which they could potentially be using for other income generating opportunities.",
  },
  {
    id: 7,
    title: "How Can I Reimburse My Firefighter For Materials?",
    content:
      "Once the job is complete and you're paying through the platform, you can easily reimburse your firefighter for any materials they purchased. Your firefighter will simply add the cost of materials to the final payment, and it will be processed along with the service fee using your credit card. This ensures a seamless and secure transaction for both you and the firefighter.",
  },
  {
    id: 8,
    title: "Am I Allowed To Tip My Firefighter?",
    content:
      "Yes, you can absolutely tip your firefighter! Once the job is complete, you’ll have the option to add a tip through the platform when making your final payment. It’s a great way to show appreciation for their hard work.",
  },
];
