import React from "react";
import { Button, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useIntercom } from "react-use-intercom";
import BottomBar from "../BottomBar";
import contactUs from "../../../assets/images/contact-us-footer.svg";
import faqs from "../../../assets/images/faqs.svg";

const useStyles = makeStyles((theme) => ({
  isMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  footerContainer: {
    marginTop: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    bottom: "0rem",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    marginTop: "5rem",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
  boxContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1.125rem",
  },
  whiteBoxContainer: {
    height: "18.688rem",
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    width: "100%",
  },
  whiteBoxContent: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "0.75rem",
    paddingTop: "1.375rem",
    paddingRight: "1.875rem",
    paddingLeft: "1.875rem",
    paddingBottom: "1.375rem",
    border: "solid 1px #e8e8e8",
    position: "relative",
    width: "50%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "0.5rem",
      paddingTop: "0.5rem",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      justifyContent: "space-between",
    },
  },
  whiteBoxTextContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    zIndex: "10",
    cursor: "pointer",
  },
  whiteBoxSubtitle: {
    fontFamily: "Urbanist-Regular",
    paddingTop: "0.563rem",
    textAlign: "left",
    maxWidth: "60%",
    lineHeight: "1.5rem",
    zIndex: "10",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
    },
  },
  whiteBoxImage: {
    position: "absolute",
    right: "2.188rem",
    bottom: "1.25rem",
    cursor: "pointer",
    [theme.breakpoints.down("lg")]: {
      right: "0rem",
      bottom: "0rem",
      width: "90%",
      position: "relative",
    },
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  navyBoxContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#002E65",
    borderRadius: "0.75rem",
    width: "100%",
    color: "white",
    paddingTop: "2.938rem",
    paddingBottom: "3.25rem",
  },
  row: {
    display: "flex",
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
}));

const Footer = () => {
  const classes = useStyles();
  const { boot, show } = useIntercom();

  const handleOpenContactSupport = () => {
    boot();
    show();
  };

  return (
    <>
      <div className={classes.footerContainer}>
        <div className={classes.boxContainer}>
          <div className={classes.whiteBoxContainer}>
            <div className={classes.whiteBoxContent}>
              <div
                className={classes.whiteBoxTextContainer}
                onClick={() => handleOpenContactSupport()}
              >
                <Typography variant="title3">Get Support</Typography>
                <Typography
                  variant="subtitle2"
                  color="darkGray"
                  className={classes.whiteBoxSubtitle}
                >
                  Having issues with your project? Contact our support team.
                </Typography>
              </div>
              <img
                alt="img"
                src={contactUs}
                className={classes.whiteBoxImage}
              />
            </div>
            <Link
              className={classes.whiteBoxContent}
              href={`${process.env.REACT_APP_WEB_URL}/faq`}
              sx={{ textDecoration: "none" }}
            >
              <div className={classes.whiteBoxTextContainer}>
                <Typography variant="title3">
                  Frequently Asked Questions
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="darkGray"
                  className={classes.whiteBoxSubtitle}
                >
                  Browse frequently asked questions about projects,
                  firefighters, and payment.
                </Typography>
              </div>
              <img alt="img" src={faqs} className={classes.whiteBoxImage} />
            </Link>
          </div>
          <div className={classes.navyBoxContent}>
            <Typography variant="title3" color="white">
              Enjoying Helpful Heroes?
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "white",
                paddingTop: "0.563rem",
                paddingBottom: "1.438rem",
              }}
            >
              Leave a review, feedback, or share with a friend
            </Typography>
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "#002E65",
                marginRight: 8,
              }}
              name="no"
              onClick={() => {
                //TODO: create screen to add reviews
              }}
            >
              Write a Review
            </Button>
          </div>
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default Footer;
