import { Box, Button, Typography } from "@mui/material";
import { LocationOn } from "@mui/icons-material";

const AddressCard = ({
  address,
  canRemove = false,
  onSelectAddress,
  onRemoveAddress,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        backgroundColor: "#FAFAFA",
        borderRadius: "12px",
        border: "1px solid #E8E8E8",
        padding: "18px",
        marginBottom: "15px",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
        <LocationOn color="primary" fontSize="medium" />

        <Box display="flex" flexDirection="column">
          {address.label && (
            <Typography variant="h5" mb={0.5}>
              {address.label}
            </Typography>
          )}
          <Typography>{address.fullAddress}</Typography>
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" gap={2}>
        <Button
          variant="text"
          size="small"
          sx={{ padding: "8px" }}
          onClick={() => onSelectAddress(address)}
        >
          Edit
        </Button>

        {canRemove && (
          <Button
            variant="text"
            size="small"
            sx={{ padding: "8px" }}
            onClick={() => onRemoveAddress(address.id)}
          >
            Remove
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddressCard;
