import { Typography, Box, IconButton, TextField, Button } from "@mui/material";

//Icons
import { ArrowBack } from "@mui/icons-material";

const ProjectConfirmation = ({
  profile,
  message,
  setMessage,
  onPreviousStep,
  onConfirm,
}) => {
  return (
    <>
      <IconButton onClick={onPreviousStep}>
        <ArrowBack />
      </IconButton>

      <Typography variant="h3" align="center" mb={2}>
        Project Confirmation
      </Typography>

      <Typography align="center" mb={2}>
        You’re almost done! Send your firefighter a message to confirm the
        project details and start your project.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={3}
        gap={3}
      >
        <TextField
          fullWidth
          multiline
          rows={6}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder={
            "Let them know about any extra details needed to complete the job, where to park, timing restrictions"
          }
        />

        <Button
          variant="contained"
          fullWidth
          disabled={!message}
          onClick={onConfirm}
        >
          Confirm Project
        </Button>
      </Box>
    </>
  );
};

export default ProjectConfirmation;
