import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { useMutation } from "@tanstack/react-query";

//Components
import Template from "../../components/shared/Template";
import HelpfulHeroesButton from "../../components/shared/HelpfulHeroesButton";

//Services
import ProjectService from "../../services/projectService";

//Utils
import { ROUTES } from "../../utils/constants";

//Images
import cancelProjectImage from "../../assets/images/addTip.svg";

const useStyles = makeStyles((theme) => ({
  buttons: {
    width: 800,
    marginTop: 23,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const reasons = [
  { name: "scheduling", label: "Scheduling" },
  { name: "already_completed", label: "Already completed" },
  { name: "communication", label: "Communication" },
  { name: "pricing", label: "Pricing" },
  { name: "found_help_elsewhere", label: "Found help elsewhere" },
  { name: "no_longer_needed", label: "No longer needed" },
  { name: "other", label: "Other" },
];

const CancelProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const classes = useStyles();

  const [cancelReason, setCancelReason] = useState("already_completed");
  const [type, setType] = useState(null);

  const cancelProject = useMutation({
    mutationFn: ({ id, cancelReason }) => {
      return type === "Job"
        ? ProjectService.cancelJob(id, cancelReason)
        : ProjectService.cancelWork(id, cancelReason);
    },
    onSuccess: (data) => {
      toast.success(
        <Typography color="success">The project has been canceled.</Typography>,
        {
          position: "top-center",
        }
      );
      navigate(ROUTES.PROJECT_CANCELLED);
    },
    onError: () => {
      toast.error(
        <Typography color="error">
          There has been an error cancelling your project. Please try again or
          contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
    },
  });

  const handleClick = () => cancelProject.mutate({ id, cancelReason });

  useEffect(() => {
    const storedType = localStorage.getItem("type");
    if (storedType) {
      setType(storedType);
    }
  }, []);

  return (
    <Template
      title="Cancel Project"
      subtitle="Cancelling? We understand, just let us know why."
      button="Confirm & Cancel Project"
      children={
        <div className={classes.buttons}>
          {reasons.map((reason) => (
            <HelpfulHeroesButton
              key={reason.name}
              name={reason.name}
              state={cancelReason}
              setState={setCancelReason}
              children={reason.label}
            />
          ))}
        </div>
      }
      handleClick={handleClick}
      image={cancelProjectImage}
    />
  );
};

export default CancelProject;
