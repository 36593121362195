import React, { useMemo } from "react";
import useSelectedChatBoard from "../../hooks/useSelectedChatBoard";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HelpfulHeroesTextField from "../shared/HelpfulHeroesTextField";
import SendIcon from "@mui/icons-material/Send";
import { makeStyles } from "@mui/styles";
import { sendMessage } from "../../redux/actions/projectActions";
import UploadImageList from "../shared/UploadImageList";
import getBase64 from "../../helpers/getBase64";
import {
  Avatar,
  Divider,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  FULFILLED,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import useSession from "../../hooks/useSession";
import ChipStatus from "../shared/ChipStatus";
import moment from "moment";
import { JOB_STATUSES, ROUTES } from "../../utils/constants";
import { groupMessagesByDate } from "../../helpers/dates";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  leftBlock: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "30%",
  },
  rightBlock: {
    width: "70%",
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
  rightBlockHeader: {
    padding: "22px",
  },
  messagesBody: {
    display: "flex",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
      alignSelf: "center",
    },
    [theme.breakpoints.down("sm")]: {},
  },
  messagesContainer: {
    height: "370px",
    overflowY: "scroll",
    marginBottom: "2rem",
    paddingTop: "22px",
    paddingInline: "22px",
  },
  listItem: {
    padding: "0 !important",
    alignItems: "center !important",
    cursor: "pointer",
  },
  sendButton: {
    padding: "22px",
  },
}));

const ChatMessages = ({ jobId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const messagesContainerRef = React.useRef(null);
  const { user } = useSession();
  const { selectedChatBoard } = useSelectedChatBoard();
  const [message, setMessage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const searchTerm = "data:image/";
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    scrollToBottom();
  }, [selectedChatBoard]);

  const onSubmit = async (e) => {
    const response = await dispatch(
      sendMessage({ jobId: jobId, message: { content: message } })
    );

    if (response.meta.requestStatus === FULFILLED) {
      setMessage("");
    }
    if (response.meta.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">
          There has been an error sending your message. Please try again or
          contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  const onInputEnter = async (e) => {
    if (e.key === "Enter") {
      const response = await dispatch(
        sendMessage({
          jobId: jobId,
          message: { content: message },
        })
      );
      if (response.meta.requestStatus === FULFILLED) {
        setMessage("");
      }
      if (response.meta.requestStatus === REJECTED) {
        toast.error(
          <Typography color="error">
            There has been an error sending your message. Please try again or
            contact support.
          </Typography>,
          {
            position: "top-center",
          }
        );
      }
    }
  };

  const handleSendImage = (file) => {
    setImages(images.concat({ data: file, filename: "job-image.png" }));
    images.concat({ data: file, filename: "job-image.png" })?.map((img) => {
      return dispatch(
        sendMessage({ jobId: jobId, message: { content: img.data } })
      );
    });
    setImages([]);
  };

  const onAddImage = (file) => {
    getBase64(file, handleSendImage);
  };

  const onRemoveImage = (index) => {
    const newArray = [...images];
    newArray.splice(index, 1);
    setImages(newArray);
  };

  const handleRedirect = () => {
    if (!selectedChatBoard?.jobPostId) return;

    localStorage.setItem("type", selectedChatBoard?.jobPostType);
    navigate(`${ROUTES.PROJECT_DETAIL}/${selectedChatBoard?.jobPostId}`);
  };

  const groupedMessages = useMemo(() => {
    return groupMessagesByDate(selectedChatBoard?.messages || []);
  }, [selectedChatBoard]);

  return (
    <>
      {selectedChatBoard?.jobImage !== undefined && (
        <div className={classes.rightBlock}>
          <div className={classes.rightBlockHeader}>
            <ListItem
              className={classes.listItem}
              alignItems="flex-start"
              onClick={handleRedirect}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    width: "2.8rem",
                    height: "2.8rem",
                    marginRight: "1.5rem",
                    bgcolor: "background.paper",
                  }}
                  alt="Remy Sharp"
                  src={selectedChatBoard?.jobImage}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h3">
                    {selectedChatBoard?.jobPostTitle}
                  </Typography>
                }
                secondary={
                  <Typography sx={{ my: "0.1rem" }} variant="body2">
                    {selectedChatBoard?.jobService}
                  </Typography>
                }
              />
              <ChipStatus
                status={
                  selectedChatBoard?.jobPostStatus === JOB_STATUSES.ASSIGNED
                    ? JOB_STATUSES.SCHEDULED
                    : selectedChatBoard?.jobPostStatus
                }
              />
            </ListItem>
          </div>

          <Divider />

          <div className={classes.messagesContainer}>
            {groupedMessages.length > 0 ? (
              groupedMessages.map(({ date, messages }) => (
                <div key={date}>
                  <Typography textAlign="center">{date}</Typography>

                  {Array.isArray(messages) && messages.length > 0 ? (
                    messages.map((message, index) => (
                      <ListItem
                        key={index}
                        className={classes.listItem}
                        alignItems="flex-start"
                      >
                        <ListItemAvatar>
                          <Avatar
                            sx={{
                              width: "2.8rem",
                              height: "2.8rem",
                              marginRight: "1.5rem",
                              bgcolor: "background.paper",
                            }}
                            style={{
                              backgroundColor: message?.sent
                                ? "#f1f1f1"
                                : "#022752",
                            }}
                            src={
                              !message?.sent
                                ? selectedChatBoard?.contact?.image
                                : user.profilePicture
                            }
                          >
                            {`${selectedChatBoard?.contact?.name.charAt(0)}`}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <div style={{ display: "flex" }}>
                              <Typography variant="title3">
                                {message?.contactName}
                              </Typography>

                              <Typography variant="body1">
                                {moment(message?.createdAt).format("hh:mm a")}
                              </Typography>
                            </div>
                          }
                          secondary={
                            message?.content?.lastIndexOf(searchTerm) ? (
                              <Typography
                                sx={{ my: "0.1rem", color: "#333333" }}
                                variant="h7"
                              >
                                {message?.content}
                              </Typography>
                            ) : (
                              <img
                                alt="img"
                                src={message.content}
                                className={classes.messagesContainer}
                              />
                            )
                          }
                        />
                      </ListItem>
                    ))
                  ) : (
                    <Typography sx={{ color: "#888", textAlign: "center" }}>
                      No messages for this date.
                    </Typography>
                  )}
                </div>
              ))
            ) : (
              <Typography sx={{ color: "#888", textAlign: "center" }}>
                No messages available.
              </Typography>
            )}
            <div ref={messagesContainerRef} />
          </div>

          <div className={classes.sendButton}>
            <HelpfulHeroesTextField
              onKeyPress={onInputEnter}
              value={message}
              handleChange={(e) => setMessage(e.target.value)}
              placeholder="Write a message..."
              sx={{ width: "100%", marginBottom: "10px" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={onSubmit} edge="end">
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start">
                      <UploadImageList
                        images={images}
                        onAddImage={onAddImage}
                        onRemoveImage={onRemoveImage}
                        isAttachFiles
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessages;
