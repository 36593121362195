import { useState } from "react";
import { Box, Typography, Stack, LinearProgress, Divider } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

//Components
import ReviewCard from "./ReviewCard";

//Services
import ProjectService from "../../../services/projectService";

//Icons
import StarIcon from "@mui/icons-material/Star";

const Reviews = ({ ffId, reviewsCount, averageRating }) => {
  const [selectedRating, setSelectedRating] = useState(null);

  const { data, isLoading } = useQuery({
    enabled: !!ffId,
    queryKey: ["getFirefighterReviews"],
    queryFn: async () => {
      const data = await ProjectService.getFirefighterReviews(ffId);
      return data;
    },
  });

  const reviewsByRating = data?.reviews?.reduce(
    (count, review) => {
      const { rating } = review;
      count[rating] = count[rating] || [];
      count[rating].push(review);
      return count;
    },
    { 1: [], 2: [], 3: [], 4: [], 5: [] }
  ) || { 1: [], 2: [], 3: [], 4: [], 5: [] };

  const fullReviewsOverview = {
    5: 0,
    4: 0,
    3: 0,
    2: 0,
    1: 0,
    ...data?.reviewsOverview,
  };

  const percentages = Object.entries(fullReviewsOverview)
    .map(([rating, value]) => ({
      rating: parseInt(rating, 10),
      percentage: (value / reviewsCount) * 100 || 0,
    }))
    .sort((a, b) => b.rating - a.rating);

  const handleRatingPress = (rating) => {
    setSelectedRating(rating);
  };

  if (isLoading) {
    return <Typography align="center">Loading...</Typography>;
  }

  return (
    <>
      <Box display="flex" alignItems="center" mt={1}>
        <StarIcon color="primary" />

        <Typography variant="h5" ml={1}>
          {averageRating.toFixed(1)} ({reviewsCount} ratings)
        </Typography>
      </Box>

      <Stack spacing={2} mt={2}>
        {percentages.map((item) => (
          <Box
            key={item.rating}
            display="flex"
            alignItems="center"
            onClick={() => handleRatingPress(item.rating)}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant="body1" color="primary" mr={2}>
              {item.rating}
            </Typography>

            <LinearProgress
              variant="determinate"
              value={item.percentage}
              color="primary"
              sx={{ flex: 1 }}
            />

            <Typography variant="body1" color="primary" ml={2}>
              {item.percentage.toFixed(0)}%
            </Typography>
          </Box>
        ))}
      </Stack>

      {selectedRating && (
        <Stack spacing={1} mt={2}>
          {reviewsByRating[selectedRating].map((review, index) => (
            <Box key={review.id}>
              <ReviewCard {...review} />

              {index < reviewsByRating[selectedRating].length - 1 && (
                <Divider sx={{ my: 2 }} />
              )}
            </Box>
          ))}
        </Stack>
      )}
    </>
  );
};

export default Reviews;
