import { useState } from "react";
import { useNavigate } from "react-router";
import { Button } from "@mui/material";

//Components
import ViewInvoiceModal from "../ViewInvoiceModal";

//Utils
import { JOB_STATUSES, ROUTES } from "../../../utils/constants";

const ProjectDetailsActions = ({ project, type, handleOpenEditWorkModal }) => {
  const navigate = useNavigate();

  const [openViewInvoice, setOpenViewInvoice] = useState(false);

  const isWork = type === "Work";

  const handleEdit = () => {
    if (isWork) {
      handleOpenEditWorkModal();
    } else {
      localStorage.setItem("serviceLocal", project?.service?.name);
      navigate(ROUTES.NEW_PROJECT);
    }
  };

  const handleCancel = () => {
    navigate(`${ROUTES.CANCEL_PROJECT}/${project.id}`);
  };

  const handleOpenViewInvoice = () => {
    setOpenViewInvoice(true);
  };

  const handleCloseViewInvoice = () => {
    setOpenViewInvoice(false);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      {project.status !== JOB_STATUSES.COMPLETED ? (
        <>
          {project.status !== JOB_STATUSES.CANCELLED && (
            <Button
              variant="text"
              sx={{
                display: { xs: "none", sm: "flex" },
                color: "#ACACAC",
              }}
              onClick={handleCancel}
            >
              Cancel Project
            </Button>
          )}

          {project.status === JOB_STATUSES.PENDING && (
            <Button
              sx={{
                display: { xs: "none", sm: "flex" },
              }}
              onClick={handleEdit}
              variant="contained"
            >
              Edit Project
            </Button>
          )}
        </>
      ) : (
        isWork && (
          <Button
            sx={{
              display: { xs: "none", sm: "flex" },
            }}
            onClick={handleOpenViewInvoice}
            variant="contained"
          >
            View Invoice
          </Button>
        )
      )}

      {openViewInvoice && project.status === JOB_STATUSES.COMPLETED && (
        <ViewInvoiceModal
          open={openViewInvoice}
          project={project}
          onClose={handleCloseViewInvoice}
        />
      )}
    </div>
  );
};

export default ProjectDetailsActions;
