import * as React from "react";
import { Typography, Box, Alert, Collapse, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Icons
import CloseIcon from "@mui/icons-material/Close";
import RequestQuote from "@mui/icons-material/RequestQuote";

const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  alertContainer: {
    backgroundColor: "#FFFFFF",
    paddingBlock: "12px",
    borderRadius: "12px",
    marginTop: "47px",
    marginBottom: "15px",
    border: "1px solid #E8E8E8",
  },
  alertIcon: {
    height: 30,
    width: 30,
    marginTop: "10px",
    marginInline: "10px",
  },
  description: { opacity: 0.7 },
}));

const ReviewInvoiceAlert = ({ project }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  if (Object.keys(project.fireFighter ?? {}).length === 0) return null;

  return (
    <Box className={classes.container}>
      <Collapse in={open}>
        <Alert
          className={classes.alertContainer}
          icon={<RequestQuote className={classes.alertIcon} color="primary" />}
          action={
            <IconButton
              aria-label="close"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon color="primary" />
            </IconButton>
          }
        >
          <Typography variant="body1" color="primary">
            Review Invoice
          </Typography>

          <Typography
            variant="body2"
            color="primary"
            className={classes.description}
          >
            {project.fireFighter.fullName} has completed your project. Let’s
            take a look at the final invoice.
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default ReviewInvoiceAlert;
