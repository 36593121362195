import * as yup from "yup";

export const validationSchema = yup.object({
  firstName: yup.string().required("First Name is a required field"),
  lastName: yup.string(),
  email: yup
    .string()
    .required("Email is a required field")
    .email("Invalid Email format"),
  phoneNumber: yup.string().required("Mobile Phone Number is a required field"),
  addressAttributes: yup.object({
    fullAddress: yup.string(),
    street: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
  }),
  serviceType: yup.number(),
  propertyType: yup.number(),
  description: yup.string(),
  availabilities: yup.array(),
  usedHelpfulHeroes: yup.boolean(),
  aboutUs: yup.number(),
});
