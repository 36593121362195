import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import moment from "moment";

const useStyles = makeStyles(() => ({
  container: {
    marginBlock: "20px",
    borderRadius: "12px",
    border: "1px solid #E8E8E8",
    backgroundColor: "white",
    padding: "1rem",
  },
  availabilityContainer: {
    borderRadius: "12px",
    backgroundColor: "#FAFAFA",
    padding: "15px",
    marginBottom: "12px",
  },
}));

const MyAvailability = ({ availability }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary" align="left" mb={1} p={3}>
        My Availability
      </Typography>
      <div>
        {availability?.map(({ id, availableAt }) => (
          <div key={id} className={classes.availabilityContainer}>
            <Typography variant="h5" align="left">
              {moment(availableAt, "ddd MMMM D, hh:mma").format("hh:mm A MMM Do")}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};
export default MyAvailability;
