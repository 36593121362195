import * as React from "react";
import { Typography, Box, Alert, Collapse, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Icons
import GroupsIcon from "@mui/icons-material/Groups";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  container: { width: "100%" },
  alertContainer: {
    backgroundColor: "#2B8CED",
    paddingBlock: "12px",
    borderRadius: "12px",
    marginTop: "47px",
    marginBottom: "15px",
  },
  alertIcon: {
    color: "#FFFFFF",
    height: 30,
    width: 30,
    marginTop: "10px",
    marginInline: "10px",
  },
  description: { opacity: 0.7 },
}));

const SelectFirefighterAlert = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  return (
    <Box className={classes.container}>
      <Collapse in={open}>
        <Alert
          className={classes.alertContainer}
          icon={<GroupsIcon className={classes.alertIcon} />}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="inherit" sx={{ color: "#FFFFFF" }} />
            </IconButton>
          }
        >
          <Typography variant="body1" color="white">
            Select your Firefighter
          </Typography>

          <Typography
            variant="body2"
            color="white"
            className={classes.description}
          >
            Review the estimates and select a firefighter to start your project.
          </Typography>
        </Alert>
      </Collapse>
    </Box>
  );
};

export default SelectFirefighterAlert;
