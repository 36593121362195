import httpClient from "../httpClient";
import parseError from "../utils/parseError";

class UserService {
  static login(user) {
    return httpClient.post("/users/sign_in", user);
  }

  static logout() {
    return httpClient.delete("/users/sign_out");
  }

  static signUp(user) {
    return httpClient.post("/users", user);
  }

  static forgotPassword(email) {
    return httpClient.post("/user/user_codes/sms", email);
  }

  static resetPassword(password, passwordConfirmation, authHeaders) {
    return httpClient.put("/users/password", {
      password,
      passwordConfirmation,
    });
  }

  static passwordCodeVerification(payload) {
    return httpClient.post("/user/user_codes/validate", payload);
  }

  static getUser() {
    return httpClient.get("/user/profile");
  }

  static getCreditCard() {
    return httpClient.get("/user/credit_cards");
  }

  static getNotifications() {
    return httpClient.get("/user/notifications");
  }

  static newNotifications() {
    return httpClient.get("/user/pending_notifications");
  }

  static getUnseenMessages() {
    return httpClient.get("/user/chat_boards/unseen_messages");
  }

  static getJobs() {
    return httpClient.get("/user/jobs");
  }

  static getChatBoards() {
    return httpClient.get("/user/chat_boards");
  }

  static updateUser(data) {
    return httpClient.put("/user", data);
  }

  static verifyUser(data) {
    return httpClient.post("/user/verifications/verify", data);
  }

  static verifyResetPasswordCode(data) {
    return httpClient.post("/user/user_codes/validate", data);
  }

  static resendVerifyCode() {
    return httpClient.post("/user/verifications");
  }

  static addCreditCard(data) {
    return httpClient.post("/user/credit_cards", data);
  }

  static addCoupon(id) {
    return httpClient.get(`/user/coupons/${id}`);
  }

  static validatePromoCode(code) {
    return httpClient.post(`user/discount_coupons/valid_coupon`, {
      discount_coupon: { code },
    });
  }

  static async getAllJobs(params = {}) {
    try {
      const { data } = await httpClient.get(`/user/job_posts`, {
        params,
      });

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async createAccount(user) {
    try {
      const { data } = await httpClient.post("/users", user);

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async resendVerifyCodeByEmail(email) {
    try {
      const { data } = await httpClient.post(
        "/user/verifications/resend_via_email",
        email
      );

      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
}

export default UserService;
