import { useState } from "react";

import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Hooks
import { useProjectDetails } from "../../../hooks/useProject";

const useStyles = makeStyles((theme) => ({
  projectTitleInput: {
    width: "100%",
    border: "none",
    outline: "none",
    fontSize: "50px",
    marginBottom: "10px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#7C92AA",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.main,
    },
  },
}));

const CreateProjectTitle = ({ setValue, watch }) => {
  const classes = useStyles();
  const { projectDetails } = useProjectDetails();

  const [error, setError] = useState(false);

  const title = watch("title");

  return (
    <TextField
      placeholder="+ Add Project Title"
      variant="standard"
      value={title}
      defaultValue={projectDetails?.title || ""}
      onChange={(e) => setValue("title", e.target.value)}
      onBlur={() => setError(!title ? true : false)}
      className={classes.projectTitleInput}
      InputProps={{ disableUnderline: true, style: { fontSize: "40px" } }}
      error={error}
      helperText={error && "Project Title is required"}
    />
  );
};

export default CreateProjectTitle;
