import React from "react";
import { Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import { useIntercom } from "react-use-intercom";
import { LEGAL_LINKS } from "../../../utils/contants";

const useStyles = makeStyles((theme) => ({
  isMobile: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  icons: {
    display: "flex",
    alignItems: "center",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingRight: "2rem",
      marginBottom: 36,
      marginTop: "1rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0.5rem",
    },
  },
  bottomBar: {
    marginTop: "3rem",
    width: "100%",
    height: "4.375rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    bottom: 0,
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  texts: {
    paddingLeft: "10rem",
    display: "flex",
    alignItems: "baseline",
    height: "1.7rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      flexDirection: "column",
      alignItems: "center",
      height: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
    },
  },
  links: {
    width: "20rem",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginTop: "1rem",
    },
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    width: "13.25rem",
    alignSelf: "center",
    alignItems: "baseline",
  },
}));

const BottomBar = () => {
  const classes = useStyles();
  const { boot, show } = useIntercom();

  const handleOpenContactSupport = () => {
    boot();
    show();
  };

  return (
    <div className={classes.bottomBar}>
      <div className={classes.texts}>
        <div className={classes.links}>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            © 2025 Helpful Heroes
          </Typography>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Link
            variant="body3"
            color="#acacac"
            sx={{ textDecoration: "none" }}
            onClick={() => window.open(LEGAL_LINKS.PRIVACY_POLICY)}
          >
            Privacy
          </Link>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Link
            variant="body3"
            color="#acacac"
            sx={{ textDecoration: "none" }}
            onClick={() => window.open(LEGAL_LINKS.TERMS_OF_USE)}
          >
            Terms
          </Link>
          <Typography variant="body3" sx={{ color: "#acacac" }}>
            •
          </Typography>
          <Link
            variant="body3"
            color="#acacac"
            sx={{ textDecoration: "none" }}
            onClick={() => handleOpenContactSupport()}
          >
            Support
          </Link>
        </div>
      </div>
      <div className={classes.icons}>
        <Link
          href={process.env.REACT_APP_WEB_URL}
          variant="body3"
          sx={{
            color: "#acacac",
            mr: "1.7rem",
            cursor: "pointer",
            textDecoration: "none",
          }}
        >
          {(process.env.REACT_APP_WEB_URL?.split("//")?.length > 1 &&
            process.env.REACT_APP_WEB_URL?.split("//")?.[1]) ||
            ""}
        </Link>
        <Link href={process.env.REACT_APP_INSTAGRAM_URL}>
          <InstagramIcon sx={{ color: "#c1c1c1" }} />
        </Link>
        <Link href={process.env.REACT_APP_FACEBOOK_URL}>
          <FacebookIcon
            sx={{
              ml: "1.25rem",
              mr: "1.25rem",
              color: "#c1c1c1",
            }}
          />
        </Link>
        <Link href={process.env.REACT_APP_X_URL}>
          <TwitterIcon sx={{ color: "#c1c1c1" }} />
        </Link>
      </div>
    </div>
  );
};

export default BottomBar;
