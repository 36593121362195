import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { makeStyles } from "@mui/styles";
import { Typography, Button, Popover, Link } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import comingSoon from "../../assets/images/comingSoon.png";
import Template from "../../components/shared/Template";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const ComingSoon = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Template
      title="Coming soon!"
      subtitle="We’ll let you know when firefighters in your area are available to
          help with your next home project."
      image={comingSoon}
      children={
        <div className={classes.body}>
          <Typography variant="subtitle2">
            Interested? Feel free to share.
          </Typography>
          <CopyToClipboard
            text={process.env.REACT_APP_WEB_URL || ""}
            onCopy={(e) => setAnchorEl(e)}
          >
            <Button
              aria-describedby={id}
              sx={{
                borderColor: "#022752",
                color: "#022752",
                width: { md: 259, sx: "90%" },
                height: 46,
                marginTop: "1.25rem",
                mb: "1.875rem",
              }}
              variant="outlined"
            >
              <LinkIcon sx={{ mr: "0.413rem" }} />
              Copy & Share
            </Button>
          </CopyToClipboard>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            sx={{ mt: 34, ml: 10 }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          >
            <Typography>Copied!</Typography>
          </Popover>
          <div style={{ marginBottom: 125 }}>
            <Link href={process.env.REACT_APP_INSTAGRAM_URL}>
              <InstagramIcon sx={{ color: "#c1c1c1" }} />
            </Link>
            <Link href={process.env.REACT_APP_FACEBOOK_URL}>
              <FacebookIcon
                sx={{ ml: "1.25rem", mr: "1.25rem", color: "#c1c1c1" }}
              />
            </Link>
            <Link href={process.env.REACT_APP_X_URL}>
              <TwitterIcon sx={{ color: "#c1c1c1" }} />
            </Link>
          </div>
        </div>
      }
    />
  );
};

export default ComingSoon;
