export const SUPPORT_URL = "https://hidrent.com/get-support";

export const ROUTES = {
  HOME: "/",
  MENU: "/menu",
  NEW_PROJECT: "/new-project",
  PROJECT_DETAIL: "/project-detail",
  PROJECT_CANCELLED: "/project-cancelled",
  CANCEL_PROJECT: "/cancel-project",
  LOGIN: "/login",
  SIGNUP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  MY_ACCOUNT: "/my-account",
  MY_PROJECTS: "/my-projects",
  NOTIFICATIONS: "/notifications",
  MESSAGES: "/messages",
  CONTACT_CENTER: "/request-service",
};

export const JOB_STATUSES = {
  PENDING: "pending",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  ASSIGNED: "assigned",
  WAITING: "waiting",
  SCHEDULED: "scheduled",
  DRAFT: "draft",
};
