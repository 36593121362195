/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import cn from "classnames";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, CircularProgress, Typography } from "@mui/material";
import {
  addCreditCard,
  getCreditCards,
  updateUser,
} from "../../../redux/actions/userActions";
import {
  FULFILLED,
  PENDING,
} from "../../../redux/constants/actionStatusConstants";
import useSession, { useCreditCard } from "../../../hooks/useSession";
import { useStatus } from "../../../hooks/useStatus";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
  },
  stripeInput: {
    border: "solid 1px #e8e8e8",
    padding: "1rem 0.85rem",
    borderRadius: "0.3rem",
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "92%",
    },
    "&:hover": {
      border: "solid 1px #022752",
    },
  },
  stripeInputFullWidth: {
    width: "100%",
  },
  stripeInputMarginLeft: {
    marginLeft: "1rem",
  },
  inputsRow: {
    marginTop: "1rem",
    display: "flex",
    width: "100%",
  },
  textButton: {
    position: "absolute",
    right: "8rem",
    top: "1rem",
    color: "white",
    letterSpacing: "0rem",
    fontSize: "0.8rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#022752",
      textDecoration: "underline",
    },
  },
  containedButton: {
    color: "white",
    fontFamily: "Urbanist-SemiBold",
    display: "flex",
    marginTop: "1.5rem",
    width: {
      xs: "100%",
      md: "11.9rem",
    },
  },
}));

export default function CreditCardForm({
  setExpanded,
  setShowContinue,
  isAtProjects,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { authenticated } = useSession();
  const { creditCard } = useCreditCard();
  const { status } = useStatus(getCreditCards);
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = async (event) => {
    setError(event.error ? event.error.message : "");
  };

  useEffect(() => {
    if (authenticated) {
      dispatch(getCreditCards());
    }
  }, [dispatch, authenticated]);

  if (status === PENDING) {
    return null;
  }

  const CARD_NUMBER_OPTIONS = {
    placeholder: "Card Number",
    showIcon: true,
  };
  const CARD_EXPIRATION_NOPTIONS = {
    placeholder: "MM / YY",
  };
  const CARD_CVC_OPTIONS = {
    placeholder: "CVC",
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!!creditCard && !isEditing) {
      if (isAtProjects) setShowContinue(false);
      setIsEditing(true);
      return;
    }

    setLoading(true);
    let element = "";
    let createdToken = "";
    if (!stripe || !elements) {
      return;
    }

    element = elements.getElement(CardNumberElement);
    createdToken = await stripe.createToken(element);

    if (createdToken.error) {
      toast.error(
        <Typography color="error">
          There was an error trying to add your credit card in Stripe. Please
          try again or contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
      setLoading(false);
    } else {
      const response = await dispatch(
        addCreditCard({ token: createdToken.token.id })
      );
      if (response?.meta?.requestStatus === FULFILLED) {
        const payload = {
          user: {
            has_payment_method: true,
          },
        };
        dispatch(updateUser(payload));
        toast.success(
          <Typography color="success">
            Credit card added successfully.
          </Typography>,
          {
            position: "top-center",
          }
        );
        setLoading(false);
        if (isAtProjects) {
          setIsEditing(false);
          setShowContinue(true);
          setExpanded("panel3");
        } else {
          setIsEditing(false);
        }
      } else {
        toast.error(
          <Typography color="error">
            {response?.error?.message ||
              "There was an error trying to add your credit card in our system. Please try again or contact support."}
          </Typography>,
          {
            position: "top-center",
          }
        );
        setLoading(false);
      }
    }
  };

  const showEditButton = isAtProjects && !!creditCard && !isEditing;

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Typography sx={{ mb: "2rem" }} variant="h3">
        {!isAtProjects &&
          (!!creditCard && !isEditing
            ? "Saved Payment Method"
            : "Add New Payment Method")}
      </Typography>
      {(!!creditCard && !isEditing && (
        <>
          <Typography color="primary" variant="subtitle1">
            Card Number
          </Typography>
          <Typography sx={{ mt: "0.3rem" }} color="secondary" variant="body1">
            **** **** **** {creditCard?.last4}
          </Typography>
        </>
      )) || (
        <CardNumberElement
          options={CARD_NUMBER_OPTIONS}
          onChange={handleChange}
          className={classes.stripeInput}
        />
      )}
      <div className={classes.inputsRow}>
        {(!!creditCard && !isEditing && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography color="primary" variant="subtitle1">
              Card Expiration Date
            </Typography>
            <Typography sx={{ mt: "0.3rem" }} color="secondary" variant="body1">
              {`${creditCard?.expMonth} / ${creditCard?.expYear}`}
            </Typography>
            <Typography
              sx={{ mt: "1rem", textAlign: "start" }}
              color="primary"
              variant="subtitle1"
            >
              Card Brand
            </Typography>
            <Typography sx={{ mt: "0.3rem" }} color="secondary" variant="body1">
              {creditCard?.brand}
            </Typography>
          </div>
        )) || (
          <>
            <CardExpiryElement
              options={CARD_EXPIRATION_NOPTIONS}
              onChange={handleChange}
              className={cn(classes.stripeInput, classes.stripeInputFullWidth)}
            />
            <CardCvcElement
              options={CARD_CVC_OPTIONS}
              onChange={handleChange}
              className={cn(
                classes.stripeInput,
                classes.stripeInputFullWidth,
                classes.stripeInputMarginLeft
              )}
            />
          </>
        )}
      </div>
      <Button
        type="submit"
        variant="contained"
        color={(isAtProjects && "blue") || "navy"}
        disabled={loading}
        className={
          showEditButton ? classes.textButton : classes.containedButton
        }
        endIcon={
          loading && (
            <CircularProgress
              sx={{
                color: "#022752",
                width: "1.5rem !important",
                height: "1.5rem !important",
              }}
            />
          )
        }
      >
        {!loading && (
          <>{!!creditCard && !isEditing ? "Edit Card" : "Continue"}</>
        )}
      </Button>
      {error && (
        <div role="alert">
          <Typography variant="body1" sx={{ mt: "1rem" }} color="error">
            {error}
          </Typography>
        </div>
      )}
    </form>
  );
}
