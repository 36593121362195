import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import CheckIcon from "@mui/icons-material/Check";
import Graph from "../../../assets/images/graph.svg";
import {
  FULFILLED,
  PENDING,
} from "../../../redux/constants/actionStatusConstants";
import useSession from "../../../hooks/useSession";
import { useDispatch } from "react-redux";
import {
  postJob,
  postWork,
  updateJob,
} from "../../../redux/actions/projectActions";
import { useStatus } from "../../../hooks/useStatus";
import { useProjectDetails } from "../../../hooks/useProject";
import ProjectSubmittedModal from "../ProjectSubmittedModal";
import SelectFirefighterModal from "../SelectFirefighterModal";

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    display: "flex",
    paddingLeft: "26% !important",
    paddingRight: "26% !important",
    alignItems: "center !important",
    paddingBottom: "3rem !important",
    marginBottom: "5rem",

    [theme.breakpoints.down("sm")]: {
      paddingLeft: "5% !important",
      paddingRight: "5% !important",
    },
  },
  estimatePrices: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    position: "relative",
  },
  row: {
    display: "flex",
  },
  title: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "space-between",
    width: "95%",
  },
  accordionContainer: {
    borderRadius: "12px",
    border: "1px solid #F1F1F1",
    marginBottom: "5rem",
  },
}));

const Estimate = ({
  expanded,
  setExpanded,
  handleChange,
  handleSubmit,
  selectedService,
  getValues,
  isAbleToSubmit,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const { projectDetails } = useProjectDetails();
  const { status } = useStatus(postJob);
  const [updateLoading, setUpdateLoading] = React.useState(false);
  const [isViewed, setIsViewed] = React.useState(false);

  const defaultAddress = user?.address;
  const idDefaultAddress = user?.address?.id;

  const [openModal, setOpenModal] = React.useState(false);
  const [openFirefightersModal, setOpenFirefightersModal] =
    React.useState(false);
  const [projectId, setProjectId] = React.useState(null);
  const [formData, setFormData] = React.useState({});

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpenFirefighterModal = () => setOpenFirefightersModal(true);
  const handleCloseFirefighterModal = () => setOpenFirefightersModal(false);

  const postNewWork = async (data) => {
    try {
      const isDefaultAddress =
        data.addressAttributes?.street === defaultAddress?.street &&
        data.addressAttributes?.city === defaultAddress?.city &&
        data.addressAttributes?.state === defaultAddress?.state &&
        data.addressAttributes?.country === defaultAddress?.country &&
        data.addressAttributes?.zip === defaultAddress?.zip &&
        data.addressAttributes?.unit === defaultAddress?.unitOrApt &&
        idDefaultAddress;

      const payload = {
        work: {
          title: data.title,
          description: data.description,
          notes: data.notes,
          images: data.images,
          address_attributes: {
            fullAddress: data.addressAttributes.fullAddress,
            state: data.addressAttributes.state,
            city: data.addressAttributes.city,
            zip: data.addressAttributes.zip,
            street: data.addressAttributes.street,
            country: data.addressAttributes.country,
            unit: data.unitOrApt,
          },
          preferred_date: data.preferredDate,
          special_equipment: data.special_equipment,
          equipment_details: data.equipment_details,
          service_id: selectedService[0]?.id,
          fire_fighter_works_attributes: data.fire_fighter_works_attributes,
          ...(isDefaultAddress && { address_id: idDefaultAddress }),
          user_availabilities_attributes: data.dateTimes?.map((date) => ({
            available_at: date,
          })),
        },
      };
      const response = await dispatch(postWork(payload));
      return response;
    } catch (error) {
      return null;
    }
  };

  React.useEffect(() => {
    if (expanded === "panel3") {
      setIsViewed(false);
    }
  }, [expanded]);

  const submitProject = async (data) => {
    if (!isAbleToSubmit) {
      return toast.error(
        <Typography color="error">
          Please verify the form again. Some required fields are missing
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
    if (!!user?.hasPaymentMethod) {
      let response = null;
      if (!projectDetails?.id && selectedService) {
        response = await dispatch(
          postJob({
            job: { ...data, serviceId: selectedService[0]?.id },
          })
        );

        if (response?.payload) {
          localStorage.setItem("type", "Job");
          setProjectId(response.payload.id);
        }
      } else {
        setUpdateLoading(true);
        if (selectedService) {
          response = await dispatch(
            updateJob({
              jobId: projectDetails.id,
              job: { ...data, serviceId: selectedService[0]?.id },
            })
          );
        }
      }

      if (response?.meta?.requestStatus === FULFILLED) {
        toast.success(
          <Typography color="success">
            Project was succesfully {projectDetails?.id ? "updated" : "created"}
            .
          </Typography>,
          {
            position: "top-center",
          }
        );
        setUpdateLoading(false);
        projectDetails?.id && navigate("/success-project");
      } else {
        toast.error(
          <Typography color="error">{`${response?.error?.message}. Please verify the form again.`}</Typography>,
          {
            position: "top-center",
          }
        );
        setExpanded("panel1");
        setUpdateLoading(false);
      }
    } else {
      if (user === null) {
        toast.error(
          <Typography color="error">
            Please complete your contact information.
          </Typography>,
          {
            position: "top-center",
          }
        );
      } else {
        if (!user?.verifiedAt) {
          toast.error(
            <Typography color="error">Please verify your account.</Typography>,
            {
              position: "top-center",
            }
          );
        } else {
          if (!!!user?.hasPaymentMethod) {
            toast.error(
              <Typography color="error">
                Please add a payment method to continue.
              </Typography>,
              {
                position: "top-center",
              }
            );
          } else {
            if (localStorage.getItem("fireFightersNear") === "0") {
              toast.error(
                <Typography color="error">
                  Please add a new address.
                </Typography>,
                {
                  position: "top-center",
                }
              );
            }
          }
        }
      }
      setUpdateLoading(false);
    }
  };

  const handleSelectFirefightersSubmit = async (selectedFirefighters = []) => {
    const updatedData = {
      ...formData,
      fire_fighter_works_attributes: selectedFirefighters.map((id) => ({
        fire_fighter_id: id,
      })),
    };
    try {
      const response = await postNewWork(updatedData);
      if (response?.meta?.requestStatus === FULFILLED) {
        setProjectId(response.payload.work.id);
        localStorage.setItem("type", "Work");
        toast.success(
          <Typography color="success">
            Project was succesfully created.
          </Typography>,
          {
            position: "top-center",
          }
        );
      } else {
        toast.error(
          <Typography color="error">
            {response?.error?.message || "Error creating work."}
          </Typography>
        );
      }
    } catch (error) {
      toast.error(
        <Typography color="error">An unexpected error occurred.</Typography>
      );
    } finally {
      handleCloseFirefighterModal();
    }
  };

  const handleContinue = async (data) => {
    const updatedData = { ...data, ...getValues() };
    setFormData(updatedData);

    if (!projectDetails?.id && selectedService && isAbleToSubmit) {
      if (data.isPhoenixLocation) {
        handleOpenFirefighterModal();
        return;
      }
    }

    submitProject(updatedData);
  };

  React.useEffect(() => {
    projectId && handleOpenModal();
  }, [projectId]);

  return (
    <>
      <Accordion
        className={classes.accordionContainer}
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary aria-controls="panel3a-content" id="panel3a-header">
          <div className={classes.title}>
            <Typography variant="title1">Project Estimate</Typography>
            {isViewed && (
              <div
                className={classes.row}
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  variant="text"
                  sx={{
                    color: "#2b8ced",
                    fontFamily: "Urbanist-Medium",
                    display: "flex",
                    fontsize: 14,
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.43,
                    letterSpacing: "normal",
                    textAlign: "left",
                  }}
                >
                  View
                </Button>
                <CheckIcon color="green" />
              </div>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.infoContainer}>
          <div className={classes.estimatePrices}>
            <img
              alt=""
              src={Graph}
              style={{ position: "absolute", width: "100%" }}
            />
            <div>
              <Typography
                sx={{ color: "navy !important", mb: "0.5rem" }}
                variant="subtitle1"
              >
                Low
              </Typography>
              {selectedService && (
                <Typography variant="body3">
                  ${selectedService[0]?.lowestCost}
                </Typography>
              )}
            </div>
            <Divider
              sx={{ height: "11.9rem", borderStyle: "dashed" }}
              orientation="vertical"
            />
            <div>
              <Typography
                sx={{ color: "navy !important", mb: "0.5rem" }}
                variant="subtitle1"
              >
                Most Spend
              </Typography>
              {selectedService && (
                <Typography
                  sx={{
                    color: "lightGreen !important",
                    fontFamily: "Urbanist-Medium",
                    fontWeight: "100",
                  }}
                  variant="subtitle1"
                >
                  ${selectedService[0]?.lowestAvgCost} - $
                  {selectedService[0]?.highestAvgCost}
                </Typography>
              )}
            </div>
            <Divider
              sx={{ height: "11.9rem", borderStyle: "dashed" }}
              orientation="vertical"
            />
            <div>
              <Typography
                sx={{
                  color: "navy !important",
                  mb: "0.5rem",
                }}
                variant="subtitle1"
              >
                High
              </Typography>
              {selectedService && (
                <Typography
                  sx={{
                    fontFamily: "Urbanist-Medium",
                    fontWeight: "100",
                  }}
                  variant="subtitle1"
                >
                  ${selectedService[0]?.highestCost}
                </Typography>
              )}
            </div>
          </div>
          {selectedService && (
            <Typography
              sx={{
                color: "lightGreen !important",
                fontFamily: "Urbanist-Medium",
                fontWeight: "100",
              }}
              variant="h1"
            >
              ${selectedService[0]?.avgCost}
            </Typography>
          )}

          <Typography
            sx={{ color: "navy !important", mt: "0.5rem" }}
            variant="subtitle1"
          >
            Helpful Heroes Average
          </Typography>
          <Typography
            sx={{
              color: "black !important",
              textAlign: "center",
              mt: "1.5rem",
            }}
            variant="body1"
          >
            Your project estimate is based on trends in your area for your
            selected service. Don’t worry, you will only pay once your job is
            completed at a price mutually agreed upon with your assigned
            firefighter. Questions? Check out our{" "}
            <b
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open(`${process.env.REACT_APP_WEB_URL}/faq`)
              }
            >
              FAQs
            </b>
          </Typography>
          <Button
            sx={{
              width: { md: "11.9rem", xs: "100%" },
              mt: "2rem",
            }}
            disabled={status === PENDING || updateLoading}
            variant="contained"
            onClick={handleSubmit(handleContinue)}
          >
            {!!user?.hasPaymentMethod &&
            localStorage.getItem("fireFightersNear") !== "0"
              ? "Submit Project"
              : "Continue"}
          </Button>
        </AccordionDetails>
      </Accordion>

      {openModal && projectId && (
        <ProjectSubmittedModal
          open={openModal}
          onClose={handleCloseModal}
          projectId={projectId}
        />
      )}

      <SelectFirefighterModal
        open={openFirefightersModal}
        onClose={handleCloseFirefighterModal}
        fullAddress={formData.addressAttributes?.fullAddress}
        onSubmit={handleSelectFirefightersSubmit}
        setGlobalValue={(key, value) =>
          setFormData({ ...formData, [key]: value })
        }
      />
    </>
  );
};

export default Estimate;
