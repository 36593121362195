import { useEffect, useState } from "react";
import { Typography, Grid, Box, AvatarGroup, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AccessTime from "@mui/icons-material/AccessTime";
import DefaultProject from "../../../assets/images/default-img.svg";

//Components
import ChipStatus from "../../../components/shared/ChipStatus";

//Helpers
import { JOB_STATUSES } from "../../../utils/constants";

//Helpers
import { formatShortCompleteDate } from "../../../helpers/dates";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    alignItems: " flex-start",
  },
  image: {
    width: "100%",
    height: 230,
    objectFit: "cover",
    borderRadius: "12px",
    border: "0.5px solid #E8E8E8",
  },
  container: { width: "100%", position: "relative" },
  profilePicture: {
    borderRadius: "50%",
    height: "35px",
    width: "35px",
    border: "1px solid white",
  },
  workAssignedDate: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  countdownTimer: {
    width: 60,
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    top: 20,
    left: 20,
    backgroundColor: "white",
    color: "black",
    padding: "5px 10px",
    borderRadius: "15px",
    fontSize: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  },
}));

const ProjectCard = ({ project, onClick }) => {
  const classes = useStyles();
  const [timeLeft, setTimeLeft] = useState("");

  const {
    title,
    status,
    images,
    service,
    type,
    totalPrice,
    date = "",
    createdAt,
    fireFighter = {},
    fireFighters = [],
  } = project || {};

  const clickable = status !== JOB_STATUSES.CANCELLED;
  const isWorkAssigned = status === JOB_STATUSES.ASSIGNED && type === "Work";
  const isWorkPending = status === JOB_STATUSES.PENDING && type === "Work";

  useEffect(() => {
    if (!isWorkPending) return;

    const calculateTimeLeft = () => {
      const now = new Date();
      const createdDate = new Date(createdAt);
      const diff = 24 * 60 * 60 * 1000 - (now - createdDate);

      if (diff <= 0) {
        setTimeLeft("");
        return;
      }

      const hours = Math.floor(diff / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      setTimeLeft(
        `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      );
    };

    calculateTimeLeft();

    const interval = setInterval(() => {
      calculateTimeLeft();
    }, 1000);

    return () => clearInterval(interval);
  }, [createdAt, isWorkPending]);

  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        display="flex"
        flexDirection="column"
        onClick={onClick}
        className={classes.container}
        style={{ cursor: clickable && "pointer" }}
      >
        <img
          className={classes.image}
          src={images[0]?.url || DefaultProject}
          alt={title}
        />

        {isWorkPending && timeLeft && (
          <Box className={classes.countdownTimer}>{timeLeft}</Box>
        )}

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
          mb={0.5}
        >
          <Typography variant="title3">{title}</Typography>

          {status === "completed" && (
            <Typography variant="h5">${totalPrice}</Typography>
          )}
        </Box>

        {isWorkAssigned ? (
          <div className={classes.workAssignedDate}>
            <AccessTime fontSize="small" color="primary" />{" "}
            <Typography variant="title">
              {formatShortCompleteDate(date)}
            </Typography>
          </div>
        ) : (
          <Typography variant="title">{service.name}</Typography>
        )}

        {status === JOB_STATUSES.WAITING && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={0.5}
          >
            <ChipStatus status={status} />

            <AvatarGroup max={3}>
              {fireFighters.length > 0 &&
                fireFighters.map((ff, index) => {
                  return (
                    <Avatar
                      key={`Firefighter_${index}`}
                      alt={`Firefighter_${index}`}
                      src={ff.profilePictureUrl}
                      className={classes.profilePicture}
                    />
                  );
                })}
            </AvatarGroup>
          </Box>
        )}

        {status !== JOB_STATUSES.WAITING && !isWorkAssigned && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            mt={0.5}
          >
            <ChipStatus status={status} />
          </Box>
        )}

        {isWorkAssigned && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            mt={0.5}
            gap={1}
          >
            <Avatar
              alt={fireFighter.fullName}
              src={fireFighter.profilePictureUrl}
              className={classes.profilePicture}
              sx={{ color: "grey" }}
            />
            <Typography variant="h5"> {fireFighter.fullName}</Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
};

export default ProjectCard;
