import React from "react";
import { Modal as MaterialModal, Box, IconButton } from "@mui/material";

//Icons
import CloseIcon from "@mui/icons-material/Close";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
};

const Modal = ({ open, onClose, showProgress, progressWidth, children }) => {
  return (
    <MaterialModal open={open} disableBackdropClick disableEscapeKeyDown>
      <Box sx={modalStyle}>
        {showProgress && progressWidth && (
          <div
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              height: 6,
              backgroundColor: "#2B8CED",
              width: progressWidth,
            }}
          />
        )}

        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>

        {children}
      </Box>
    </MaterialModal>
  );
};

export default Modal;
