import { Card, Typography, Avatar, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import {
  getAllProjectTypes,
} from "../../../redux/actions/projectActions";
import { useAllProjectTypes } from "../../../hooks/useProject";
import { useEffect } from "react";

const useStyles = makeStyles(() => ({
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "1rem",
  },
}));

const ServiceType = ({setValue, watch}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { allProjectTypes } = useAllProjectTypes();
    const serviceType = watch("serviceType");

    useEffect(() => {
        dispatch(getAllProjectTypes());
      }, [dispatch]);
    
      const handleClick = (item) => {
        if (serviceType + 1 ===  item.id) {
          setValue("serviceType", undefined);
        } else {
          setValue("serviceType", item.id-1);
        }
      };
      

    return (
    <div className={classes.sectionContainer}>
    <Typography variant="title1">Service Request Details</Typography>
    <Typography>What type of service do you need?</Typography>
    {allProjectTypes ? (
        <Grid
          container
          columns={{ xs: 4, sm: 4, md: 4, lg: 4 }}
          columnSpacing={2}
        >
          {allProjectTypes?.map((item, index) => (
            <Grid item xs={1} sm={1} md={1} lg={1} key={index}>
              <Card
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "none",
                  backgroundColor: serviceType === item.id-1 ? "#FAFAFA" : "",
                  maxHeight: "140px",
                  cursor: "pointer",
                  padding: "2px",
                  border: serviceType === item.id-1 ? "" : "1px solid lightGrey"
                }}
                onClick={() => handleClick(item)}
              >
                <Avatar>
                  <img
                    style={{ width: "2.3rem", height: "auto" }}
                    alt={item.name}
                    src={item.icon}
                  />
                </Avatar>
                <Typography variant="h5" marginTop="14.4px" sx={{textAlign: "center"}}>
                  {item.name}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : null}
      </div>
      )
}

export default ServiceType;