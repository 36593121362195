import moment from "moment";

export const formatTimeToMinutes = (minutes) => {
  const hours = Math.floor(minutes / 60)
    .toString()
    .padStart(2, "0");
  const remainingMinutes = (minutes % 60).toString().padStart(2, "0");
  return `${hours}:${remainingMinutes}`;
};

export const formatDateToMonthYear = (dateString) => {
  const date = new Date(dateString);
  const options = { year: "numeric", month: "long" };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const formatCalendarDate = (createdAt) => {
  const date = new Date(createdAt);
  const now = new Date();

  const sameDay = date.toDateString() === now.toDateString();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  const isYesterday = date.toDateString() === yesterday.toDateString();

  const optionsTime = { hour: "numeric", minute: "numeric", hour12: true };
  const optionsFull = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  if (sameDay) {
    return date.toLocaleString("en-US", optionsTime);
  } else if (isYesterday) {
    return `Yesterday, ${date.toLocaleString("en-US", optionsTime)}`;
  } else if (now - date < 7 * 24 * 60 * 60 * 1000) {
    return `${date.toLocaleDateString("en-US", {
      weekday: "short",
    })}, ${date.toLocaleString("en-US", optionsTime)}`;
  } else {
    return date.toLocaleString("en-US", optionsFull);
  }
};

export const formatShortCompleteDate = (dateString) => {
  const date = new Date(dateString);

  const options = {
    weekday: "short",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const groupMessagesByDate = (messages) => {
  if (!Array.isArray(messages)) return [];

  const groups = messages.reduce((acc, message) => {
    const date = moment(message.createdAt).format("MMM D, YYYY");
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(message);
    return acc;
  }, {});

  const sortedDates = Object.keys(groups).sort((a, b) =>
    moment(a, "MMM D, YYYY").isBefore(moment(b, "MMM D, YYYY")) ? -1 : 1
  );

  return sortedDates.map((date) => ({
    date,
    messages: groups[date].sort((a, b) =>
      moment(a.createdAt).isBefore(moment(b.createdAt)) ? -1 : 1
    ),
  }));
};
