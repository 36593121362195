import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import { useForm } from "react-hook-form";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Button, TextField } from "@mui/material";
import locationIcon from "../../../assets/icons/location.svg";
import LocationInput from "./LocationInput";
import { validationSchema } from "./validations.js";
import { useYupValidationResolver } from "../../../helpers/useValidationResolver";
import CheckIcon from "@mui/icons-material/Check";
import { useProjectDetails } from "../../../hooks/useProject";
import { useDispatch } from "react-redux";
import checkPhoenixLocation from "../../../utils/checkPhoenixLocation";
import { useQuery } from "@tanstack/react-query";
import settingsService from "../../../services/settingsService";
import { useQueryParam, StringParam } from "use-query-params";
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
  addressInputContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      height: "125px",
      justifyContent: "space-between",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "95%",
  },
  icon: {
    width: 11.2,
    height: 16,
  },
  form: {
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  locationInput: {
    marginRight: "10px",
    width: "25.875rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px",
    },
  },
  accordionContainer: {
    borderRadius: "12px",
    border: "1px solid #F1F1F1",
  },
  projectLocation: {
    justifyContent: "space-between",
    width: "95%",
  },
  alignCenter: {
    alignItems: "center",
  },
}));
const Location = ({ expanded, setExpanded, handleChange, setGlobalValue }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDone, setIsDone] = React.useState(false);
  const resolver = useYupValidationResolver(validationSchema);
  const { projectDetails } = useProjectDetails();
  const [location, setLocation] = useQueryParam(("location", StringParam));
  const [locationValue, setLocationValue] = React.useState("");
  const [locationInputValue, setLocationInputValue] = useState(projectDetails?.address?.fullAddress || "");
  const [selectedLocationZipCode, setSelectedLocationZipCode] = useState("");

  const { data: zipCodes } = useQuery({
    queryKey: ["getPhoenixZipCodes"],
    queryFn: settingsService.getPhoenixZipCodes,
    staleTime: Infinity,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    register,
    setValue,
  } = useForm({
    resolver,
    defaultValues: {
      unitOrApt: projectDetails?.unitOrApt || "",
      addressAttributes: {
        fullAddress: projectDetails?.address?.fullAddress || "",
        city: projectDetails?.address?.city || "",
        state: projectDetails?.address?.state || "",
        street: projectDetails?.address?.street || "",
        zip: projectDetails?.address?.zip || "",
      },
    },
  });

  useEffect(() => {
    if (expanded === "panel1") {
      setIsDone(false);
    }
  }, [dispatch, expanded]);

  useEffect(() => {
    if (zipCodes && selectedLocationZipCode) {
      setGlobalValue(
        "isPhoenixLocation",
        checkPhoenixLocation(selectedLocationZipCode, zipCodes)
      );
    }
  }, [selectedLocationZipCode, setGlobalValue, zipCodes]);

  const onSubmit = (data) => {
    if (!selectedLocationZipCode) {
      toast.error(
        <Typography color="error">
          The selected location does not have a ZIP code. Please choose a more specific address.
        </Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }
    setExpanded("panel2");
    setIsDone(true);
    setGlobalValue(
      "addressAttributes",
      data?.addressAttributes
    );
    setGlobalValue("unitOrApt", data.unitOrApt);
    setGlobalValue(
      "isPhoenixLocation",
      checkPhoenixLocation(selectedLocationZipCode, zipCodes)
    );
  };

  return (
    <Accordion
      className={classes.accordionContainer}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
    >
      <AccordionSummary>
        {(isDone && (
          <div className={classes.rowContainer}>
            <div>
              <Typography variant="title1">Project Location</Typography>
              <div className={classes.row} style={{ alignItems: "center" }}>
                <img alt="" src={locationIcon} className={classes.icon} />
                <Typography variant="body1" color="#535353" marginLeft="9px">
                  {locationInputValue} {getValues("unitOrApt")}
                </Typography>
              </div>
            </div>
            <div className={(classes.row, classes.alignCenter)}>
              <Button
                variant="text"
                sx={{
                  color: "#2b8ced",
                  fontFamily: "Urbanist-Medium",
                  display: "flex",
                  fontsize: 14,
                  fontWeight: 500,
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: 1.43,
                  letterSpacing: "normal",
                  textAlign: "left",
                }}
              >
                Edit
              </Button>
              <CheckIcon color="green" />
            </div>
          </div>
        )) || <Typography variant="title1">Project Location</Typography>}
      </AccordionSummary>
      <AccordionDetails>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <div className={classes.addressInputContainer}>
            <div className={classes.locationInput}>
              <LocationInput
                value={locationValue}
                setValue={setLocationValue}
                setLocation={setLocation}
                inputValue={locationInputValue}
                setInputValue={setLocationInputValue}
                formKey="addressAttributes"
                setFormValue={setValue}
                error={!!errors["addressAttributes.fullAddress"]}
                helperText={errors["addressAttributes.fullAddress"]?.message}
                setZipCode={setSelectedLocationZipCode}
              />
            </div>
            <TextField
              name="unitOrApt"
              control={control}
              {...register("unitOrApt", {
                required: true,
                pattern:
                  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
              })}
              controller
              type="number"
              variant="outlined"
              placeholder="Unit or Apt #"
              inputProps={{ min: "0" }}
              className={classes.unitTextField}
            />
          </div>
          <Button
            variant="contained"
            sx={{
              color: "white",
              fontFamily: "Urbanist-SemiBold",
              display: "flex",
              width: {
                xs: "100%",
                md: "11.9rem",
              },
              height: "3rem",
              mt: {
                xs: "1rem",
                md: "0",
              },
            }}
            children="Continue"
            type="submit"
          />
        </form>
      </AccordionDetails>
    </Accordion>
  );
};
Location.propTypes = {
  handleChange: PropTypes.func,
  expanded: PropTypes.string,
};
export default Location;