import * as yup from "yup";

export const validationSchema = (isPhoenixLocation) => yup.object({
  title: yup.string().required("Title is a required field."),
  description: yup.string().required("Description is a required field."),
  specialEquipment: yup
    .string()
    .required("Special Equipment is a required field.")
    .oneOf(["yes", "no", "maybe"]),
  equipmentDetails: yup
    .string()
    .test(
      "required-if-yes",
      "Equipment Details is a required field.",
      (value, context) => {
        const specialEquipmentValue = context.parent.specialEquipment;
        return specialEquipmentValue === "yes" ? !!value : true;
      }
    ),
    dateTimes: yup.array().when([], {
      is: () => isPhoenixLocation,
      then: (schema) => schema.min(1, 'Please select at least one date & time'),
      otherwise: (schema) => schema.notRequired(),
    }),
});
