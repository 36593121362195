import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Typography, Button, Avatar, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import Modal from "../../shared/Modal";

//Helpers
import { formatTimeToMinutes } from "../../../helpers/dates";
import { firefighters } from "../../../helpers/popupFirefighters";

//Icons
import Check from "../../../assets/icons/firefighterCheck.svg";

const useStyles = makeStyles(() => ({
  avatarContainer: { position: "relative" },
  avatarFirefighter: { position: "absolute", bottom: "2px", right: "2px" },
  firefigtherName: {
    fontSize: 14,
    marginTop: "5px",
  },
  remainingTime: {
    marginTop: "10px",
    marginBottom: "3px",
    fontWeight: "bold",
    opacity: 0.5,
  },
  message: {
    marginBlock: "15px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    gap: "10px",
  },
}));

const ProjectSubmittedModal = ({ open, onClose, projectId }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [timeRemaining, setTimeRemaining] = useState(1440);

  const handleRedirect = (route) => {
    navigate(route);
    onClose();
  };

  useEffect(() => {
    let timer;

    if (open && timeRemaining > 0) {
      timer = setTimeout(() => {
        setTimeRemaining(timeRemaining - 1);
      }, 60000);
    } else if (timeRemaining === 0) {
      handleRedirect("/my-projects");
    }

    return () => clearTimeout(timer);
  }, [open, timeRemaining, navigate, handleRedirect]);

  return (
    <Modal open={open} onClose={() => handleRedirect("/my-projects")}>
      <Box>
        <Grid container justifyContent="center">
          {firefighters.map((firefighter) => (
            <Grid
              item
              xs={3}
              key={firefighter.name}
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.avatarContainer}>
                <Avatar
                  alt={firefighter.name}
                  src={firefighter.image}
                  sx={{
                    width: 70,
                    height: 70,
                  }}
                />
                <img
                  alt="Check"
                  src={Check}
                  className={classes.avatarFirefighter}
                />
              </div>

              <Typography
                variant="caption"
                align="center"
                color="primary"
                className={[
                  classes.fireFighterName,
                  { opacity: firefighter.opacity || 1 },
                ]}
              >
                {firefighter.name}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Typography
          variant="h2"
          component="h2"
          align="center"
          className={classes.remainingTime}
        >
          {formatTimeToMinutes(timeRemaining)}
        </Typography>
        <Typography variant="h2" component="h2" align="center">
          Project Submitted!
        </Typography>

        <Typography variant="body1" align="center" className={classes.message}>
          Firefighters in your area will have 24 hours to apply for your
          project.
        </Typography>

        <Box className={classes.buttonsContainer}>
          <Button
            onClick={() => handleRedirect(`/project-detail/${projectId}`)}
            variant="contained"
            size="small"
          >
            View Project
          </Button>
          <Button
            onClick={() => handleRedirect("/my-projects")}
            variant="text"
            size="small"
          >
            Go to Dashboard
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ProjectSubmittedModal;
