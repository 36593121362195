import React, { useMemo, useState } from "react";
import { Box, Typography, Button, Divider } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

//Components
import Modal from "../../../components/shared/Modal";
import FirefighterAddTipModal from "../YourFirefighter/FirefighterAddTipModal";

//Services
import ProjectService from "../../../services/projectService";

const ViewInvoiceModal = ({ open, project, onClose }) => {
  const [openAddTipModal, setOpenAddTipModal] = useState(false);
  const { fireFighterTipPaid } = project || {};

  const handleOpenAddTipModal = () => {
    if (!project?.fireFighterTipPaid) {
      setOpenAddTipModal(true);
    } else {
      onClose();
    }
  };

  const handleCloseAddTipModal = () => {
    setOpenAddTipModal(false);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["getInvoice", project.id],
    queryFn: () => ProjectService.getInvoice(project.id),
  });

  const confirmInvoice = useMutation({
    mutationFn: (workId) => {
      ProjectService.confirmInvoice(workId);
    },
    onSuccess: () => {
      toast.success(
        <Typography color="success">
          Your invoice has been confirmed successfully.
        </Typography>,
        {
          position: "top-center",
        }
      );
      onClose();
    },
    onError: () => {
      toast.error(
        <Typography color="error">
          There was an error confirming the invoice.
        </Typography>,
        {
          position: "top-center",
        }
      );
    },
  });

  const handleConfirm = () => {
    confirmInvoice.mutate(project.id);
  };

  const handleSubmitDispute = async () => {
    const subject = encodeURIComponent(`Invoice Dispute Job #${project.id}`);
    const uri = `mailto:support@hirehelpfulheroes.com?subject=${subject}`;
    window.open(uri, "_blank");
  };

  const {
    hold,
    price,
    processingFee,
    tecnologyFee,
    total,
    discountCouponAmount,
    giftUpCharge,
  } = data?.invoice || {};

    const projectTotal = useMemo(() => {
      const sum =
        (Number(price) || 0) +
        (Number(processingFee) || 0) +
        (Number(tecnologyFee) || 0);
      return parseFloat(sum.toFixed(1));
    }, [price, processingFee, tecnologyFee]);
  
    const parsedTecnologyFee = useMemo(() => {
      const fee = Number(tecnologyFee) || 0;
      return parseFloat(fee.toFixed(1));
    }, [tecnologyFee]);
  
    const parsedProcessingFee = useMemo(() => {
      const fee = Number(processingFee) || 0;
      return parseFloat(fee.toFixed(1));
    }, [processingFee]);
  
    const parsedPrice = useMemo(() => {
      const fee = Number(price) || 0;
      return parseFloat(fee.toFixed(1));
    }, [price]);

  return (
    <Modal open={open} onClose={onClose}>
      {isLoading && !data ? (
        <Typography>Loading...</Typography>
      ) : (
        <Box display="flex" flexDirection="column">
          <Typography variant="h3" mb={2} align="center">
            Project Invoice
          </Typography>

          <Typography mb={4} align="center">
            Your credit card has been charged for the final amount shown below.
          </Typography>

          <Typography
            variant="h1"
            mb={2}
            align="center"
            sx={{ fontSize: "50px" }}
          >
            ${total}
          </Typography>

          <Typography variant="h4" align="center" color="primary" mb={4}>
            Amount Due
          </Typography>

          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography>Service Charge</Typography>
              <Typography>${parsedPrice}</Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography>Processing Fee</Typography>
              <Typography>${parsedProcessingFee}</Typography>
            </Box>

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography>Technology, Service, & Insurance Fee</Typography>
              <Typography>${parsedTecnologyFee}</Typography>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography>Project Total</Typography>
              <Typography>${projectTotal}</Typography>
            </Box>
            {hold > 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>Deposit</Typography>
                <Typography>-${hold}</Typography>
              </Box>
            )}
            {discountCouponAmount > 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>Discount code</Typography>
                <Typography>-${discountCouponAmount}</Typography>
              </Box>
            )}
            {giftUpCharge > 0 && (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Typography>Gift Card</Typography>
                <Typography>-${giftUpCharge}</Typography>
              </Box>
            )}

            <Divider sx={{ my: 2 }} />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h4" color="primary">
                Amount Due
              </Typography>
              <Typography variant="h4" color="primary">
                ${total}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column" gap={2} mt={3}>
              {!project.paid ? (
                <Button
                  variant="contained"
                  onClick={handleConfirm}
                  disabled={confirmInvoice.isPending}
                >
                  Confirm Invoice
                </Button>
              ) : (
                <>
                  {!fireFighterTipPaid && (
                    <Button variant="contained" onClick={handleOpenAddTipModal}>
                      Add A Tip
                    </Button>
                  )}
                </>
              )}

              {!project.paid && (
                <Button
                  variant="text"
                  color="error"
                  onClick={handleSubmitDispute}
                >
                  Submit Dispute
                </Button>
              )}
            </Box>
          </Box>

          {openAddTipModal && (
            <FirefighterAddTipModal
              open={openAddTipModal}
              workId={project.id}
              onClose={handleCloseAddTipModal}
            />
          )}
        </Box>
      )}
    </Modal>
  );
};

export default ViewInvoiceModal;
