import { useSelector, shallowEqual } from "react-redux";

const useUnseenMessages = () =>
  useSelector(
    ({ session: { unseenMessages } }) => ({
      unseenMessages,
    }),
    shallowEqual
  );

export default useUnseenMessages;
