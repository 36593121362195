import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProjectTypesFulfilled,
  getProjectChatBoardFulfilled,
  sendMessageFulfilled,
  getProjectByIdFulfilled,
  updateJobFulfilled,
  resetProjectDetailsFulfilled,
  verifyLocationFulfilled,
  addTipFulfilled,
  cancelJobFulfilled,
} from "../actions/projectActions";

const initialState = {
  allProjectTypes: [],
  selectedChatBoard: {},
  jobs: [],
  projectDetails: {},
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectInformation: (state, { payload }) => {
      state.projectDetails = payload;
    },
  },
  extraReducers: {
    [getAllProjectTypesFulfilled]: (state, { payload }) => {
      state.allProjectTypes = payload;
    },
    [getProjectChatBoardFulfilled]: (state, { payload }) => {
      state.selectedChatBoard = payload?.chatBoard;
    },
    [getProjectByIdFulfilled]: (state, { payload }) => {
      state.projectDetails = payload?.job;
    },
    [resetProjectDetailsFulfilled]: (state) => {
      state.projectDetails = {};
    },
    [updateJobFulfilled]: (state, { payload }) => {
      state.projectDetails = payload;
    },
    [cancelJobFulfilled]: (state, { payload }) => {
      state.projectDetails = payload;
    },
    [sendMessageFulfilled]: (state, { payload }) => {
      const newMessages = state.selectedChatBoard.messages;
      newMessages.unshift(payload.message);
      state.selectedChatBoard.messages = newMessages;
    },
    [verifyLocationFulfilled]: (state, { payload }) => {
      state.fireFightersNear = payload.fireFightersNear;
      localStorage.setItem("fireFightersNear", payload.fireFightersNear);
    },
    [addTipFulfilled]: (state, { payload }) => {
      state.tip = payload.tip;
    },
  },
});

export const projectReducer = projectSlice.reducer;
export const { setProjectInformation } = projectSlice.actions;
