import { useEffect, useRef } from "react";

import { Typography, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Components
import CreateProjectPhotosList from "../CreateProjectPhotosList";

//Hooks
import { useProjectDetails } from "../../../hooks/useProject";

//Helpers
import getBase64 from "../../../helpers/getBase64";

const useStyles = makeStyles((theme) => ({
  createProjectPhotos: {
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "2rem",
  },
  createProjectPhotosHeader: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "25px",
  },
  photoUploadArea: {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px",
    marginInline: "25px",
  },
  photoDetails: {
    background: "#07326533",
    borderRadius: "8px",
    height: 50,
    width: 50,
  },
}));

const CreateProjectPhotos = ({ setValue, watch }) => {
  const classes = useStyles();
  const { projectDetails } = useProjectDetails();
  const inputRef = useRef(null);

  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);

    const addImagePromises = files.map(
      (file) =>
        new Promise((resolve) => {
          getBase64(file, (base64Data) => {
            resolve({
              data: base64Data,
              filename: "job-image.png",
            });
          });
        })
    );

    await Promise.all(addImagePromises).then((newImages) => {
      setValue("images", [...images, ...newImages]);
    });
  };

  const handleAddPhotosClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    if (projectDetails?.images?.length > 0) {
      setValue("images", projectDetails.images);
    }
  }, [projectDetails, setValue]);

  const images = watch("images") || [];

  return (
    <div className={classes.createProjectPhotos}>
      <div className={classes.createProjectPhotosHeader}>
        <Typography variant="h5" color="primary" sx={{ marginLeft: "25px" }}>
          Photos
        </Typography>

        {images.length > 0 && (
          <Button variant="text" onClick={handleAddPhotosClick}>
            + Add Photos
          </Button>
        )}
      </div>

      {images.length > 0 ? (
        <CreateProjectPhotosList images={images} setValue={setValue} />
      ) : (
        <div className={classes.photoUploadArea}>
          <Grid container columns={2} gap={1} sx={{ marginBlock: "30px" }}>
            <div>
              <Grid item className={classes.photoDetails} />
              <Grid item className={classes.photoDetails} />
            </div>
            <div>
              <Grid item className={classes.photoDetails} />
              <Grid item className={classes.photoDetails} />
            </div>
          </Grid>

          <Typography
            variant="h5"
            color="primary"
            align="center"
            sx={{ marginBottom: "12px" }}
          >
            Upload Project Photos
          </Typography>

          <Typography
            variant="body1"
            color="textSecondary"
            align="center"
            sx={{ marginBottom: "20px" }}
          >
            Firefighters are more likely apply for projects with photos.
          </Typography>

          <Button variant="text" onClick={handleAddPhotosClick}>
            + Add Photos
          </Button>
        </div>
      )}

      <input
        ref={inputRef}
        accept="image/*"
        id="image-upload"
        type="file"
        multiple
        style={{ display: "none" }}
        onChange={handleImageUpload}
      />
    </div>
  );
};

export default CreateProjectPhotos;
