import { Stack, Box, Chip, Avatar, Typography } from "@mui/material";

//Helpers
import { formatCalendarDate } from "../../../../helpers/dates";

//Icons
import StarIcon from "@mui/icons-material/Star";

const ReviewCard = ({ name, comment, rating, createdAt, service }) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" mb={3}>
        <Stack direction="row" alignItems="center" gap={1}>
          <Avatar alt={name} sx={{ width: 60, height: 60, color: "gray" }}>
            {name.charAt(0)}
          </Avatar>

          <Stack>
            <Typography variant="h5" mb={1}>
              {name}
            </Typography>

            <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
              <Chip
                label={service?.name}
                color="primary"
                variant="filled"
                size="small"
                sx={{
                  color: "#002E65",
                  backgroundColor: "#002E6540",
                  "& .MuiChip-label": {
                    fontSize: "12px",
                  },
                  textTransform: "uppercase",
                }}
              />
              <Typography>{formatCalendarDate(createdAt)}</Typography>
            </Box>
          </Stack>
        </Stack>

        <Box display="flex" flexDirection="row" alignItems="center">
          <StarIcon color="primary" fontSize="small" />

          <Typography variant="body2" color="primary" ml={1}>
            {rating.toFixed(1)}
          </Typography>
        </Box>
      </Stack>

      {comment && <Typography variant="body2">{comment}</Typography>}
    </>
  );
};

export default ReviewCard;
