import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { Typography, Button, Tab, Tabs, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import { resetProjectDetails } from "../../redux/actions/projectActions";
import Footer from "../../components/shared/Footer";
import TopBar from "../../components/shared/TopBar";
import ProjectList from "./ProjectsList";

// Utils
import { ROUTES, JOB_STATUSES } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
  },
  myProjects: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  tabPanel: {
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const MyProjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  useEffect(() => {
    dispatch(resetProjectDetails());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCreate = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TopBar />
        <div className={classes.myProjects}>
          <Typography variant="title2">My Projects</Typography>
          <Button
            variant="contained"
            color="secondary"
            sx={{ color: "white" }}
            onClick={handleCreate}
          >
            <AddIcon sx={{ mr: 1 }} />
            Create Project
          </Button>
        </div>
        <div className={classes.tabPanel}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ borderBottom: "1px solid #d0d0d0" }}
          >
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Previous" {...a11yProps(1)} />
          </Tabs>
        </div>
        <TabPanel className={classes.tabPanel} value={value} index={0}>
          <ProjectList
            statuses={[
              JOB_STATUSES.ASSIGNED,
              JOB_STATUSES.PENDING,
              JOB_STATUSES.WAITING,
            ]}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={value} index={1}>
          <ProjectList
            statuses={[JOB_STATUSES.COMPLETED, JOB_STATUSES.CANCELLED]}
          />
        </TabPanel>
      </div>
      <Footer />
    </div>
  );
};

export default MyProjects;
