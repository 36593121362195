import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Button, Checkbox, Typography, Box } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import cancel from "../../assets/images/addTip.svg";
import Template from "../../components/shared/Template";

const useStyles = makeStyles((theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "self-start",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "500px",
    },
  },
}));

const DeleteAccount = ({ firefighter = false }) => {
  const classes = useStyles();

  const [confirmation, setConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const handleConfirmation = () => {
    setConfirmation(!confirmation);

    if (!confirmation) {
      setErrorMessage(false);
    }
  };

  const handleDeleteAccount = () => {
    if (!confirmation) {
      setErrorMessage(true);
      return;
    }

    const bodyMessage = firefighter
      ? `Full Name: \n\nEmail: \n\nFire Department: \n\nOrganization: \n\nIAFF Member Number: \n\nReason for Account Deletion:`
      : `Full Name: \n\nEmail: \n\nReason for Account Deletion:`;

    const body = encodeURIComponent(bodyMessage);
    window.open(
      `mailto:support@hirehelpfulheroes.com?subject=Delete%20User%20Account&body=${body}`,
      "_blank"
    ); //TODO: change the email account
  };

  return (
    <Template
      title={`Delete ${firefighter ? "Firefighter " : ""} Account`}
      subtitle="We're sorry to see you go! If you wish to permanently delete your Helpful Heroes account, please click the button below to send us an email with your request. Once your request is received, your account and all associated data will be permanently removed from our system within 30 days. Please note that this action cannot be undone."
      image={cancel}
      children={
        <div className={classes.body}>
          <Typography variant="subtitle2">
            In the email, please include the following information:
          </Typography>

          <Typography variant="body1">Your Full Name:</Typography>
          <Typography variant="body1">Your Email Address:</Typography>
          {firefighter && (
            <>
              <Typography variant="body1">Your Fire Department</Typography>
              <Typography variant="body1">Your Organization:</Typography>
              <Typography variant="body1">Your IAFF Member Number:</Typography>
            </>
          )}
          <Typography variant="body1">Reason for Account Deletion:</Typography>

          <Box className={classes.checkboxContainer}>
            <Checkbox value={confirmation} onChange={handleConfirmation} />
            <Typography
              variant="body1"
              style={{ textAlign: "center", marginTop: "10px" }}
            >
              Confirmation that you understand your account and all associated
              data will be permanently deleted from Helpful Heroes's system
              within 30 days.
            </Typography>
          </Box>

          {errorMessage && (
            <Typography
              style={{
                textAlign: "center",
                marginTop: "10px",
                color: "red",
              }}
            >
              Please check the consent checkbox before proceeding
            </Typography>
          )}

          <Button
            onClick={handleDeleteAccount}
            sx={{
              width: { md: 259, sx: "90%" },
              height: 46,
              marginTop: "1.25rem",
              mb: "1.875rem",
            }}
            color={confirmation ? "error" : "secondary"}
            variant="contained"
          >
            Send Email
          </Button>
          <div style={{ marginBottom: 125 }}>
            <InstagramIcon sx={{ color: "#c1c1c1" }} />
            <FacebookIcon
              sx={{ ml: "1.25rem", mr: "1.25rem", color: "#c1c1c1" }}
            />
            <TwitterIcon sx={{ color: "#c1c1c1" }} />
          </div>
        </div>
      }
    />
  );
};

export default DeleteAccount;
