import { Box, Typography, Button, Divider, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

//Components
import Modal from "../../../components/shared/Modal";
import ProjectPhotos from "./ProjectPhotos";
import ChipStatus from "../../../components/shared/ChipStatus";

//Services
import ProjectService from "../../../services/projectService";

const equipmentOptions = ["yes", "no", "maybe"];

const preferredDateOptions = [
  { value: "flexible", label: "I’m Flexible" },
  { value: "less_than_a_week", label: "<1 week" },
  { value: "one_to_two_weeks", label: "1-2 Weeks" },
  { value: "more_than_two_weeks", label: "2+ weeks" },
];

const EditWorkModal = ({ open, project, onClose }) => {
  const {
    id,
    title,
    description,
    status,
    images,
    service,
    address,
    notes,
    preferredDate,
    specialEquipment,
    equipmentDetails,
  } = project;

  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      title: title,
      description: description,
      preferred_date: preferredDate || "flexible",
      special_equipment: specialEquipment || "yes",
      equipment_details: equipmentDetails || "",
      notes: notes,
      images: images,
    },
  });

  const updateWork = useMutation({
    mutationFn: ({ id, work }) => ProjectService.updateWork(id, work),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["getWorkById"] });
      onClose();
    },
    onError: (error) => {
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
      onClose();
    },
  });

  const onSubmit = (data) => {
    const updatedURLImages =
      data.images.map((image) => (image.id ? { id: image.id } : image)) || [];

    const work = {
      ...data,
      images: updatedURLImages,
    };

    updateWork.mutate({ id, work });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        display="flex"
        flexDirection="column"
        sx={{ overflowY: "scroll", maxHeight: "650px" }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="h5" align="center" mb={4}>
          Edit project
        </Typography>

        <Typography variant="h3" mb={2}>
          {title}
        </Typography>

        <Box display="flex" flexDirection="row" alignItems="center" gap={2}>
          <ChipStatus status={status} />

          <Typography>{service.name}</Typography>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h5">Project Location</Typography>
          <TextField defaultValue={address.fullAddress} disabled />
        </Box>

        <Divider sx={{ my: 3 }} />

        <ProjectPhotos watch={watch} setValue={setValue} />

        <Box display="flex" flexDirection="column" gap={1} mb={3}>
          <Typography variant="h5">Project Title</Typography>
          <Controller
            name="title"
            control={control}
            rules={{ required: "Project Title is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                error={!!errors.title}
                helperText={errors.title && errors.title.message}
              />
            )}
          />
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={4}>
          <Typography variant="h5">Project Description</Typography>
          <Typography>Tell firefighters about your project.</Typography>
          <Controller
            name="description"
            control={control}
            rules={{ required: "Project Description is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Describe your project"
                error={!!errors.description}
                helperText={errors.description && errors.description.message}
              />
            )}
          />
          <Typography sx={{ fontSize: "12px" }}>
            *Please note that plumbing, electrical and HVAC are best handled by
            professionals
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={4}>
          <Typography variant="h5">
            Is there special equipment needed?
          </Typography>

          <Box display="flex" flexDirection="row" gap={2} mt={1}>
            <Controller
              name="special_equipment"
              control={control}
              render={({ field }) => (
                <>
                  {equipmentOptions.map((option) => (
                    <Button
                      key={option}
                      variant="contained"
                      color={field.value === option ? "navy" : "lightGrey"}
                      style={{
                        color: field.value === option ? "white" : "navy",
                        marginRight: 8,
                      }}
                      onClick={() => field.onChange(option)}
                      name={option}
                    >
                      {option === "maybe"
                        ? "I’m not sure"
                        : option.charAt(0).toUpperCase() + option.slice(1)}
                    </Button>
                  ))}
                </>
              )}
            />
          </Box>

          {watch("special_equipment") === "yes" && (
            <Box display="flex" flexDirection="column" gap={1} mt={1}>
              <Typography sx={{ fontSize: "12px" }}>
                Describe what special equipment is required for your project.
              </Typography>

              <Controller
                name="equipment_details"
                control={control}
                rules={{
                  required: {
                    value: watch("special_equipment") === "yes",
                    message: "Equipment details are required",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!errors.equipment_details}
                    helperText={
                      errors.equipment_details &&
                      errors.equipment_details.message
                    }
                  />
                )}
              />
            </Box>
          )}
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={4}>
          <Typography variant="h5">
            When would you like your project completed?
          </Typography>
          <Typography>
            You will select a specific date later based on your firefighter’s
            availability.
          </Typography>

          <Box display="flex" flexDirection="row" gap={2} mt={1}>
            <Controller
              name="preferred_date"
              control={control}
              render={({ field }) => (
                <>
                  {preferredDateOptions.map((option) => (
                    <Button
                      key={option.value}
                      variant="contained"
                      color={
                        field.value === option.value ? "navy" : "lightGrey"
                      }
                      style={{
                        color: field.value === option.value ? "white" : "navy",
                        marginRight: 8,
                      }}
                      onClick={() => field.onChange(option.value)}
                      name={option.value}
                      size="small"
                    >
                      {option.label}
                    </Button>
                  ))}
                </>
              )}
            />
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap={1} mb={4}>
          <Typography variant="h5">Additional Notes</Typography>
          <Typography>
            Anything else firefighters need to know about your project,
            availability, etc.
          </Typography>
          <Controller
            name="notes"
            control={control}
            render={({ field }) => <TextField {...field} />}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          disabled={updateWork.isPending}
        >
          Save Changes
        </Button>
      </Box>
    </Modal>
  );
};

export default EditWorkModal;
