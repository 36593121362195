import { Avatar, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaceIcon from "@mui/icons-material/Place";
import { JOB_STATUSES } from "../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: "12px",
    border: "1px solid #E8E8E8",
    backgroundColor: "white",
    padding: "25px",
  },
  projectDetailRow: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    marginBottom: "0.75rem",
  },
  avatar: {
    backgroundColor: "lightGrey",
    marginRight: "0.688rem",
    height: "2.625rem",
    width: "2.625rem",
  },
  avatarText: {
    marginLeft: "0.875rem",
  },
  serviceIcon: {
    height: "2.625rem",
    width: "2.625rem",
    marginRight: "0.6rem",
    objectFit: "scale-down",
  },
  icon: {
    width: "1.125rem",
    color: "#022752",
  },
}));

const ProjectInformation = ({
  serviceIcon,
  serviceName,
  address,
  preferredDate,
  avgCost,
  status,
  totalPrice,
}) => {
  const classes = useStyles();

  const renderSwitch = () => {
    switch (preferredDate) {
      case "less_than_a_week":
        return "<1 week";
      case "flexible":
        return "Flexible";
      case "one_to_two_weeks":
        return "1-2 weeks";
      case "more_than_two_weeks":
        return "2+ weeks";
      default:
        return "Flexible";
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.projectDetailRow}>
        <img
          alt="icon"
          src={serviceIcon}
          color="#022752"
          className={classes.serviceIcon}
        />

        <Typography variant="h5" color="#022752">
          {serviceName}
        </Typography>
      </div>

      <div className={classes.projectDetailRow}>
        <Avatar className={classes.avatar}>
          <PlaceIcon style={{ width: "1.125rem" }} className={classes.icon} />
        </Avatar>

        <Typography variant="h5" color="#022752">
          {address}
        </Typography>
      </div>

      <div className={classes.projectDetailRow}>
        <Avatar className={classes.avatar}>
          <AccessTimeIcon
            style={{ width: "1.125rem" }}
            className={classes.icon}
          />
        </Avatar>

        <Typography variant="h5" color="#022752">
          {renderSwitch()}
        </Typography>
      </div>

      <div className={classes.projectDetailRow}>
        <Avatar className={classes.avatar}>
          <AttachMoneyIcon
            style={{ width: "1.125rem" }}
            className={classes.icon}
          />
        </Avatar>

        <Typography variant="h5" color="#022752">
          {status !== JOB_STATUSES.COMPLETED ? avgCost : `$${totalPrice}`}
        </Typography>
      </div>
    </div>
  );
};

export default ProjectInformation;
