/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useDispatch } from "react-redux";
import useChatBoards from "../../hooks/useChatBoards";
import { getProjectChatBoard } from "../../redux/actions/projectActions";
import { getChatBoards } from "../../redux/actions/userActions";
import ImageIcon from "@mui/icons-material/Image";
import { JOB_STATUSES } from "../../utils/constants";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: "0 !important",
    alignItems: "center !important",
    cursor: "pointer",
  },
}));

const statusMap = {
  active: JOB_STATUSES.ASSIGNED,
  complete: JOB_STATUSES.COMPLETED,
};

const ChatList = ({ setIsMobile = () => {} }) => {
  const { chatBoards } = useChatBoards();
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchTerm = "data:image/";

  const [tab, setTab] = React.useState("active");

  const handleTab = (tab) => {
    setTab(tab);
  };

  const visibleChats = React.useMemo(() => {
    return (
      chatBoards?.filter((item) => item.jobPostStatus === statusMap[tab]) || []
    );
  }, [tab, chatBoards]);

  React.useEffect(() => {
    dispatch(getChatBoards());
  }, [dispatch]);

  React.useEffect(() => {
    if (visibleChats?.length){
      dispatch(getProjectChatBoard(visibleChats?.at(0)?.jobPostId));
    }
  }, [chatBoards]);

  const handleItemClick = (id) => {
    dispatch(getProjectChatBoard(id));
  };

  return (
    <>
      {visibleChats && (
        <Box display="flex" alignItems="center" gap={4} mb={2}>
          <Typography
            component="span"
            onClick={() => handleTab("active")}
            color={tab === "active" && "primary"}
            sx={{ cursor: "pointer", fontSize: 17 }}
          >
            Active
          </Typography>

          <Typography
            component="span"
            onClick={() => handleTab("complete")}
            color={tab === "complete" && "primary"}
            sx={{ cursor: "pointer", fontSize: 17 }}
          >
            Complete
          </Typography>
        </Box>
      )}

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          overflowY: "scroll",
        }}
      >
        {visibleChats?.map((chatBoard, index) => (
          <ListItem
            onClick={() => {
              handleItemClick(chatBoard.jobPostId);
              setIsMobile();
            }}
            className={classes.listItem}
            alignItems="center"
            key={index}
          >
            <ListItemAvatar>
              <Avatar
                sx={{
                  width: "3.5rem",
                  height: "3.5rem",
                  marginRight: "1.5rem",
                  bgcolor: "background.paper",
                }}
                src={chatBoard.jobImage}
              />
            </ListItemAvatar>
            <ListItemText
              primary={
                <span>
                  <Typography variant="title3">
                    {chatBoard.jobPostTitle}
                  </Typography>
                  {chatBoard?.lastMessage?.lastIndexOf(searchTerm) ? (
                    <Typography
                      sx={{
                        my: "0.1rem",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      variant="body2"
                    >
                      {chatBoard.lastMessage}
                    </Typography>
                  ) : (
                    <div
                      style={{
                        my: "0.1rem",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <ImageIcon />
                      <Typography>Image</Typography>
                    </div>
                  )}
                </span>
              }
              secondary={
                <Typography mb={2} variant="body2">
                  {moment(chatBoard?.lastMessageAt).isValid() &&
                    moment(chatBoard.lastMessageAt).fromNow()}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default ChatList;
