import "./App.css";
import React from "react";
import { QueryParamProvider } from "use-query-params";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LetsStarted from "./pages/LetsStarted";
import CreateProject from "./pages/CreateProject";
import SignUp from "./pages/SignUp";
import { overridesTheme } from "./theme";
import { ThemeProvider } from "@mui/material/styles";
import LogIn from "./pages/LogIn/index";
import MyProjects from "./pages/MyProjects";
import Notifications from "./pages/Notifications";
import PrivateRoute from "./components/shared/PrivateRoute";
import Messages from "./pages/Messages";
import MyAccount from "./pages/MyAccount";
import "react-toastify/dist/ReactToastify.css";
import ProjectDetail from "./pages/ProjectDetail";
import Success from "./pages/Success";
import ComingSoon from "./pages/ComingSoon";
import VerifyAccount from "./pages/VerifyAccount";
import MobileMenu from "./pages/MobileMenu";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import { IntercomProvider } from "react-use-intercom";
import AddTip from "./pages/AddTip";
import CancelProject from "./pages/CancelProject";
import ProjectCancelled from "./pages/ProjectCancelled";
import InsertPasswordCode from "./pages/InsertPasswordCode";
import DeleteAccount from "./pages/DeleteAccount";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ROUTES } from "./utils/constants";
import ContactCenter from "./pages/ContactCenter";

function App() {
  const queryClient = new QueryClient();

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />

        <ThemeProvider theme={overridesTheme}>
          <ToastContainer />
          <Router>
            <QueryParamProvider>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <IntercomProvider appId="gz93o0kh">
                  <Routes>
                    <Route exact path="/" element={<LetsStarted />} />
                    <Route
                      exact
                      path="/new-project"
                      element={<CreateProject />}
                    />
                    <Route exact path="/sign-up" element={<SignUp />} />
                    <Route exact path="/login" element={<LogIn />} />
                    <Route exact path="/success-project" element={<Success />} />
                    <Route exact path="/coming-soon" element={<ComingSoon />} />
                    <Route
                      exact
                      path="/delete-account"
                      element={<DeleteAccount />}
                    />
                    <Route
                      exact
                      path="/delete-firefighter-account"
                      element={<DeleteAccount firefighter />}
                    />
                    <Route
                      exact
                      path="/cancel-project/:id"
                      element={<CancelProject />}
                    />
                    <Route
                      exact
                      path="/project-cancelled"
                      element={<ProjectCancelled />}
                    />
                    <Route
                      exact
                      path="/verify-account"
                      element={<VerifyAccount />}
                    />

                    <Route exact path="/menu" element={<MobileMenu />} />
                    <Route
                      exact
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route
                      exact
                      path="/insert-code/:email"
                      element={<InsertPasswordCode />}
                    />
                    <Route
                      exact
                      path="/reset-password/:code"
                      element={
                        <PrivateRoute>
                          <ResetPassword />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/notifications"
                      element={
                        <PrivateRoute>
                          <Notifications />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/messages"
                      element={
                        <PrivateRoute>
                          <Messages />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/my-account"
                      element={
                        <PrivateRoute>
                          <MyAccount />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/my-projects"
                      element={
                        <PrivateRoute>
                          <MyProjects />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/project-detail/:id"
                      element={
                        <PrivateRoute>
                          <ProjectDetail />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/add-tip/:id"
                      element={
                        <PrivateRoute>
                          <AddTip />
                        </PrivateRoute>
                      }
                    />
                    <Route exact path={ROUTES.CONTACT_CENTER} element={<ContactCenter />} />
                  </Routes>
                </IntercomProvider>
             </LocalizationProvider>
            </QueryParamProvider>
          </Router>
        </ThemeProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
