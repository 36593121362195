const chipColor = (status) => {
  const colorMap = {
    completed: { backgroundColor: "#002E6533", color: "#002E65" },
    waiting: { backgroundColor: "#2B8CED33", color: "#2B8CED" },
    pending: { backgroundColor: "#FAB54733", color: "#FAB547" },
    draft: { backgroundColor: "#7C92AA33", color: "#7C92AA" },
    assigned: { backgroundColor: "#2B8CED33", color: "#2B8CED" },
    scheduled: { backgroundColor: "#2B8CED33", color: "#2B8CED" },
  };

  return colorMap[status] || { backgroundColor: "#E8E8E8", color: "#000000" };
};

export default chipColor;
