import { Chip } from "@mui/material";

//Helpers
import chipColor from "../../../helpers/chipColor";

const ChipStatus = ({ status }) => {
  return (
    <Chip
      label={status}
      sx={{
        backgroundColor: chipColor(status).backgroundColor,
        color: chipColor(status).color,
        textTransform: "capitalize",
      }}
    />
  );
};

export default ChipStatus;
