import { useMemo, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

// Components
import Modal from "../../../components/shared/Modal";
import ScheduleProject from "./ScheduleProject";
import PaymentInformation from "./PaymentInformation";
import ProjectConfirmation from "./ProjectConfirmation";
import ProjectSuccess from "./ProjectSuccess";

//Services
import ProjectService from "../../../services/projectService";

const InterestedFirefighterSelected = ({
  open,
  firefighterId,
  workId,
  onClose,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);
  const [giftCode, setGiftCode] = useState("");
  const [promoCode, setPromoCode] = useState("");
  const [message, setMessage] = useState("");
  const [work, setWork] = useState(null);

  const queryClient = useQueryClient();

  const { data: profile, isLoading } = useQuery({
    enabled: open && !!firefighterId && !!workId,
    queryKey: ["getFirefighterInformation"],
    queryFn: async () => {
      const data = await ProjectService.getFirefighter({
        ffId: firefighterId,
        workId,
      });
      return data.fireFighterWorkInterest || {};
    },
  });

  const assignFFToWork = useMutation({
    mutationFn: ProjectService.assignFFToWork,
    onSuccess: async (data) => {
      setWork(data.work);
      handleNextStep();
    },
    onError: (error) => {
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
      onClose();
    },
  });

  const handleNextStep = () => setCurrentStep(currentStep + 1);

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
    currentStep - 1 === 0 && onClose();
  };

  const handleSelectOption = (option) => {
    const isSelected =
      selectedOption && selectedOption.availableAt === option.availableAt;
    setSelectedOption(isSelected ? null : option);
  };

  const progressWidth = useMemo(() => {
    return (100 / 4) * currentStep + "%";
  }, [currentStep]);

  const onConfirm = () => {
    const payload = {
      workId,
      data: {
        fire_fighter_id: profile.fireFighter.id,
        work_assignment_attributes: {
          fire_fighter_availability_id: selectedOption?.id,
          message,
        },
        ...(promoCode && { discount_coupon_code: promoCode }),
        ...(giftCode && { gift_up_coupon: giftCode }),
      },
    };

    assignFFToWork.mutate(payload);
  };

  const handleClose = async () => {
    await queryClient.invalidateQueries({ queryKey: ["getWorkById", +workId] });
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      showProgress={currentStep > 0 && currentStep < 4}
      progressWidth={progressWidth}
    >
      {isLoading ? (
        <Typography align="center">Loading...</Typography>
      ) : (
        <Box sx={{ overflowY: "scroll", maxHeight: "650px" }}>
          {currentStep === 1 && (
            <ScheduleProject
              profile={profile}
              selectedOption={selectedOption}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
              onSelectedChange={handleSelectOption}
            />
          )}
          {currentStep === 2 && (
            <PaymentInformation
              profile={profile}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
              setGiftCode={setGiftCode}
              setPromoCode={setPromoCode}
            />
          )}
          {currentStep === 3 && (
            <ProjectConfirmation
              profile={profile}
              message={message}
              onPreviousStep={handlePreviousStep}
              setMessage={setMessage}
              onConfirm={onConfirm}
            />
          )}
          {currentStep === 4 && (
            <ProjectSuccess
              profile={profile}
              work={work}
              availability={selectedOption}
              onClose={handleClose}
            />
          )}
        </Box>
      )}
    </Modal>
  );
};

export default InterestedFirefighterSelected;
