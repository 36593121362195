export const CARD_NUMBER_OPTIONS = {
  placeholder: "Card Number",
  showIcon: true,
};

export const CARD_EXPIRATION_NOPTIONS = {
  placeholder: "MM / YY",
};

export const CARD_CVC_OPTIONS = {
  placeholder: "CVC",
};
