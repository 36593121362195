import { Typography, Box, Button, Avatar, IconButton } from "@mui/material";

//Icons
import { ArrowBack } from "@mui/icons-material";

const ScheduleProject = ({
  profile,
  selectedOption,
  onNextStep,
  onPreviousStep,
  onSelectedChange,
}) => {
  const { address, availability, fireFighter } = profile;

  return (
    <>
      <IconButton onClick={onPreviousStep}>
        <ArrowBack />
      </IconButton>

      <Typography variant="h3" align="center" mb={2}>
        Schedule Project
      </Typography>

      <Typography align="center" mb={2}>
        Select a date that works for you.
      </Typography>

      <Box display="flex" flexDirection="column" alignItems="center" mt={1}>
        <Avatar
          alt={fireFighter.firstName}
          src={fireFighter.profilePicture}
          sx={{
            width: 75,
            height: 75,
            color: "lightgray",
          }}
        />

        <Typography variant="h5" mt={2} mb={1}>
          {fireFighter.firstName} {fireFighter.lastName}
        </Typography>

        <Typography>{address.fullAddress}</Typography>

        <Box my={3}>
          {availability && availability.length > 0 ? (
            availability.map((item, index) => (
              <Button
                key={index}
                fullWidth
                onClick={() => onSelectedChange(item)}
                variant={
                  selectedOption?.availableAt === item?.availableAt
                    ? "contained"
                    : "outlined"
                }
                sx={{
                  marginBottom: "10px",
                  "&:hover": {
                    backgroundColor:
                      selectedOption?.availableAt === item?.availableAt
                        ? "#003366"
                        : "#f0f0f0",
                  },
                }}
              >
                {item.availableAt}
              </Button>
            ))
          ) : (
            <Typography>No availability</Typography>
          )}
        </Box>

        <Button
          variant="contained"
          fullWidth
          onClick={onNextStep}
          disabled={!selectedOption}
        >
          Continue
        </Button>
      </Box>
    </>
  );
};

export default ScheduleProject;
