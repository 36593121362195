import { Typography, Box, IconButton, Button } from "@mui/material";

//Components
import PaymentCreditCardForm from "./PaymentCreditCardForm";
import PaymentFAQs from "./PaymentFAQs";

//Icons
import { ArrowBack } from "@mui/icons-material";

const PaymentInformation = ({
  profile,
  onNextStep,
  onPreviousStep,
  setGiftCode,
  setPromoCode,
}) => {
  return (
    <>
      <IconButton onClick={onPreviousStep}>
        <ArrowBack />
      </IconButton>

      <Typography variant="h3" align="center" mb={2}>
        Payment Information
      </Typography>

      <Typography align="center" mb={2}>
        You will be charged{" "}
        <span style={{ color: "#002E65" }}>
          $50 once your project is submitted
        </span>{" "}
        to ensure our firefighters will be paid if they accept your job.
      </Typography>

      <Typography align="center" mb={2}>
        This <span style={{ color: "#002E65" }}>$50 will be credited</span>{" "}
        toward the final project cost.
      </Typography>

      <PaymentCreditCardForm
        setGiftCode={setGiftCode}
        setPromoCode={setPromoCode}
        onNextStep={onNextStep}
      />

      <PaymentFAQs />
    </>
  );
};

export default PaymentInformation;
