import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../services/userService";
import parseError from "../../utils/parseError";

export const login = createAsyncThunk("user/login", async (user) => {
  try {
    const response = await userService.login({ user });
    return response?.data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const forgotPassword = createAsyncThunk(
  "users/password",
  async (user) => {
    try {
      const {
        data: { data },
      } = await userService.forgotPassword(user);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const updateAuthInfo = createAsyncThunk(
  "users/updateAuthInfo",
  async (data) => {
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async (payload) => {
    try {
      const {
        data: { data },
      } = await userService.resetPassword(
        payload.password,
        payload.passwordConfirmation,
        payload.authHeaders
      );
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const passwordCodeVerification = createAsyncThunk(
  "users/validate",
  async (payload) => {
    try {
      const {
        data: { data },
      } = await userService.passwordCodeVerification(payload);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const logout = createAction("user/logout");

export const signUp = createAsyncThunk("user/signup", async (user) => {
  try {
    const { data } = await userService.signUp({ user });
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getUser = createAsyncThunk("user/profile", async () => {
  try {
    const { data } = await userService.getUser();
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const getCreditCards = createAsyncThunk(
  "user/getCreditCards",
  async () => {
    try {
      const { data } = await userService.getCreditCard();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getNotifications = createAsyncThunk(
  "user/notifications",
  async (user) => {
    try {
      const { data } = await userService.getNotifications();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const newNotifications = createAsyncThunk(
  "user/pending_notifications",
  async (user) => {
    try {
      const { data } = await userService.newNotifications();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getUnseenMessages = createAsyncThunk(
  "user/chat_boards/unseen_messages",
  async () => {
    try {
      const { data } = await userService.getUnseenMessages();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getChatBoards = createAsyncThunk(
  "user/chat_boards",
  async (user) => {
    try {
      const { data } = await userService.getChatBoards();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const getJobs = createAsyncThunk("user/jobs", async (user) => {
  try {
    const { data } = await userService.getJobs();
    return data;
  } catch ({ response: { data } }) {
    console.error(data);
  }
});

export const updateUser = createAsyncThunk("user/update", async (user) => {
  try {
    const { data } = await userService.updateUser(user);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const verifyUser = createAsyncThunk("user/verify", async (code) => {
  try {
    const { data } = await userService.verifyUser(code);
    return data;
  } catch ({ response: { data } }) {
    throw parseError(data);
  }
});

export const verifyPasswordResetCode = createAsyncThunk(
  "user/verifyPasswordResetCode",
  async (code) => {
    try {
      const { data } = await userService.verifyResetPasswordCode(code);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const resendVerifyCode = createAsyncThunk(
  "user/verifications",
  async () => {
    try {
      const { data } = await userService.resendVerifyCode();
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addCreditCard = createAsyncThunk(
  "user/addCreditCard",
  async (token) => {
    try {
      const { data } = await userService.addCreditCard(token);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
);

export const addCoupon = createAsyncThunk(
  "user/addCoupon",
  async (id) => {
    try {
      const { data } = await userService.addCoupon(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
)

export const validatePromoCode = createAsyncThunk(
  "user/validatePromoCode",
  async (id) => {
    try {
      const { data } = await userService.validatePromoCode(id);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
)

export const updateSession = createAction("session/update");

export const { fulfilled: addCreditCardFulfilled } = addCreditCard;
export const { fulfilled: getCreditCardsFulfilled } = getCreditCards;
export const { fulfilled: loginFulfilled, rejected: loginRejected } = login;
export const { fulfilled: signUpFulfilled } = signUp;
export const { fulfilled: getUserFulfilled } = getUser;
export const { fulfilled: getNotificationsFulfilled } = getNotifications;
export const { fulfilled: newNotificationsFulfilled } = newNotifications;
export const { fulfilled: getChatBoardsFulfilled } = getChatBoards;
export const { fulfilled: updateUserFulfilled, rejected: updateUserRejected } =
  updateUser;
export const { fulfilled: verifyUserFulfilled, rejected: verifyUserRejected } =
  verifyUser;
export const { fulfilled: getJobsFulfilled } = getJobs;
export const { fulfilled: resetPasswordFulfilled } = resetPassword;
export const { fulfilled: updateAuthInfoFulfilled } = updateAuthInfo;
export const { fulfilled: resendVerifyCodeFulfilled } = resendVerifyCode;
export const { fulfilled: getUnseenMessagesFulfilled } = getUnseenMessages;
