const DarkCircleIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5 8.5C14.5 6.1875 13.25 4.0625 11.25 2.875C9.21875 1.71875 6.75 1.71875 4.75 2.875C2.71875 4.0625 1.5 6.1875 1.5 8.5C1.5 10.8438 2.71875 12.9688 4.75 14.1562C6.75 15.3125 9.21875 15.3125 11.25 14.1562C13.25 12.9688 14.5 10.8438 14.5 8.5ZM0 8.5C0 5.65625 1.5 3.03125 4 1.59375C6.46875 0.15625 9.5 0.15625 12 1.59375C14.4688 3.03125 16 5.65625 16 8.5C16 11.375 14.4688 14 12 15.4375C9.5 16.875 6.46875 16.875 4 15.4375C1.5 14 0 11.375 0 8.5Z"
      fill="#1B365F"
    />
  </svg>
);

export default DarkCircleIcon;
