import { Typography, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useSession from "../../../hooks/useSession";
import { getUser, updateUser } from "../../../redux/actions/userActions";
import HelpfulHeroesPhoneInput from "../../shared/HelpfulHeroesPhoneField";
import HelpfulHeroesTextField from "../../shared/HelpfulHeroesTextField";
import AddressesList from "../AddressesList";

const useStyles = makeStyles((theme) => ({
  contactInformationFormRowInput: {
    marginBottom: "1rem !important",
  },
  inputsContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
      alignSelf: "center",
    },
  },
  inputsContainertwo: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    marginBottom: "1.7rem",
  },
  form: {
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    padding: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
}));

const PersonalInfoForm = ({ image }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSession();
  const [locationValue, setLocationValue] = React.useState(
    user?.address?.fullAddress || null
  );
  const [inputValue, setInputValue] = React.useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [editAddress, setEditAddress] = useState(null);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadingAddress, setLoadingAddress] = useState(false);

  const onSubmit = async (data) => {
    const payload = !!locationValue
      ? {
          user: {
            ...data,
            addressesAttributes: [
              {
                id: user?.address?.id,
                fullAddress: locationValue?.description,
              },
            ],
            selectedAddressId: user?.address.id,
            profile_picture: image,
          },
        }
      : { user: data };

    const response = await dispatch(updateUser(payload));

    if (response?.error) {
      toast.error(
        <Typography color="error">{response?.error?.message}</Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }

    toast.success(
      <Typography color="success">
        Your Personal Info has been updated successfully.
      </Typography>,
      {
        position: "top-center",
      }
    );
  };

  const handleSelectAddress = (address) => {
    if (address) {
      setDefaultAddress(user?.address.id === address.id);
      setEditAddress(address);
    }
  };

  const handleRemoveAddress = async (id) => {
    const payload = {
      user: {
        addressesAttributes: [
          {
            id,
            _destroy: "true",
          },
        ],
      },
    };

    const response = await dispatch(updateUser(payload));

    if (response?.error) {
      toast.error(
        <Typography color="error">{response?.error?.message}</Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }

    handleCancel();

    await dispatch(getUser());

    toast.success(
      <Typography color="success">
        Your address has been updated successfully.
      </Typography>,
      {
        position: "top-center",
      }
    );
  };

  const handleSubmitEditAddress = async (e) => {
    e.preventDefault();

    if (!!!locationValue && !editAddress) return;

    setIsSubmitted(true);

    const payload = {
      user: {
        ...((defaultAddress && { selectedAddressId: editAddress.id }) || {}),
        addressesAttributes: [
          {
            id: editAddress.id,
            fullAddress:
              editAddress?.fullAddress || locationValue?.description
                ? locationValue.description
                : locationValue,
            state: editAddress?.state,
            city: editAddress?.city,
            zip: editAddress?.zip,
            country: editAddress?.country,
            street: editAddress?.street,
            unit: editAddress?.unit,
            label: editAddress?.label,
          },
        ],
      },
    };

    const response = await dispatch(updateUser(payload));

    if (response?.error) {
      toast.error(
        <Typography color="error">{response?.error?.message}</Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }

    handleCancel();

    setIsSubmitted(false);

    await dispatch(getUser());

    toast.success(
      <Typography color="success">
        Your default address has been updated successfully.
      </Typography>,
      {
        position: "top-center",
      }
    );
  };

  const handleCancel = () => {
    setEditAddress(null);
    setDefaultAddress(false);
  };

  const handleChange = useCallback(
    (next) => {
      setEditAddress({ ...editAddress, ...next });
    },
    [editAddress, setEditAddress]
  );

  useEffect(() => {
    user && setLocationValue(user?.address?.fullAddress || null);
  }, [user]);

  useEffect(() => {
    if (!isSubmitted && typeof locationValue === "object") {
      setLoadingAddress(true);

      const label = editAddress?.label || "";

      handleCancel();

      setTimeout(() => {
        setEditAddress((prev) => ({ ...prev, label, ...locationValue }));
        setLoadingAddress(false);
      }, 500);
    }
  }, [user, locationValue, isSubmitted]);

  if (Object.keys(user ?? {}).length === 0) return null;

  return (
    <>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.sectionContainer}>
          <Typography sx={{ mb: "0.8rem" }} variant="h5">
            Contact Information
          </Typography>
          <div className={classes.inputsContainer}>
            <HelpfulHeroesTextField
              name="firstName"
              control={control}
              defaultValue={user?.firstName}
              controller
              placeholder="First Name*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "50%" },
                mr: "1rem",
                color: "navy",
              }}
              className={classes.contactInformationFormRowInput}
              error={errors?.firstName}
              helperText={errors.firstName && "First Name is required."}
            />
            <HelpfulHeroesTextField
              name="lastName"
              control={control}
              controller
              defaultValue={user?.lastName}
              className={classes.contactInformationFormRowInput}
              placeholder="Last Name*"
              sx={{ width: { lg: "100%", md: "40%", xs: "50%" } }}
              error={errors?.lastName}
              helperText={errors.lastName && "Last Name is required."}
            />
          </div>
          <div className={classes.inputsContainertwo}>
            <HelpfulHeroesTextField
              name="email"
              control={control}
              controller
              disabled
              defaultValue={user?.email}
              className={classes.contactInformationFormRowInput}
              placeholder="Email Address*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "100%" },
                alignSelf: { xs: "center", sm: "start" },
                color: "navy",
                mr: { sm: "1rem" },
              }}
              error={errors?.email}
              helperText={
                (errors.email?.type === "required" && "Email is required.") ||
                (errors.email?.type === "pattern" &&
                  "Email must be a valid email address.")
              }
            />
            <HelpfulHeroesPhoneInput
              name="phoneNumber"
              control={control}
              defaultValue={user?.phoneNumber}
              controller
              id="phone-input"
              placeholder="Mobile Phone Number*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "100%" },
                alignSelf: { xs: "center", sm: "start" },
                color: "navy",
              }}
              error={errors?.phone}
              helperText={errors?.phone && "Mobile Phone Number is required."}
            />
          </div>
        </div>
        <Button
          type="submit"
          color="navy"
          variant="contained"
          sx={{
            width: { md: "194px", sm: "194px", xs: "70%" },
            alignSelf: { xs: "center", md: "start", sm: "start" },
            color: "white",
          }}
        >
          Save Changes
        </Button>
      </form>

      <AddressesList
        address={user?.address}
        addresses={user?.addresses}
        editAddress={editAddress}
        locationValue={locationValue}
        loadingAddress={loadingAddress}
        setLocationValue={setLocationValue}
        defaultAddress={defaultAddress}
        setDefaultAddress={setDefaultAddress}
        inputValue={inputValue}
        setInputValue={setInputValue}
        setValue={setValue}
        handleChange={handleChange}
        handleCancel={handleCancel}
        handleRemoveAddress={handleRemoveAddress}
        handleSelectAddress={handleSelectAddress}
        handleSubmitEditAddress={handleSubmitEditAddress}
      />
    </>
  );
};

export default PersonalInfoForm;
