import { useState } from "react";
import { Box, Typography, TextField, Button, Rating } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

//Components
import Modal from "../../../../components/shared/Modal";

//Services
import ProjectService from "../../../../services/projectService";

//Icons
import { Star } from "@mui/icons-material";

const reviewsOption = [
  { value: "quality_work", label: "Quality Work" },
  { value: "good_communication", label: "Good Communication" },
  { value: "arrived_on_time", label: "Arrived On Time" },
  { value: "fair-pricing", label: "Fair Pricing" },
];

const useStyles = makeStyles((theme) => ({
  starIcon: { color: "#022752", fontSize: "3rem" },
  starEmptyIcon: { color: "lightgray", fontSize: "3rem" },
}));

const FirefighterWriteReviewModal = ({ open, workId, onClose }) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [reviewOption, setReviewOption] = useState("quality_work");

  const handleReviewOption = (option) => {
    setReviewOption(option);
  };

  const postReview = useMutation({
    mutationFn: ProjectService.postReview,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["getWorkById", +workId],
      });

      toast.success(
        <Typography color="success">Review sent successfully.</Typography>,
        {
          position: "top-center",
        }
      );

      onClose();
    },
    onError: (error) => {
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
    },
  });

  const onSubmit = () => {
    if (rating === 0) {
      toast.error(
        <Typography color="error">You have to specify a rating</Typography>,
        {
          position: "top-center",
        }
      );
      return;
    }

    const data = {
      review: {
        rating,
        highlight: reviewOption,
        comment: message,
      },
    };

    postReview.mutate({ workId, data });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box display="flex" flexDirection="column">
        <Typography variant="h3" align="center" mb={3}>
          Write a Review
        </Typography>

        <Typography align="center">
          How’d they do? Write a review for your firefighter to let other
          customers know.
        </Typography>

        <Box display="flex" flexDirection="column" my={4} gap={4}>
          <Rating
            size="large"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            icon={<Star fontSize="inherit" className={classes.starIcon} />}
            emptyIcon={
              <Star fontSize="inherit" className={classes.starEmptyIcon} />
            }
            sx={{
              alignSelf: "center",
            }}
          />

          <Box display="flex" flexWrap="wrap" gap={1.5} justifyContent="center">
            {reviewsOption.map((option) => (
              <Button
                key={option.value}
                variant="contained"
                size="small"
                color={reviewOption === option.value ? "navy" : "lightGrey"}
                style={{
                  color: reviewOption === option.value ? "white" : "navy",
                  padding: "10px",
                }}
                onClick={() => handleReviewOption(option.value)}
              >
                {option.label}
              </Button>
            ))}
          </Box>

          <TextField
            fullWidth
            multiline
            rows={6}
            value={message}
            onChangeCapture={(e) => setMessage(e.target.value)}
            placeholder="On time, prepared, great communication, got the job done right."
          />
        </Box>

        <Box display="flex" flexDirection="column" mt={2} gap={3}>
          <Button
            variant="contained"
            onClick={onSubmit}
            disabled={postReview.isPending}
          >
            Submit
          </Button>

          <Button variant="text" onClick={onClose}>
            No Thanks
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FirefighterWriteReviewModal;
