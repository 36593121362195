import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { faqs } from "./faqs";

//Utils
import { SUPPORT_URL } from "../../../../../utils/constants";

const PaymentFAQs = () => {
  const [showFaqs, setShowFaqs] = useState(false);

  const handleToggleFaqs = () => {
    setShowFaqs(!showFaqs);
  };

  const handleContactSupport = () => {
    window.open(SUPPORT_URL);
  };

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      {!showFaqs && (
        <Button variant="text" onClick={handleToggleFaqs}>
          View Payment FAQs
        </Button>
      )}

      {showFaqs && faqs.length > 0 && (
        <>
          {faqs.map((faq) => {
            return (
              <Box key={faq.id} display="flex" flexDirection="column" my={2}>
                <Typography variant="h5" mb={2}>
                  {faq.title}
                </Typography>
                <Typography>{faq.content}</Typography>
              </Box>
            );
          })}

          <Button
            variant="contained"
            onClick={handleContactSupport}
            sx={{ my: 2 }}
          >
            Contact Support
          </Button>

          <Button variant="text" onClick={handleToggleFaqs}>
            Hide Payment FAQs
          </Button>
        </>
      )}
    </Box>
  );
};

export default PaymentFAQs;
