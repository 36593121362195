import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import {
  Typography,
  Box,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

//Hooks
import { useDispatch } from "react-redux";
import useSession, { useCreditCard } from "../../../../../hooks/useSession";
import useDebounce from "../../../../../hooks/useDebounce";
import { useStatus } from "../../../../../hooks/useStatus";

//Actions
import {
  addCoupon,
  validatePromoCode,
} from "../../../../../redux/actions/userActions";
import {
  addCreditCard,
  getCreditCards,
  updateUser,
} from "../../../../../redux/actions/userActions";
import {
  FULFILLED,
  PENDING,
  REJECTED,
} from "../../../../../redux/constants/actionStatusConstants";

//Icons
import { Done } from "@mui/icons-material";

//Helpers
import {
  CARD_CVC_OPTIONS,
  CARD_NUMBER_OPTIONS,
  CARD_EXPIRATION_NOPTIONS,
} from "../../../../../helpers/stripeInputs";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    marginTop: "1rem",
  },
  stripeInput: {
    border: "solid 1px #e8e8e8",
    padding: "1rem 0.85rem",
    borderRadius: "0.3rem",
    marginBottom: "1rem",
    "&:hover": {
      border: "solid 1px #022752",
    },
  },
  spinner: {
    position: "absolute",
    top: 30,
    right: 20,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  doneIcon: {
    position: "absolute",
    top: "0.6rem",
    right: "-0.5rem",
  },
}));

const CreditCard = ({ setGiftCode, setPromoCode, onNextStep }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const { authenticated } = useSession();
  const { creditCard } = useCreditCard();
  const { status } = useStatus(addCoupon);
  const promoCodeStatus = useStatus(validatePromoCode);
  const [showPromoCode, setShowPromoCode] = useState(false);
  const [promoCode, setPromoNewCode] = useState("");
  const [promoCodeLog, setPromoCodeLog] = useState({
    hasError: false,
    log: "empty",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [showGiftCard, setShowGitfCard] = useState(false);
  const [giftPromoCode, setGiftPromoCode] = useState("");
  const [giftLog, setGiftLog] = useState({
    hasError: false,
    log: "empty",
  });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const debouncedGiftCode = useDebounce(giftPromoCode, 500);
  const debouncedPromoCode = useDebounce(promoCode, 500);

  const handleChange = (event) =>
    setError(event.error ? event.error.message : "");

  const toggleShowGiftCard = () => setShowGitfCard(!showGiftCard);
  const toggleShowPromoCode = () => setShowPromoCode(!showPromoCode);
  const toggleEditCard = () => setIsEditing(!isEditing);

  const handleGiftCodeChange = async (giftCode) => {
    if (giftCode !== "") {
      const response = await dispatch(addCoupon(giftCode));

      if (response?.meta?.requestStatus === FULFILLED) {
        if (response.payload?.coupon?.remainingValue > 0) {
          setGiftLog({
            hasError: false,
            log: `Success! You've applied $${response.payload?.coupon?.remainingValue} toward your project`,
          });
          setGiftCode(giftCode);
        } else {
          setGiftLog({
            hasError: true,
            log: "No balance remaining. Try another coupon.",
          });
        }
      } else if (response?.meta?.requestStatus === REJECTED) {
        setGiftLog({
          hasError: true,
          log: response.error?.message,
        });
      }
    } else {
      setGiftLog({
        hasError: false,
        log: "empty",
      });
    }
  };

  const handlePromoCodeChange = async (code) => {
    if (code !== "") {
      const response = await dispatch(validatePromoCode(code));
      const {
        meta: { requestStatus },
        payload: { discountCoupon: { percentageDiscount } = {} } = {},
        error,
      } = response;
      if (requestStatus === FULFILLED) {
        setPromoCodeLog({
          hasError: false,
          log: `Success! You've applied ${percentageDiscount}% off toward your project`,
        });
        setPromoCode(code);
      } else if (requestStatus === REJECTED) {
        setPromoCodeLog({
          hasError: true,
          log: error?.message,
        });
      }
      return;
    }
    setPromoCodeLog({
      hasError: false,
      log: "empty",
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!!creditCard && !isEditing) {
      onNextStep();
      return;
    }

    setLoading(true);

    let element = "";
    let createdToken = "";

    if (!stripe || !elements) return;

    element = elements.getElement(CardNumberElement);
    createdToken = await stripe.createToken(element);

    if (createdToken.error) {
      toast.error(
        <Typography color="error">
          There was an error trying to add your credit card in Stripe. Please
          try again or contact support.
        </Typography>,
        {
          position: "top-center",
        }
      );
      setLoading(false);
    } else {
      const response = await dispatch(
        addCreditCard({ token: createdToken.token.id })
      );
      if (response?.meta?.requestStatus === FULFILLED) {
        const payload = {
          user: {
            has_payment_method: true,
          },
        };
        dispatch(updateUser(payload));
        toast.success(
          <Typography color="success">
            Credit card added successfully.
          </Typography>,
          {
            position: "top-center",
          }
        );
        setLoading(false);
        onNextStep();
      } else {
        toast.error(
          <Typography color="error">
            {response?.error?.message ||
              "There was an error trying to add your credit card in our system. Please try again or contact support."}
          </Typography>,
          {
            position: "top-center",
          }
        );
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => handleGiftCodeChange(giftPromoCode), 2000);
    return () => clearTimeout(timeOut);
  }, [debouncedGiftCode]);

  useEffect(() => {
    const timeOut = setTimeout(() => handlePromoCodeChange(promoCode), 2000);
    return () => clearTimeout(timeOut);
  }, [debouncedPromoCode]);

  useEffect(() => {
    authenticated && dispatch(getCreditCards());
  }, [dispatch, authenticated]);

  return (
    <>
      <Button
        variant="text"
        color="info"
        fullWidth
        onClick={toggleShowGiftCard}
      >
        + Add Gift Card
      </Button>

      {showGiftCard && (
        <>
          <Box sx={{ position: "relative" }}>
            <TextField
              placeholder="Enter Gift Card Code"
              value={giftPromoCode}
              onChange={(e) => setGiftPromoCode(e.target.value)}
              fullWidth
              sx={{ mt: 2, mb: 1 }}
            />
            {status === PENDING && (
              <CircularProgress size="1.5rem" className={classes.spinner} />
            )}
            {!giftLog.hasError &&
            giftLog.log !== "empty" &&
            status !== PENDING && (
              <Done
                sx={{ width: "2rem", height: "auto" }}
                color="green"
                className={classes.doneIcon}
              />
            )}
          </Box>
          {giftLog.log !== "empty" && (
            <Typography variant={giftLog.hasError ? "error1" : "success1"}>
              {giftLog.log}
            </Typography>
          )}
        </>
      )}
      <Button
        variant="text"
        color="info"
        fullWidth
        onClick={toggleShowPromoCode}
      >
        + Add Promo Code
      </Button>
      {showPromoCode && (
        <>
          <Box sx={{ position: "relative" }}>
            <TextField
              placeholder="Enter Promo Code"
              value={promoCode}
              onChange={(e) => setPromoNewCode(e.target.value)}
              fullWidth
              sx={{ mt: 2, mb: 1 }}
            />
            {promoCodeStatus === PENDING && (
              <CircularProgress size="1.5rem" className={classes.spinner} />
            )}
            {!promoCodeLog.hasError &&
            promoCodeLog.log !== "empty" &&
            promoCodeStatus?.status !== PENDING && (
              <Done
                sx={{ width: "2rem", height: "auto" }}
                color="green"
                className={classes.doneIcon}
              />
            )}
          </Box>
          {promoCodeLog.log !== "empty" && (
            <span className={classes.couponError}>
              <Typography
                variant={promoCodeLog.hasError ? "error1" : "success1"}
              >
                {promoCodeLog.log}
              </Typography>
            </span>
          )}
        </>
      )}

      <Box display="flex" flexDirection="column" mt={4}>
        <Typography variant="h5">Credit Card</Typography>

        <Typography my={1}>All transactions are secure & encrypted.</Typography>

        {!!creditCard && !isEditing && (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap={2}
            mt={2}
          >
            <div className={classes.rowContainer}>
              <div>
                <Typography color="primary" variant="subtitle1">
                  Card Number
                </Typography>

                <Typography color="secondary" variant="body1">
                  **** **** **** {creditCard?.last4}
                </Typography>
              </div>

              <Button
                variant="text"
                color="primary"
                sx={{ color: "white" }}
                onClick={toggleEditCard}
              >
                Edit Card
              </Button>
            </div>

            <div>
              <Typography color="primary" variant="subtitle1">
                Card Expiration Date
              </Typography>

              <Typography color="secondary" variant="body1">
                {`${creditCard?.expMonth} / ${creditCard?.expYear}`}
              </Typography>
            </div>

            <div>
              <Typography color="primary" variant="subtitle1">
                Card Brand
              </Typography>

              <Typography color="secondary" variant="body1">
                {creditCard?.brand}
              </Typography>
            </div>

            <Button variant="contained" fullWidth onClick={onNextStep}>
              Continue
            </Button>
          </Box>
        )}

        {isEditing && (
          <form onSubmit={onSubmit} className={classes.formContainer}>
            <CardNumberElement
              options={CARD_NUMBER_OPTIONS}
              onChange={handleChange}
              className={classes.stripeInput}
            />

            <CardExpiryElement
              options={CARD_EXPIRATION_NOPTIONS}
              onChange={handleChange}
              className={classes.stripeInput}
            />

            <CardCvcElement
              options={CARD_CVC_OPTIONS}
              onChange={handleChange}
              className={classes.stripeInput}
            />

            <Button
              variant="contained"
              type="submit"
              disabled={loading}
              fullWidth
            >
              Continue
            </Button>

            {error && (
              <Typography variant="body1" sx={{ mt: "1rem" }} color="error">
                {error}
              </Typography>
            )}
          </form>
        )}
      </Box>
    </>
  );
};

export default CreditCard;
