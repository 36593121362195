import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Images
import DefaultProject from "../../../assets/images/default-img.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "100%",
    overflowX: "scroll",
  },
  jobImage: {
    objectFit: "cover",
    borderRadius: "12px",
    marginBottom: "5px",
    height: "200px",
    width: "250px",
  },
}));

const RecentJobsList = ({ jobs }) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={4}
      className={classes.container}
    >
      {jobs.map(({ image, title, service }) => {
        return (
          <Box display="flex" flexDirection="column">
            <img
              src={image ? image : DefaultProject}
              alt={title}
              className={classes.jobImage}
            />

            <Typography variant="h5" mb={1}>
              {title}
            </Typography>

            <Typography color="secondary">{service.name}</Typography>
          </Box>
        );
      })}
    </Box>
  );
};

export default RecentJobsList;
