import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { Typography, Button, Box } from "@mui/material";
import { validationSchema } from "./validations";
import { useYupValidationResolver } from "../../../helpers/useValidationResolver";
import ProjectDescription from "./projectDescription";
import Equipemnt from "./equipment";
import Handyman from "./handyman";
import AdditionalNotes from "./additionalNotes";
import CheckIcon from "@mui/icons-material/Check";
import { useProjectDetails } from "../../../hooks/useProject";
import useSession from "../../../hooks/useSession";
import Availability from "./availability";
import Modal from "../../shared/Modal";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    flexDirection: "row",
  },
  accordionContainer: {
    borderRadius: "12px",
    border: "1px solid #F1F1F1",
  },
}));

const Description = ({
  expanded,
  setExpanded,
  handleChange,
  setGlobalValue,
  watch,
  openCreateContactModal,
}) => {
  const classes = useStyles();
  const [isDone, setIsDone] = useState(false);
  const { authenticated, user } = useSession();
  const isPhoenixLocation = watch("isPhoenixLocation");
  const resolver = useYupValidationResolver(validationSchema(isPhoenixLocation));
  const { projectDetails } = useProjectDetails();
  const [openModal, setOpenModal] = useState(false);
  const [updatedDescriptionData, setUpdatedDescriptionDate] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch: descriptionWatch,
  } = useForm({
    resolver,
    defaultValues: {
      title: projectDetails.title,
      description: projectDetails.description,
      specialEquipment: projectDetails?.specialEquipment || "maybe",
      equipmentDetails: projectDetails?.equipmentDetails || "",
      preferredDate: projectDetails?.preferredDate || "flexible",
      images: projectDetails?.images || [],
      dateTimes: projectDetails?.dateTimes || [],
    },
  });

  const title = watch("title");
  const dateTimes = descriptionWatch("dateTimes");
  const titleForm = descriptionWatch("title");

  const setGeneralValues = (data) => {
    setGlobalValue("special_equipment", data.specialEquipment);
    setGlobalValue("equipment_details", data.equipmentDetails);
    setGlobalValue("preferredDate", data.preferredDate);
    setGlobalValue("notes", data.notes);
    setGlobalValue("title", data.title);
    setGlobalValue("description", data.description);
  };

  const onSubmit = (data) => {
    if (!authenticated || !!!user?.verifiedAt) {
      setIsDone(true);
      setGeneralValues(data);
      if (isPhoenixLocation) {
        setGlobalValue("dateTimes", data.dateTimes);
        openCreateContactModal();
      } else {
        setExpanded("panel4");
      }
      return;
    } else {
      if (isPhoenixLocation) {
        if ((dateTimes.length === 1 || dateTimes.length === 2) && !isDone) {
          setGeneralValues(data);
          setGlobalValue("dateTimes", data.dateTimes);
          setOpenModal(true);
          setHasInteracted(true);
        } else {
          setIsDone(true);
          setGeneralValues(data);
          setGlobalValue("dateTimes", data.dateTimes);
          setExpanded("panel3");
        }
      } else {
        setIsDone(true);
        setGeneralValues(data);
        setExpanded("panel5");
      }
    }
  };

  useEffect(() => {
    setValue("title", title);
  }, [title, setValue]);

  useEffect(() => {
    setGlobalValue("title", titleForm);
  }, [titleForm, setGlobalValue]);

  useEffect(() => {
    if (updatedDescriptionData && expanded !== "panel2") {
      handleSubmit(onSubmit)();
      setUpdatedDescriptionDate(false);
    }
    if (expanded === "panel2") {
      setUpdatedDescriptionDate(true);
    }
  }, [expanded, updatedDescriptionData]);

  return (
    <Accordion
      className={classes.accordionContainer}
      expanded={expanded === "panel2"}
      onChange={handleChange("panel2")}
    >
      {(expanded !== "panel2" && (
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <div
            className={classes.row}
            style={{
              alignItems: "baseline",
              justifyContent: "space-between",
              width: "95%",
            }}
          >
            <Typography variant="title1">Project Description</Typography>
            {isDone && (
              <div className={classes.row} style={{ alignItems: "center" }}>
                <Button
                  variant="text"
                  sx={{
                    color: "#2b8ced",
                    fontFamily: "Urbanist-Medium",
                    display: "flex",
                    fontsize: 14,
                    fontWeight: 500,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: 1.43,
                    letterSpacing: "normal",
                    textAlign: "left",
                  }}
                >
                  Edit
                </Button>
                <CheckIcon color="green" />
              </div>
            )}
          </div>
        </AccordionSummary>
      )) || (
        <AccordionSummary aria-controls="panel2a-content" id="panel2a-header">
          <Typography variant="title1">Project Title</Typography>
        </AccordionSummary>
      )}
      <AccordionDetails>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ProjectDescription control={control} errors={errors} />
          <Equipemnt
            setDescriptionFormValue={setValue}
            control={control}
            errors={errors}
          />
          <Handyman setDescriptionFormValue={setValue} control={control} />
          {isPhoenixLocation && (
            <Availability
              setValue={(value) => setValue("dateTimes", value)}
              value={dateTimes}
              errors={errors}
              hasInteracted={hasInteracted}
            />
          )}
          <AdditionalNotes setDescriptionFormValue={setValue} />
          <Button
            variant="contained"
            sx={{
              color: "white",
              fontFamily: "Urbanist-SemiBold",
              display: "flex",
              marginTop: "1.8rem",
              width: {
                xs: "100%",
                md: "11.9rem",
              },
            }}
            type="submit"
            children="Continue"
          />
        </form>
        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box sx={{ paddingInline: 3, justifyContent: "center" }}>
            <Typography variant="h4" color="primary" align="center" mb={2}>
              Are you sure?
            </Typography>
            <Typography align="center">
              More dates will help your firefighter schedule your project.
            </Typography>
            <Button
              variant="contained"
              sx={{
                color: "white",
                fontFamily: "Urbanist-SemiBold",
                display: "flex",
                marginTop: "1.8rem",
                width: "100%",
              }}
              onClick={() => {
                setOpenModal(false);
                setIsDone(true);
                setExpanded("panel3");
              }}
              children="Continue"
            />
            <Button
              onClick={() => setOpenModal(false)}
              sx={{ color: "primary", width: "100%", marginTop: "1rem" }}
            >
              Add Availability
            </Button>
          </Box>
        </Modal>
      </AccordionDetails>
    </Accordion>
  );
};

Description.propTypes = {
  data: PropTypes.object,
  expanded: PropTypes.string,
  handleChange: PropTypes.func,
  setExpanded: PropTypes.func,
};

export default Description;
