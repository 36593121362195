import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const options = [
  {
    name: "Personal Info",
    icon: (color, opacity) => (
      <AccountCircleIcon
        color={color}
        style={{ opacity }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "personal",
  },
  {
    name: "Payment Methods",
    icon: (color, opacity) => (
      <CreditCardIcon
        color={color}
        style={{ opacity }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "payment",
  },
  {
    name: "Get Support",
    icon: (color, opacity) => (
      <HelpOutlineOutlinedIcon
        color={color}
        style={{ opacity }}
        fontSize="large"
        sx={{ marginRight: "0.8rem" }}
      />
    ),
    section: "support",
  },
];

export default options;
