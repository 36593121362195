import { useState } from "react";
import { Box, Typography, Avatar, Stack, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

//Components
import FirefighterProfileModal from "../FirefighterProfileModal";
import InterestedFirefighterSelected from "../InterestedFirefighterSelected";
import ReadMoreTextContainer from "../../../components/shared/ReadMoreTextContainer";

//Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import FirefighterCheck from "../../../assets/icons/firefighterCheck.svg";

//Services
import ProjectService from "../../../services/projectService";

const useStyle = makeStyles((theme) => ({
  container: {
    marginBlock: "20px",
    borderRadius: "12px",
    border: "1px solid #E8E8E8",
    backgroundColor: "white",
  },
  firefightersContainer: { marginBottom: "10px", width: "100%" },
  shieldIcon: { marginLeft: "5px", width: 14, height: 14 },
  informationItem: { display: "flex", alignItems: "center", marginTop: "8px" },
  informationItemIcon: { height: 22, width: 22, marginRight: "7px" },
  estimateDescription: {
    backgroundColor: "#FAFAFA",
    borderRadius: "12px",
    marginBlock: "15px",
    padding: "15px",
  },
}));

const InterestedFirefighters = ({ project }) => {
  const classes = useStyle();

  const [selectedFirefighter, setSelectedFirefighter] = useState(null);

  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openSelectedModal, setOpenSelectedModal] = useState(false);

  const workId = project.id;

  const { data: interestedFirefighters = [], isLoading } = useQuery({
    enabled: Object.keys(project).length > 0,
    queryKey: ["getInterestedFirefighter", workId],
    retry: 1,
    queryFn: async () => {
      const data = await ProjectService.getJobInterests(workId);
      return data.fireFighters;
    },
  });

  const handleOpenProfileModal = () => setOpenProfileModal(true);
  const handleCloseProfileModal = () => setOpenProfileModal(false);

  const handleCloseSelectedModal = () => setOpenSelectedModal(false);
  const handleOpenSelectedModal = () => {
    handleCloseProfileModal();
    setOpenSelectedModal(true);
  };

  const handleSelectedFirefighter = (firefighterId) => {
    setSelectedFirefighter(firefighterId);
    handleOpenProfileModal();
  };

  if (isLoading) return <Typography>Loading...</Typography>;

  if (interestedFirefighters.length === 0) return null;

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary" align="left" mb={3} p={3}>
        Select Firefighter
      </Typography>

      {interestedFirefighters.map((firefighter, index) => (
        <div key={firefighter.id} className={classes.firefightersContainer}>
          <Box sx={{ paddingInline: 3 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              onClick={() => handleSelectedFirefighter(firefighter.id)}
              sx={{ cursor: "pointer" }}
            >
              <Stack direction="row">
                <Avatar
                  alt={firefighter.firstName}
                  src={firefighter.profilePicture}
                  sx={{
                    width: 80,
                    height: 80,
                    marginRight: 2,
                    color: "lightgrey",
                  }}
                />

                <Stack alignContent="flex-start">
                  <Typography variant="h5">
                    {firefighter.firstName} {firefighter.lastName}
                    <img
                      src={FirefighterCheck}
                      alt="Check"
                      className={classes.shieldIcon}
                    />
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.informationItem}
                  >
                    <LocationOnIcon className={classes.informationItemIcon} />
                    <span>{firefighter.distance} Miles away</span>
                  </Typography>

                  <Typography
                    variant="body2"
                    color="primary"
                    className={classes.informationItem}
                  >
                    <StarIcon className={classes.informationItemIcon} />
                    <span>
                      {firefighter.averageRating}({firefighter.reviewsCount}{" "}
                      ratings)
                    </span>
                  </Typography>
                  <Typography
                    variant="body2"
                    className={classes.informationItem}
                  >
                    <span>
                      {firefighter.completedJobs || 0} Projects Completed
                    </span>
                  </Typography>
                </Stack>
              </Stack>

              <Typography variant="h5">
                ${firefighter.priceEstimate || 0}
              </Typography>
            </Stack>

            <Box className={classes.estimateDescription}>
              <Typography variant="body2">
                <ReadMoreTextContainer
                  text={
                    firefighter.estimateDescription ||
                    "No description provided."
                  }
                  length={100}
                />
              </Typography>
            </Box>
          </Box>

          {interestedFirefighters.length > 0 &&
            interestedFirefighters.length - 1 !== index && (
              <Divider sx={{ mb: 3 }} />
            )}
        </div>
      ))}

      {openProfileModal && selectedFirefighter && workId && (
        <FirefighterProfileModal
          open={openProfileModal}
          firefighterId={selectedFirefighter}
          workId={workId}
          onSelected={handleOpenSelectedModal}
          onClose={handleCloseProfileModal}
        />
      )}

      {openSelectedModal && selectedFirefighter && workId && (
        <InterestedFirefighterSelected
          open={openSelectedModal}
          firefighterId={selectedFirefighter}
          workId={workId}
          onClose={handleCloseSelectedModal}
        />
      )}
    </div>
  );
};

export default InterestedFirefighters;
