import { useState } from "react";
import { Box, Typography, Avatar, Stack, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

//Components
import FirefighterAddTipModal from "./FirefighterAddTipModal";
import FirefighterWriteReviewModal from "./FirefighterWriteReviewModal";
import FirefighterProfileModal from "../FirefighterProfileModal";

//Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import ChatBubble from "@mui/icons-material/ChatBubble";
import AttachMoney from "@mui/icons-material/AttachMoney";
import FirefighterCheck from "../../../assets/icons/firefighterCheck.svg";

//Utils
import { JOB_STATUSES, ROUTES } from "../../../utils/constants";

const useStyle = makeStyles((theme) => ({
  container: {
    marginBlock: "20px",
    borderRadius: "12px",
    border: "1px solid #E8E8E8",
    backgroundColor: "white",
  },
  firefightersContainer: { marginBottom: "10px", width: "100%" },
  shieldIcon: { marginLeft: "5px", width: 14, height: 14 },
  informationItem: { display: "flex", alignItems: "center", marginTop: "8px" },
  informationItemIcon: { height: 22, width: 22, marginRight: "7px" },
  estimateDescription: {
    backgroundColor: "#FAFAFA",
    borderRadius: "12px",
    marginBlock: "15px",
    padding: "15px",
  },
}));

const YourFirefighter = ({ project }) => {
  const classes = useStyle();
  const navigate = useNavigate();

  const [openAddTipModal, setOpenAddTipModal] = useState(false);
  const [openWriteReviewModal, setOpenWriteReviewModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);

  const {
    id,
    fireFighter,
    status,
    type = "",
    workAssignment = {},
    fireFighterTipPaid,
    review,
  } = project;

  const isJob = type === "Job";
  const ffWorkInterestedId = workAssignment?.fireFighterWorkInterestId;

  const handleOpenAddTipModal = () => {
    setOpenAddTipModal(true);
  };

  const handleCloseAddTipModal = () => {
    setOpenAddTipModal(false);
  };

  const handleOpenWriteReviewModal = () => {
    setOpenWriteReviewModal(true);
  };

  const handleCloseWriteReviewModal = () => {
    setOpenWriteReviewModal(false);
  };

  const handleOpenProfileModal = () => setOpenProfileModal(true);
  const handleCloseProfileModal = () => setOpenProfileModal(false);

  const handleOpenFFProfile = () => {
    if (isJob) return;
    handleOpenProfileModal();
  };

  const handleMessages = () => {
    navigate(ROUTES.MESSAGES);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h4" color="primary" align="left" mb={3} p={3}>
        Your Firefighter
      </Typography>

      <div key={fireFighter.id} className={classes.firefightersContainer}>
        <Box sx={{ paddingInline: 3, mb: 4 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ cursor: "pointer" }}
            onClick={handleOpenFFProfile}
          >
            <Stack direction="row">
              <Avatar
                alt={fireFighter.fullName}
                src={fireFighter.profilePicture}
                sx={{
                  width: 80,
                  height: 80,
                  marginRight: 2,
                  color: "lightgrey",
                }}
              />

              <Stack alignContent="flex-start">
                <Typography variant="h5">
                  {fireFighter.fullName}
                  <img
                    src={FirefighterCheck}
                    alt="Check"
                    className={classes.shieldIcon}
                  />
                </Typography>

                {fireFighter.distance && (
                  <Typography
                    variant="body2"
                    className={classes.informationItem}
                  >
                    <LocationOnIcon className={classes.informationItemIcon} />
                    <span>{fireFighter.distance} Miles away</span>
                  </Typography>
                )}

                <Typography
                  variant="body2"
                  color="primary"
                  className={classes.informationItem}
                >
                  <StarIcon className={classes.informationItemIcon} />
                  <span>
                    {fireFighter.averageRating}({fireFighter.reviewsCount}{" "}
                    ratings)
                  </span>
                </Typography>

                <Typography variant="body2" className={classes.informationItem}>
                  <span>
                    {fireFighter.completedJobs || 0} Projects Completed
                  </span>
                </Typography>
              </Stack>
            </Stack>
          </Stack>

          {!isJob && (
            <Stack direction="column" gap={2} my={2}>
              {status === JOB_STATUSES.ASSIGNED && (
                <Button variant="outlined" onClick={handleMessages}>
                  <ChatBubble color="primary" fontSize="small" sx={{ mr: 1 }} />{" "}
                  Message
                </Button>
              )}

              {status === JOB_STATUSES.COMPLETED && (
                <>
                  {!review && (
                    <Button
                      variant="outlined"
                      onClick={handleOpenWriteReviewModal}
                    >
                      <ChatBubble
                        color="primary"
                        fontSize="small"
                        sx={{ mr: 1 }}
                      />
                      Write a Review
                    </Button>
                  )}
                  {!fireFighterTipPaid && (
                    <Button variant="outlined" onClick={handleOpenAddTipModal}>
                      <AttachMoney
                        color="primary"
                        fontSize="small"
                        sx={{ mr: 1 }}
                      />
                      Add a tip
                    </Button>
                  )}
                </>
              )}
            </Stack>
          )}

          {openProfileModal && id && ffWorkInterestedId && (
            <FirefighterProfileModal
              open={openProfileModal}
              firefighterId={ffWorkInterestedId}
              workId={id}
              onClose={handleCloseProfileModal}
              onlyProfile
            />
          )}
        </Box>
      </div>

      {openAddTipModal && (
        <FirefighterAddTipModal
          open={openAddTipModal}
          workId={id}
          onClose={handleCloseAddTipModal}
        />
      )}

      {openWriteReviewModal && (
        <FirefighterWriteReviewModal
          open={openWriteReviewModal}
          workId={id}
          onClose={handleCloseWriteReviewModal}
        />
      )}
    </div>
  );
};

export default YourFirefighter;
