import { useNavigate } from "react-router-dom";
import { Typography, Button, Box, Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Icons
import Check from "../../../../assets/icons/firefighterCheck.svg";
import Success from "../../../../assets/images/success.png";
import Star from "@mui/icons-material/Star";
import AccessTime from "@mui/icons-material/AccessTime";

//Utils
import { ROUTES } from "../../../../utils/constants";

const REVIEW = 5;

const useStyles = makeStyles((theme) => ({
  workImage: {
    border: "0.5px solid gray",
    borderRadius: "10px",
    objectFit: "cover",
    width: 250,
    height: 180,
  },
  firefighterContainer: { backgroundColor: "white", minWidth: 100 },
}));

const ProjectSuccess = ({ profile, work, availability, onClose }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  if (!work) return <Typography>Loading...</Typography>;

  const { title, images, fireFighter } = work;
  const workImage = images.length > 0 ? images[0].url : Success;

  const handleGoToMessages = () => {
    navigate(ROUTES.MESSAGES);
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        position="absolute"
        top={40}
        left={40}
        zIndex={1}
        padding={2}
      >
        <img src={workImage} alt="Work" className={classes.workImage} />

        <Typography variant="h5" mt={1}>
          {title}
        </Typography>

        <Typography display="flex" alignItems="center" mt={0.5} gap={0.5}>
          <AccessTime fontSize="small" />{" "}
          <span>{availability.availableAt}</span>
        </Typography>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="absolute"
        top={120}
        right={70}
        gap={1}
        borderRadius={2}
        padding={2}
        boxShadow={2}
        zIndex={2}
        className={classes.firefighterContainer}
      >
        <Avatar
          alt={fireFighter.fullName}
          src={fireFighter.profilePicture}
          sx={{
            width: 70,
            height: 70,
            color: "lightgrey",
          }}
        />

        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="h5">{fireFighter.fullName}</Typography>
          <img alt="Check" src={Check} height={14} width={14} />
        </Box>

        <Box>
          {Array.from({ length: REVIEW }, (_, index) => (
            <Star key={index} color="primary" fontSize="small" />
          ))}
        </Box>
      </Box>

      <Box mt={40}>
        <Typography variant="h3" align="center" mb={2}>
          Project Scheduled
        </Typography>

        <Typography align="center" mb={3}>
          {fireFighter.fullName} will be notified and contact you to get started
          on your project.
        </Typography>

        <Button variant="contained" fullWidth onClick={handleGoToMessages}>
          Go to Messages
        </Button>
      </Box>
    </>
  );
};

export default ProjectSuccess;
