import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import HelpfulHeroesTextField from "../../../components/shared/HelpfulHeroesTextField";
import HelpfulHeroesPhoneInput from "../../../components/shared/HelpfulHeroesPhoneField";
import LocationInput from "../../../components/NewProject/Location/LocationInput";

const useStyles = makeStyles((theme) => ({
  contactInformationFormRowInput: {
    marginBottom: "1rem !important",
  },
  inputsContainer: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-between",
      alignSelf: "center",
    },
  },
  inputsContainerTwo: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
  },
  form: {
    border: "1px solid #E8E8E8",
    borderRadius: "12px",
    padding: "30px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
}));

const ContactInformation = ({ control, errors, setValue,locationValue, setLocationValue, locationInputValue, setLocationInputValue }) => {
  const classes = useStyles();
  return (
    <>
        <form className={classes.sectionContainer}>
          <Typography sx={{ mb: "0.8rem" }} variant="h5">
            Contact Information
          </Typography>
          <div className={classes.inputsContainer}>
            <HelpfulHeroesTextField
              name="firstName"
              control={control}
              controller
              placeholder="First Name*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "50%" },
                mr: "1rem",
                color: "navy",
              }}
              className={classes.contactInformationFormRowInput}
              error={errors?.firstName}
              helperText={errors?.firstName?.message}
            />
            <HelpfulHeroesTextField
              name="lastName"
              control={control}
              controller
              className={classes.contactInformationFormRowInput}
              placeholder="Last Name"
              sx={{ width: { lg: "100%", md: "40%", xs: "50%" } }}
            />
          </div>
          <div className={classes.inputsContainerTwo}>
            <HelpfulHeroesTextField
              name="email"
              control={control}
              controller
              className={classes.contactInformationFormRowInput}
              placeholder="Email Address*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "100%" },
                alignSelf: { xs: "center", sm: "start" },
                color: "navy",
                mr: { sm: "1rem" },
              }}
              error={errors?.email}
              helperText={errors?.email?.message}
            />
            <HelpfulHeroesPhoneInput
              name="phoneNumber"
              control={control}
              controller
              id="phone-input"
              placeholder="Mobile Phone Number*"
              className={classes.contactInformationFormRowInput}
              sx={{
                width: { lg: "100%", md: "40%", xs: "100%" },
                alignSelf: { xs: "center", sm: "start" },
                color: "navy",
              }}
              error={errors?.phoneNumber}
              helperText={errors?.phoneNumber?.message}
            />
            
          </div>
          <div className={classes.inputsContainerTwo}>
            <LocationInput
              value={locationValue}
              setValue={setLocationValue}
              inputValue={locationInputValue}
              setInputValue={setLocationInputValue}
              formKey="addressAttributes"
              setFormValue={setValue}
              sx={{
                width: { lg: "10%", md: "100%", xs: "100%" },
                alignSelf: { xs: "center", sm: "start" },
                color: "navy",
              }}
              className={classes.contactInformationFormRowInput}
              hideDefaultAddresses
            />
            <HelpfulHeroesTextField
              name="addressAttributes.city"
              control={control}
              controller
              placeholder="City"
              sx={{ 
                width: { lg: "100%", md: "100%", xs: "100%" },
                ml: { sm: "1rem" },
              }}
              className={classes.contactInformationFormRowInput}
            />
          </div>
          <div className={classes.inputsContainer}>
            <HelpfulHeroesTextField
              name="addressAttributes.state"
              control={control}
              controller
              placeholder="State*"
              sx={{
                width: { lg: "100%", md: "40%", xs: "50%" },
                mr: "1rem",
                color: "navy",
              }}
              className={classes.contactInformationFormRowInput}
            />
            <HelpfulHeroesTextField
              name="addressAttributes.zip"
              control={control}
              controller
              className={classes.contactInformationFormRowInput}
              placeholder="Zip*"
              sx={{ width: { lg: "100%", md: "40%", xs: "50%" } }}
            />
          </div>
        </form>
    </>
  );
};

export default ContactInformation;
