import { useNavigate } from "react-router";
import { Grid, Typography, Box, Button } from "@mui/material";
import { useInfiniteQuery } from "@tanstack/react-query";

//Components
import ProjectCard from "../ProjectCard";

//Serivces
import UserService from "../../../services/userService";

//Utils
import { JOB_STATUSES, ROUTES } from "../../../utils/constants";

const ProjectList = ({ statuses = [] }) => {
  const navigate = useNavigate();

  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: ["getAllJobs", statuses],
      queryFn: async ({ pageParam = 1 }) => {
        const params = {
          sort_key: "created_at",
          sort_direction: "desc",
          per_page: 12,
          page: pageParam,
          status: statuses,
        };

        const response = await UserService.getAllJobs(params);
        return response;
      },
      getNextPageParam: (lastPage) => {
        return lastPage.meta.nextPage ? lastPage.meta.nextPage : undefined;
      },
    });

  const handleDetailRedirect = (id, type) => {
    localStorage.setItem("type", type);
    navigate(`${ROUTES.PROJECT_DETAIL}/${id}`);
  };

  const projects = data?.pages?.flatMap((page) => page.jobPosts);

  if (isLoading) return null;

  return (
    <>
      <Grid container spacing={3} justifyContent="start">
        {projects?.length > 0 ? (
          projects.map((project) => (
            <ProjectCard
              onClick={() => {
                if (project.status === JOB_STATUSES.CANCELLED) return;

                handleDetailRedirect(project.id, project.type);
              }}
              project={project}
              key={project.id}
            />
          ))
        ) : (
          <Typography variant="subtitle2" pl={3}>
            No projects found.
          </Typography>
        )}
      </Grid>

      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        {hasNextPage && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => fetchNextPage()}
            disabled={isFetchingNextPage}
          >
            {isFetchingNextPage ? "Loading..." : "Load More"}
          </Button>
        )}
      </Box>
    </>
  );
};

export default ProjectList;
