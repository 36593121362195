/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  ListItemText,
  MenuList,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import options from "./menuOptions";

const MyAccountMenuList = ({
  selectedSection,
  setSelectedSection,
  section,
  setSection,
}) => {
  React.useEffect(() => {
    if (!section) {
      setSection("personal");
      setSelectedSection("personal");
    }
  }, []);

  return (
    <MenuList
      sx={{
        border: "1px solid #E8E8E8",
        borderRadius: "12px",
        padding: "12px",
        paddingBlock: "16px",
        height: "fit-content",
        width: "25%",
      }}
    >
      {options.map((item, index) => (
        <MenuItem
          onClick={() => {
            setSection(item.section);
            setSelectedSection(item.section);
          }}
          key={index}
          sx={{ bgcolor: "white", mb: "1rem" }}
        >
          <ListItemIcon>
            {item.icon(
              selectedSection === item.section ? "blueNavy" : "primary",
              selectedSection === item.section ? "1" : "0.5"
            )}
          </ListItemIcon>
          <ListItemText>
            <Typography
              color={selectedSection === item.section ? "blueNavy" : "primary"}
              sx={{ opacity: selectedSection === item.section ? "1" : "0.5" }}
              variant="h5"
            >
              {item.name}
            </Typography>
          </ListItemText>
        </MenuItem>
      ))}
    </MenuList>
  );
};

export default MyAccountMenuList;
