import React from "react";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions/userActions";
import TopBar from "../../components/shared/TopBar";
import EditIcon from "@mui/icons-material/Edit";
import {
  Typography,
  ListItem,
  ListItemText,
  Button,
  ListItemAvatar,
  Avatar,
  IconButton,
} from "@mui/material";
import { useQueryParam, StringParam } from "use-query-params";
import MyAccountMenuList from "../../components/MyAccount/MyAccountMenuList";
import PersonalInfoForm from "../../components/MyAccount/PersonalInfoForm";
import useSession from "../../hooks/useSession";
import ShareBar from "../../components/shared/ShareBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PaymentMethodsForm from "../../components/MyAccount/PaymentMethodsForm";
import getBase64 from "../../helpers/getBase64";
import BottomBar from "../../components/shared/BottomBar";
import { LEGAL_LINKS } from "../../utils/contants";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
  },
  myAccountHeader: {
    alignItems: "center !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  editPictureIcon: {
    borderRadius: "50%",
    width: "1.1rem",
    height: "1.1rem",
    position: "absolute",
    bottom: "0",
    right: "1.5rem",
    backgroundColor: "#2b8ced",
    padding: "0.3rem",
    cursor: "pointer",
  },
  myAccount: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
  },
  myAccountBody: {
    display: "flex",
    marginTop: "2.5rem",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "0rem",
    },
  },
  rightSection: {
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileBtn: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "70%",
      alignSelf: "center",
      marginTop: "2rem",
    },
  },
  logoutBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  PaymentMethodsContainer: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    width: "100%",
  },
}));

const Input = styled("input")({
  display: "none",
});

const MyAccount = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMobile, setDesktop] = React.useState(window.innerWidth < 600);
  const { user } = useSession();
  const [section, setSection] = useQueryParam("section", StringParam);
  const [selectedSection, setSelectedSection] = React.useState(section);
  const [image, setImage] = React.useState(user?.profilePicture);
  const updateMedia = () => {
    setDesktop(window.innerWidth < 600);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const addImage = (event) => {
    setImage({ data: event, filename: "profile-image.png" });
  };

  const handleChange = (event) => {
    getBase64(event?.target?.files[0], addImage);
  };

  const handleSupport = () => {
    window.open(LEGAL_LINKS.SUPPORT_URL);
    if (!isMobile) {
      setSelectedSection("personal");
      setSection("personal");
    } else {
      setSelectedSection();
      setSection();
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TopBar />
        <div className={classes.myAccount}>
          <ListItem className={classes.myAccountHeader} alignItems="flex-start">
            <ListItemAvatar sx={{ position: "relative", alignSelf: "start" }}>
              <label htmlFor="icon-button-file">
                <Input
                  accept="image/*"
                  id="icon-button-file"
                  type="file"
                  onChange={handleChange}
                />
                <div className={classes.images} style={{ marginTop: "10px" }}>
                  <Avatar
                    sx={{
                      width: "5.5rem",
                      height: "5.5rem",
                      marginRight: "2rem",
                      bgcolor: "#f1f1f1 !important",
                      cursor: "pointer",
                    }}
                  >
                    {(image?.data !== "" && (
                      <img
                        style={{ width: "100%", marginRight: "5px" }}
                        src={image?.data || image}
                        alt=""
                      />
                    )) || (
                      <Typography variant="h3">{`${user?.firstName?.charAt(
                        0
                      )}${user?.lastName?.charAt(0)}`}</Typography>
                    )}
                  </Avatar>
                  <div className={classes.editPictureIcon}>
                    <EditIcon style={{ color: "white", fontSize: "1.1rem" }} />
                  </div>
                </div>
              </label>
            </ListItemAvatar>
            <ListItemText
              style={{ alignSelf: "start" }}
              primary={
                <span>
                  <Typography variant="h2">{`${user?.firstName} ${user?.lastName}`}</Typography>
                </span>
              }
              secondary={
                <Typography variant="h7">{`${user?.email}`}</Typography>
              }
            />
            <div className={classes.logoutBtn}>
              <Button
                onClick={() => dispatch(logout())}
                variant="outlined"
                color="primary"
              >
                Log out
              </Button>
            </div>
          </ListItem>
          <div className={classes.myAccountBody}>
            {isMobile && (
              <>
                {selectedSection === "personal" && (
                  <div className={classes.myAccountBody}>
                    <IconButton
                      sx={{
                        paddingLeft: "0",
                        alignSelf: "start",
                        marginBottom: "1rem",
                      }}
                      onClick={() => setSelectedSection("other")}
                      marginBottom="23px"
                    >
                      <ArrowBackIcon
                        sx={{ marginRight: "0.2rem", width: "1rem" }}
                        color="darkGray"
                      />
                      <Typography variant="body3" color="darkGray">
                        Back
                      </Typography>
                    </IconButton>
                    <PersonalInfoForm image={image} />
                  </div>
                )}
                {selectedSection === "payment" && (
                  <div className={classes.PaymentMethodsContainer}>
                    <IconButton
                      sx={{
                        paddingLeft: "0",
                        alignSelf: "start",
                        display: "flex",
                        marginBottom: "1rem",
                      }}
                      onClick={() => setSelectedSection("other")}
                      marginBottom="23px"
                    >
                      <ArrowBackIcon
                        sx={{ marginRight: "0.2rem", width: "1rem" }}
                        color="darkGray"
                      />
                      <Typography variant="body3" color="darkGray">
                        Back
                      </Typography>
                    </IconButton>
                    <PaymentMethodsForm />
                  </div>
                )}
                {selectedSection === "support" && handleSupport()}
              </>
            )}
            {isMobile &&
              selectedSection !== "personal" &&
              selectedSection !== "payment" &&
              selectedSection !== "support" && (
                <MyAccountMenuList
                  section={section}
                  setSection={setSection}
                  selectedSection={selectedSection}
                  setSelectedSection={setSelectedSection}
                />
              )}
            {!isMobile && (
              <MyAccountMenuList
                section={section}
                setSection={setSection}
                selectedSection={selectedSection}
                setSelectedSection={setSelectedSection}
              />
            )}
            <div className={classes.rightSection}>
              {selectedSection === "personal" && (
                <PersonalInfoForm image={image} />
              )}
              {selectedSection === "payment" && <PaymentMethodsForm />}
              {selectedSection === "support" && handleSupport()}
            </div>
            <div className={classes.mobileBtn}>
              <Button
                onClick={() => dispatch(logout())}
                variant="outlined"
                color="primary"
              >
                Log out
              </Button>
            </div>
          </div>
        </div>
      </div>
      <BottomBar />
    </div>
  );
};

export default MyAccount;
