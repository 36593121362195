import { Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  options: {
    display: "flex",
  },
  option: {
    width: "100%",
    backgroundColor: theme.palette.lightGrey.main
  },
  sectionContainer: {
    marginBottom: "1.7rem",
  },
}));

const PropertyType = ({setValue, watch}) => {
    const classes = useStyles();
    const type = watch("propertyType");
  return (
    <form className={classes.sectionContainer}>
        <Typography sx={{ mb: "0.8rem" }}>
          Is this for a residential or business property?
        </Typography>
        <div className={classes.options}>
        <Button
          variant="outlined"
          style={{
            backgroundColor: type === 0 ? "" : "white",
            marginRight: 8
          }}
          onClick={() => {
            setValue("propertyType", 0);
          }}
          children="residential"
          className={classes.option}
        />
        <Button
          variant="outlined"
          style={{backgroundColor: type === 1 ? "" : "white"}}
          onClick={() => {
            setValue("propertyType", 1);
          }}
          children="business"
          className={classes.option}
        />
        </div>
    </form>
  );
}

export default PropertyType;