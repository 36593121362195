export default function defaultAddressOption(defaultAddress) {
  if (!defaultAddress) {
    return null;
  }

  return {
    description: defaultAddress,
    structured_formatting: {
      main_text: defaultAddress,
      main_text_matched_substrings: [],
      secondary_text: "",
    },
    terms: defaultAddress.split(",").map((term, index) => ({
      offset: index * 10,
      value: term.trim(),
    })),
    types: ["geocode"],
  };
}
