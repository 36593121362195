import httpClient from "../httpClient";

class SettingsService {
  static async getPhoenixZipCodes() {
    const cachedData = localStorage.getItem("phoenixZipCodes");
    if (cachedData) {
      return JSON.parse(cachedData);
    }
    const {data} = await httpClient.get("settings/work_enabled_zip_codes");
    localStorage.setItem("phoenixZipCodes", JSON.stringify(data.zipCodes));
    return data.zipCodes;
  }
}

export default SettingsService;