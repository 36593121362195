import { Link } from "react-router-dom";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";

//Utils
import { ROUTES } from "../../../../utils/constants";
import { LEGAL_LINKS } from "../../../../utils/contants";

const ContactForm = ({
  control,
  handleSubmit,
  onSubmit,
  errors,
  isSubmitting,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ overflowY: "auto", maxHeight: "650px" }}
    >
      <Typography variant="h3" align="center" mb={2}>
        Create Your Account
      </Typography>

      <Typography align="center">
        Create an account to manage your project and communicate with your
        firefighter.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        mt={3}
      >
        <Box display="flex" flexDirection="row" gap={2} mb={2}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: "First Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName?.message}
                fullWidth
              />
            )}
          />

          <Controller
            name="lastName"
            control={control}
            rules={{ required: "Last Name is required" }}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName?.message}
                fullWidth
              />
            )}
          />
        </Box>

        <Controller
          name="email"
          control={control}
          rules={{
            required: "Email Address is required",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Invalid email format",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Email Address"
              error={!!errors.email}
              helperText={errors.email?.message}
              sx={{ mb: 2 }}
              fullWidth
            />
          )}
        />

        <Controller
          name="phoneNumber"
          control={control}
          rules={{ required: "Phone Number is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Phone Number"
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber?.message}
              sx={{ mb: 2 }}
              fullWidth
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{
            required: "Password is required",
            minLength: {
              value: 6,
              message: "Password must be at least 6 characters",
            },
          }}
          render={({ field }) => (
            <TextField
              {...field}
              type="password"
              placeholder="Password"
              error={!!errors.password}
              helperText={errors.password?.message}
              sx={{ mb: 2 }}
              fullWidth
            />
          )}
        />

        <Typography mb={2}>
          By creating an account, you agree to the{" "}
          <Link
            to={LEGAL_LINKS.TERMS_OF_USE}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            Terms of Use
          </Link>{" "}
          &{" "}
          <Link
            to={LEGAL_LINKS.PRIVACY_POLICY}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
          .
        </Typography>

        <Button
          variant="contained"
          fullWidth
          type="submit"
          disabled={isSubmitting}
        >
          Create Account
        </Button>
      </Box>

      <Typography align="center" mt={2}>
        Already have an account?{" "}
        <Link to={ROUTES.LOGIN} style={{ textDecoration: "none" }}>
          Log in.
        </Link>
      </Typography>
    </Box>
  );
};

export default ContactForm;
