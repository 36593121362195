import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

//Components
import CreditCard from "../CreditCard";

const stripeLoaded = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PaymentCreditCardForm = ({ setGiftCode, setPromoCode, onNextStep }) => {
  return (
    <Elements stripe={stripeLoaded}>
      <CreditCard
        setGiftCode={setGiftCode}
        setPromoCode={setPromoCode}
        onNextStep={onNextStep}
      />
    </Elements>
  );
};

export default PaymentCreditCardForm;
