import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  options: {
    display: "flex",
    marginBottom: "1rem",
  },
  option: {
    width: "100%",
    backgroundColor: theme.palette.lightGrey.main
  },
  selectOption: {
    backgroundColor: "white",
    color: theme.palette.darkGray.main
  },
  sectionContainer: {
    marginBottom: "1.7rem",
  }
}));

const AboutUs = ({setValue, watch}) => {
  const classes = useStyles();
  const usedHelpfulHeroes = watch("usedHelpfulHeroes");
  const aboutUs = watch("aboutUs");
  return (
    <div className={classes.sectionContainer}>
      <Typography sx={{ mb: "0.8rem" }} variant="h5">
        Have you used Helpful Heroes before?
      </Typography>

      <div className={classes.options}>
        <Button
         type="button"
          variant="outlined"
          style={{ 
            backgroundColor: usedHelpfulHeroes === true ? "" : "white",
            marginRight: 8
          }}
          children="yes"
          className={classes.option}
          onClick={() => {
            setValue("usedHelpfulHeroes", true);
          }}
        />
        <Button
          type="button"
          variant="outlined"
          onClick={() => {
            setValue("usedHelpfulHeroes", false);
          }}
          style={{ backgroundColor: usedHelpfulHeroes === false ? "" : "white" }}
          children="no"
          className={classes.option}
        />
        </div>
      <Box>
      <FormControl fullWidth>
        <InputLabel id="simple-select-label">How did you hear about us?</InputLabel>
        <Select
          labelId="simple-select-label"
          id="simple-select"
          value={aboutUs ?? ""}
          onChange={(e)=>setValue("aboutUs", e.target.value)}
          label="How did you hear about us?"
        >
          <MenuItem value={0} className={classes.selectOption}>TV</MenuItem>
          <MenuItem value={1} className={classes.selectOption}>Digital Ad</MenuItem>
          <MenuItem value={2} className={classes.selectOption}>Web Search</MenuItem>
          <MenuItem value={3} className={classes.selectOption}>Referral</MenuItem>
          <MenuItem value={4} className={classes.selectOption}>Other</MenuItem>
        </Select>
      </FormControl>
    </Box>
    </div>
  );
}

export default AboutUs;