//Images
import jim from "../assets/images/firefighters/jim.png";
import dalton from "../assets/images/firefighters/dalton.png";
import anthony from "../assets/images/firefighters/anthony.png";
import andy from "../assets/images/firefighters/andy.png";
import peter from "../assets/images/firefighters/peter.png";
import lane from "../assets/images/firefighters/lane.png";
import landon from "../assets/images/firefighters/landon.png";
import jenna from "../assets/images/firefighters/jenna.png";

export const firefighters = [
  { name: "Jim H", image: jim, opacity: 0.5 },
  { name: "Dalton K", image: dalton },
  { name: "Anthony R", image: anthony },
  { name: "Andy D", image: andy, opacity: 0.5 },
  { name: "Peter D", image: peter, opacity: 0.5 },
  { name: "Lane J", image: lane },
  { name: "Landon D", image: landon },
  { name: "Jenna F", image: jenna, opacity: 0.5 },
];
