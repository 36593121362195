import { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
} from "@mui/material";

//Components
import AddressCard from "../AddressCard";
import LocationInput from "../../shared/LocationInput";

const AddressesList = ({
  address,
  addresses,
  editAddress,
  locationValue,
  setLocationValue,
  inputValue,
  loadingAddress,
  setInputValue,
  setValue,
  defaultAddress,
  setDefaultAddress,
  handleChange,
  handleSelectAddress,
  handleRemoveAddress,
  handleSubmitEditAddress,
  handleCancel,
}) => {
  const navigateToForm = useRef(null);

  useEffect(() => {
    if (editAddress) {
      setTimeout(() => {
        if (navigateToForm.current) {
          navigateToForm.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 200);
    }
  }, [editAddress]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        mt={4}
        sx={{
          border: "1px solid #E8E8E8",
          borderRadius: "12px",
          padding: "30px",
          width: "100%",
        }}
      >
        <Typography variant="h5" mb={4}>
          My Addresses
        </Typography>

        <Box display="flex" flexDirection="column" mb={4}>
          <Typography variant="h5" mb={2}>
            Default
          </Typography>

          <AddressCard
            address={address}
            onSelectAddress={handleSelectAddress}
            onRemoveAddress={handleRemoveAddress}
          />
        </Box>

        {addresses && addresses.length > 0 && (
          <Box display="flex" flexDirection="column" mb={4}>
            {addresses.length > 1 && (
              <Typography variant="h5" mb={2}>
                All Addresses
              </Typography>
            )}

            {addresses.map((item) => {
              if (item.id === address.id) return null;
              return (
                <AddressCard
                  key={item.id}
                  address={item}
                  canRemove
                  onSelectAddress={handleSelectAddress}
                  onRemoveAddress={handleRemoveAddress}
                />
              );
            })}
          </Box>
        )}
      </Box>

      {!editAddress && loadingAddress && (
        <Typography variant="h5" my={2}>
          Loading...
        </Typography>
      )}

      {editAddress && (
        <Box
          component="div"
          ref={navigateToForm}
          display="flex"
          flexDirection="column"
          mt={4}
          sx={{
            border: "1px solid #E8E8E8",
            borderRadius: "12px",
            padding: "30px",
            width: "100%",
          }}
        >
          <Typography variant="h5" mb={4}>
            Edit Address
          </Typography>

          <Box component="form" display="flex" flexDirection="column">
            <TextField
              placeholder="Address Name"
              sx={{ mb: 2 }}
              value={editAddress.label}
              onChange={(e) => {
                handleChange({ label: e.target.value });
              }}
            />

            <Box mb={2}>
              <LocationInput
                value={
                  editAddress?.fullAddress
                    ? editAddress.fullAddress
                    : locationValue
                }
                setValue={setLocationValue}
                inputValue={inputValue}
                setInputValue={setInputValue}
                formKey="addressAttributes.fullAddress"
                setFormValue={setValue}
                components
              />
            </Box>

            <TextField
              placeholder="Address Line 2"
              sx={{ mb: 2 }}
              value={editAddress.unit}
              onChange={(e) =>
                handleChange({
                  unit: e.target.value,
                })
              }
            />

            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap={2}
            >
              <TextField
                placeholder="Frisco"
                sx={{ mb: 2 }}
                value={editAddress.city}
                onChange={(e) => {
                  handleChange({ city: e.target.value });
                }}
              />
              <TextField
                placeholder="Texas"
                sx={{ mb: 2 }}
                value={editAddress.state}
                onChange={(e) => {
                  handleChange({ state: e.target.value });
                }}
              />
              <TextField
                placeholder="72568"
                sx={{ mb: 2 }}
                value={editAddress.zip}
                onChange={(e) => {
                  handleChange({ zip: e.target.value });
                }}
              />
            </Box>

            {editAddress.id && (
              <FormGroup sx={{ mb: 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={defaultAddress}
                      onChange={(e) => setDefaultAddress(e.target.checked)}
                    />
                  }
                  label="Make Default Address"
                />
              </FormGroup>
            )}

            <Box display="flex" flexDirection="row" gap={2}>
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmitEditAddress}
              >
                Save Changes
              </Button>
              <Button variant="text" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AddressesList;
