import React from "react";
import { IconButton, Badge } from "@mui/material";

const HelpfulHeroesIconButton = ({
  selected,
  onClick,
  children,
  badgeCount,
  ...props
}) => {
  return (
    <IconButton
      size="large"
      onClick={onClick}
      {...props}
      sx={{
        mr: "0.5rem",
        minWidth: "3rem",
        minHeight: "3rem",
        backgroundColor: selected ? "#022752" : "#f1f1f1",
      }}
    >
      <Badge badgeContent={badgeCount} color="error">
        {children}
      </Badge>
    </IconButton>
  );
};

export default HelpfulHeroesIconButton;
