import { Box, Button, Typography } from "@mui/material";
import { Email, Send } from "@mui/icons-material";
import ReactCodeInput from "react-verification-code-input";

const VerificationForm = ({
  verificationType,
  verificationCode,
  handleVerificationCode,
  onVerifyUser,
  onResend,
  onResendByEmail,
  verifyUserLoading,
  resendVerificationCodeLoading,
  resendVerificationCodeByEmailLoading,
  contactInfo,
  handleResendByEmail,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{ overflowY: "scroll", maxHeight: "650px" }}
    >
      <Typography variant="h3" align="center" mb={2}>
        Verify your {verificationType === "email" ? "email" : "phone"}
      </Typography>

      <Typography align="center">
        We sent a verification code to {contactInfo}. Enter the code below to
        verify your {verificationType}.
      </Typography>

      <Box my={2}>
        <ReactCodeInput
          values={verificationCode}
          onChange={handleVerificationCode}
          type="number"
          fields={6}
          autoFocus
        />
      </Box>

      <Box display="flex" flexDirection="column" my={2} gap={2}>
        {verificationType === "phone" && (
          <Button
            variant="text"
            fullWidth
            type="submit"
            onClick={handleResendByEmail}
          >
            <Email fontSize="small" sx={{ mr: 1 }} />
            Verify by email
          </Button>
        )}

        <Button
          variant="contained"
          fullWidth
          disabled={verifyUserLoading}
          onClick={onVerifyUser}
        >
          Verify
        </Button>

        <Button
          variant="text"
          fullWidth
          type="submit"
          disabled={
            resendVerificationCodeLoading ||
            resendVerificationCodeByEmailLoading
          }
          onClick={verificationType === "email" ? onResendByEmail : onResend}
        >
          <Send fontSize="small" sx={{ mr: 1 }} />
          Resend Verification Code
        </Button>
      </Box>
    </Box>
  );
};

export default VerificationForm;
