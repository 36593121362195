import React, { useMemo } from "react";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TopBar from "../../components/shared/TopBar";
import Location from "../../components/NewProject/Location";
import Description from "../../components/NewProject/Description";
import Estimate from "../../components/NewProject/Estimate";
import ContactInformation from "../../components/NewProject/ContactInformation";
import PaymentInformation from "../../components/NewProject/PaymentInformation";
import useSession from "../../hooks/useSession";
import { useQueryParam, StringParam } from "use-query-params";
import { useAllProjectTypes } from "../../hooks/useProject";
import { useDispatch } from "react-redux";
import { getAllProjectTypes } from "../../redux/actions/projectActions";
import Footer from "../../components/shared/Footer";

import CreateProjectBack from "./CreateProjectBack";
import CreateProjectTitle from "./CreateProjectTitle";
import CreateProjectPhotos from "./CreateProjectPhotos";
import CreateContact from "../../components/NewProject/CreateContact";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
  },
  createProjectContainer: {
    paddingLeft: "10rem",
    paddingRight: "10rem",
    marginTop: "2rem",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: "3rem",
      paddingRight: "3rem",
    },
  },
  formSection: {
    marginBottom: "1rem",
  },
  projectService: {
    marginLeft: "1rem",
  },
  projectStatus: {
    backgroundColor: "#7C92AA33",
    paddingInline: "10px",
    paddingBlock: "8px",
    borderRadius: "6px",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#7C92AA",
  },
  projectDescriptionContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "1rem",
  },
  projectButtons: {
    justifyContent: "flex-end",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "center",
    },
  },
  relative: {
    position: "relative",
  },
}));

const CreateProject = ({ props }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");
  const { authenticated, user } = useSession();
  const { handleSubmit, setValue, getValues, watch } = useForm();
  const [showVerification, setShowVerification] = React.useState(false);
  const dispatch = useDispatch();
  const [service, setService] = useQueryParam("service", StringParam);
  const { allProjectTypes } = useAllProjectTypes();
  const [selectedService, setSelectedService] = React.useState();
  const [isOpen, setIsOpen] = React.useState(true);

  const [openCreateContactModal, setOpenCreateContactModal] =
    React.useState(false);

  const isPhoenixLocation = watch("isPhoenixLocation");
  const values = getValues();

  const isAbleToSubmit = useMemo(() => {
    if (
      values.description &&
      values.preferredDate &&
      values.title &&
      values.addressAttributes?.fullAddress &&
      (values.specialEquipment || values.special_equipment)
    ) {
      if (values.specialEquipment === "yes") {
        return !!values.equipment_details;
      } else {
        return true;
      }
    }
  }, [values]);
  if (!service) {
    setService(localStorage.getItem("serviceLocal"));
  } else {
    localStorage.removeItem("service");
  }
  if (isOpen) {
    dispatch(getAllProjectTypes());
    setIsOpen(false);
  }

  React.useEffect(() => {
    setSelectedService(
      allProjectTypes?.filter((item) => item.name === service)
    );
  }, [allProjectTypes, dispatch, service]);

  const handleChange = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenCreateContactModal = () => {
    setOpenCreateContactModal(true);
  };

  const handleCloseCreateContactModal = () => {
    setOpenCreateContactModal(false);
  };

  const showContactInformation =
    (!authenticated ||
      showVerification ||
      (authenticated && !user?.verifiedAt)) &&
    !isPhoenixLocation;

  const showContactInformationForPhoenix =
    (!authenticated ||
      showVerification ||
      (authenticated && !user?.verifiedAt)) &&
    isPhoenixLocation;

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TopBar />
        <main className={classes.createProjectContainer}>
          <CreateProjectBack />

          <Grid container marginBottom={2}>
            <Grid item sm={12}>
              <CreateProjectTitle setValue={setValue} watch={watch} />
            </Grid>
          </Grid>

          {selectedService && (
            <div className={classes.projectDescriptionContainer}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                className={classes.projectStatus}
              >
                DRAFT
              </Typography>
              <Typography
                variant="subtitle1"
                color="primary"
                className={classes.projectService}
              >
                {selectedService[0]?.name}
              </Typography>
            </div>
          )}
          <Grid container spacing={3}>
            <Grid
              item
              sm={12}
              lg={4}
              sx={{ display: "flex", alignSelf: "flex-start" }}
            >
              <CreateProjectPhotos setValue={setValue} watch={watch} />
            </Grid>
            <Grid item sm={12} lg={8}>
              <div>
                <div className={classes.formSection}>
                  <Location
                    expanded={expanded}
                    setExpanded={setExpanded}
                    handleChange={handleChange}
                    setGlobalValue={setValue}
                  />
                </div>

                <div className={classes.formSection}>
                  <Description
                    expanded={expanded}
                    setExpanded={setExpanded}
                    handleChange={handleChange}
                    setGlobalValue={setValue}
                    watch={watch}
                    openCreateContactModal={handleOpenCreateContactModal}
                  />
                </div>

                {showContactInformation && (
                  <div className={classes.formSection}>
                    <ContactInformation
                      expanded={expanded}
                      setExpanded={setExpanded}
                      handleChange={handleChange}
                      showVerification={showVerification}
                      setShowVerification={setShowVerification}
                    />
                  </div>
                )}

                {showContactInformationForPhoenix && openCreateContactModal && (
                  <CreateContact
                    open={openCreateContactModal}
                    onClose={handleCloseCreateContactModal}
                    setExpanded={setExpanded}
                  />
                )}

                {!isPhoenixLocation && (
                  <div className={classes.formSection}>
                    <PaymentInformation
                      expanded={expanded}
                      setExpanded={setExpanded}
                      handleChange={handleChange}
                      setGlobalValue={setValue}
                    />
                  </div>
                )}

                <div className={classes.formSection}>
                  <Estimate
                    expanded={expanded}
                    setExpanded={setExpanded}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    selectedService={selectedService}
                    getValues={getValues}
                    isAbleToSubmit={isAbleToSubmit}
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default CreateProject;
