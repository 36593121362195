import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import moment from "moment";
import WarningIcon from "@mui/icons-material/Warning";

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  time: {
    display: "flex",
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
    width: "100%",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem",
    borderRadius: "10px",
    marginTop: "1rem",
  },
  borderError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
  },
}));

const Availability = ({ setValue, value, errors, hasInteracted }) => {
  const classes = useStyles();

  const hasThreeDates = value.length === 3;
  const showError = hasInteracted && !hasThreeDates;
  const [error, setError] = useState(false);

  const handleChange = (newValue, index) => {
    setError(null);
    if (!newValue) {
      return;
    }
    const dateString = moment(newValue).utcOffset(0, true).toISOString();
    const updatedDateTimes = [...value];
    updatedDateTimes[index] = dateString;
    setValue(updatedDateTimes);
  };

  return (
    <div>
      <Typography variant="title1">
        When would you like your project completed?
      </Typography>
      {errors?.dateTimes && (
        <Typography variant="title1" color="error.main">
          {errors?.dateTimes?.message}
        </Typography>
      )}
      <div className={classes.timeContainer}>
        {Array.from({ length: 3 }).map((_, index) => (
          <div className={classes.time} key={index}>
            <MobileDateTimePicker
              sx={{ width: "100%" }}
              defaultValue={value[index] ? moment(value[index]) : null}
              onChange={(newValue) => handleChange(newValue, index)}
              label="+ Add date & time"
              minDateTime={moment.utc()}
              minutesStep={30}
              className={errors?.dateTimes ? classes.borderError : ""}
              onAccept={(val) => {
                !val && handleChange(null, index);
              }}
              onError={() => {
                setError("Please select a valid date and time");
              }}
              format="hh:mm A MMM Do YYYY"
            />
          </div>
        ))}
        {error && <Typography color="error.main">{error}</Typography>}
      </div>
      <Typography>
        *Please note that your project will be scheduled according to your
        firefighters availability.
      </Typography>
      <div
        className={classes.warningContainer}
        style={{
          backgroundColor: showError ? "#F73C440F" : "#FAFAFA",
        }}
      >
        <WarningIcon
          color={showError ? "green" : "primary"}
          sx={{ mr: "1rem" }}
        />
        <Typography
          sx={{
            color: showError ? "error.main" : "primary.main",
          }}
        >
          We recommend submitting 3 dates & times to help your firefighter
          schedule the project at a time that works best for you.
        </Typography>
      </div>
    </div>
  );
};
export default Availability;
