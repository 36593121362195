import { useNavigate } from "react-router";

import { IconButton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

//Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const useStyles = makeStyles((theme) => ({
  buttonBack: {
    display: "flex",
    justifyContent: "start",
    marginBottom: "10px",
  },
  buttonIcon: { marginRight: "0.9rem", color: "#7C92AA" },
}));

const CreateProjectBack = ({ route = "/" }) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <IconButton className={classes.buttonBack} onClick={() => navigate(route)}>
      <ArrowBackIcon className={classes.buttonIcon} />
      <Typography variant="body3" color="#7C92AA">
        Back
      </Typography>
    </IconButton>
  );
};

export default CreateProjectBack;
