import React from "react";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  timeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  time: {
    display: "flex",
    marginBottom: "0.5rem",
    width: "100%",
  },
  warningContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "1rem",
    borderRadius: "10px",
    marginTop: "1rem",
  },
  borderError: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
    "&:focus .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.error.main,
    },
  },
  sectionContainer: {
    marginBottom: "1.7rem",
  },
}));

const Availability = ({ setValue, availabilities = [] }) => {
  const classes = useStyles();

  const handleChange = (newValue, index) => {
    if (!newValue) {
      return;
    }
    const dateString = moment(newValue).format();
    const updatedDateTimes = [...availabilities];
    updatedDateTimes[index] = dateString;
    setValue(updatedDateTimes);
  };

  return (
    <div className={classes.sectionContainer}>
      <Typography sx={{ mb: "0.8rem" }}>
        Is there a preferred date and time?
      </Typography>
      <div className={classes.timeContainer}>
        <div className={classes.time}>
          <MobileDateTimePicker
            sx={{ width: "100%" }}
            value={availabilities[0] ? moment(availabilities[0]) : null}
            onChange={(newValue) => handleChange(newValue, 0)}
            label="+ Add date & time"
            minDateTime={moment.utc()}
            minutesStep={30}
            onAccept={(val) => {
              !val && handleChange(null, 0);
            }}
            format="hh:mm A MMM Do YYYY"
          />
        </div>
      </div>
    </div>
  );
};
export default Availability;
