import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

//Components
import TopBar from "../../components/shared/TopBar";
import ProjectDetailSecondColumn from "../../components/ProjectDetailColumns/secondColumn";
import { resetProjectDetails } from "../../redux/actions/projectActions";
import Footer from "../../components/shared/Footer";
import ProjectService from "../../services/projectService";
import CreateProjectBack from "../CreateProject/CreateProjectBack";
import ProjectDetailsActions from "./ProjectDetailsActions";
import { JOB_STATUSES, ROUTES } from "../../utils/constants";
import ChipStatus from "../../components/shared/ChipStatus";
import YourFirefighter from "./YourFirefighter";
import ProjectInformation from "./ProjectInformation";
import InterestedFirefighters from "./InterestedFirefighters";
import { setProjectInformation } from "../../redux/slices/projectSlice";
import EditWorkModal from "./EditWorkModal";
import MyAvailability from "./MyAvailability";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: 1,
  },
  projectDetailPadding: {
    paddingLeft: "10rem",
    paddingRight: "10rem",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignSelf: "center",
    alignItems: "center",
  },
  projectDetailRow: {
    display: "flex",
    alignSelf: "center",
    alignItems: "center",
    gap: "15px",
  },
  avatar: {
    backgroundColor: "lightGrey",
    marginRight: "0.688rem",
  },
  avatarText: {
    marginLeft: "0.875rem",
  },
  icon: {
    width: "1.125rem",
    color: "#022752",
  },
  twoColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  firstColumn: {
    marginTop: "2.875rem",
    marginRight: "2rem",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      width: "100%",
    },
  },
}));

const ProjectDetail = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { id } = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const [type, setType] = useState(null);
  const [openEditWorkModal, setOpenEditWorkModal] = useState(false);

  const { data: jobData, isLoading: isLoadingJob } = useQuery({
    queryKey: ["getProjectById"],
    enabled: !!id && type === "Job",
    queryFn: () => ProjectService.getProjectById(id),
  });

  const { data: workData, isLoading: isLoadingWork } = useQuery({
    queryKey: ["getWorkById", +id],
    enabled: !!id && type === "Work",
    queryFn: () => ProjectService.getWorkById(id),
  });

  const handleOpenEditWorkModal = () => {
    setOpenEditWorkModal(true);
  };

  const handleCloseEditWorkModal = () => {
    setOpenEditWorkModal(false);
  };

  useEffect(() => {
    dispatch(resetProjectDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedType = localStorage.getItem("type");
    if (storedType) {
      setType(storedType);
    }
  }, []);

  useEffect(() => {
    //TODO: I will improve this in a next PR
    if (type === "Job") {
      if (jobData?.job) {
        setProjectDetails(jobData.job);
        dispatch(setProjectInformation(jobData.job));
      }
    } else {
      if (workData?.work) {
        setProjectDetails(workData.work);
        dispatch(setProjectInformation(workData.work));
      }
    }
  }, [jobData, workData, type, dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <TopBar />
        <div className={classes.projectDetailPadding}>
          {isLoadingJob ||
          isLoadingWork ||
          Object.keys(projectDetails).length === 0 ? (
            <Typography>Loading...</Typography>
          ) : (
            <>
              <CreateProjectBack route={ROUTES.MY_PROJECTS} />
              <div className={classes.titleRow}>
                <Typography variant="title2">
                  {projectDetails?.title}
                </Typography>

                <ProjectDetailsActions
                  project={projectDetails}
                  type={type}
                  handleOpenEditWorkModal={handleOpenEditWorkModal}
                />
              </div>

              <div className={classes.projectDetailRow}>
                <ChipStatus
                  status={
                    projectDetails?.status === JOB_STATUSES.ASSIGNED
                      ? JOB_STATUSES.SCHEDULED
                      : projectDetails?.status
                  }
                />
                {projectDetails?.service?.name && (
                  <Typography variant="body3" color="#acacac">
                    {projectDetails.service.name}
                  </Typography>
                )}
              </div>

              <div className={classes.twoColumns}>
                <div className={classes.firstColumn}>
                  <ProjectInformation
                    serviceIcon={projectDetails?.service?.icon}
                    serviceName={projectDetails?.service?.name}
                    address={projectDetails?.address?.fullAddress}
                    preferredDate={projectDetails?.preferredDate}
                    avgCost={`$${projectDetails?.service?.lowestAvgCost} - $${projectDetails?.service?.highestAvgCost}`}
                    status={projectDetails.status}
                    totalPrice={projectDetails?.totalPrice}
                  />

                  {projectDetails?.fireFighter &&
                    (type === "Job" ||
                      (type !== "Job" &&
                        (projectDetails?.status === JOB_STATUSES.ASSIGNED ||
                          projectDetails?.status ===
                            JOB_STATUSES.COMPLETED))) && (
                      <YourFirefighter project={projectDetails} />
                    )}

                  {type !== "Job" &&
                    projectDetails?.status === JOB_STATUSES.WAITING && (
                      <InterestedFirefighters project={projectDetails} />
                    )}

                  {type !== "Job" &&
                    projectDetails?.status === JOB_STATUSES.PENDING && (
                      <MyAvailability
                        availability={projectDetails?.userAvailabilities}
                      />
                    )}
                </div>
                <ProjectDetailSecondColumn
                  project={projectDetails}
                  type={type}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <Footer />
      {openEditWorkModal && type === "Work" && (
        <EditWorkModal
          open={openEditWorkModal}
          project={projectDetails}
          onClose={handleCloseEditWorkModal}
        />
      )}
    </div>
  );
};

export default ProjectDetail;
