import { useState } from "react";
import { Box, Typography, Checkbox, Avatar, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";

//Components
import Modal from "../../shared/Modal";

//Client
import httpClient from "../../../httpClient";

//Icons
import FirefighterCheck from "../../../assets/icons/firefighterCheck.svg";
import FirefighterMiles from "../../../assets/icons/firefighterMiles.svg";
import DarkCircleIcon from "../../../assets/icons/DarkCircleIcon";
import BlueCircleCheckIcon from "../../../assets/icons/BlueCircleCheckIcon";

const FirefighterOption = ({ firefighter, checked, onChange }) => {
  return (
    <Box
      sx={{
        borderRadius: 2,
        p: 2,
        mb: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: checked ? "#fff" : "#fafafa",
        border: checked ? "2px solid #2196F3" : "2px solid #E0E0E0",
      }}
    >
      <Box display="flex" alignItems="center">
        <Avatar
          alt={firefighter.fullName}
          src={firefighter.profilePicture}
          sx={{ mr: 2, width: 61, height: 61, backgroundColor: "lightgrey" }}
        />
        <Box>
          <Typography variant="h5" mb="3px">
            {firefighter.fullName}
            <img
              alt="Check"
              src={FirefighterCheck}
              height={14}
              width={14}
              style={{ marginLeft: "3px" }}
            />
          </Typography>
          <Typography variant="body2" color="textSecondary">
            <img
              alt="Location"
              src={FirefighterMiles}
              width={9}
              height={11}
              style={{ marginRight: "3px" }}
            />
            {firefighter.distance} Miles away
          </Typography>
        </Box>
      </Box>
      <Checkbox
        checked={checked}
        onChange={onChange}
        icon={<DarkCircleIcon />}
        checkedIcon={<BlueCircleCheckIcon />}
      />
    </Box>
  );
};

const SelectFirefighterModal = ({
  open,
  onClose,
  fullAddress,
  setGlobalValue,
  onSubmit,
}) => {
  const [selectedFirefighters, setSelectedFirefighters] = useState([]);

  const { data: firefighters = [], loading: loadingFirefighters } = useQuery({
    enabled: open,
    queryKey: ["getFirefighters", fullAddress],
    queryFn: async () => {
      const response = await httpClient.get("/user/fire_fighters/used", {
        params: {
          full_address: fullAddress,
        },
      });
      return response.data.fireFighters;
    },
  });

  const handleFirefighterChange = (firefighterId) => {
    setSelectedFirefighters((prevSelected) => {
      let updatedSelected;

      if (prevSelected.includes(firefighterId)) {
        updatedSelected = prevSelected.filter((id) => id !== firefighterId);
      } else {
        if (prevSelected.length >= 3) {
          return prevSelected;
        }
        updatedSelected = [...prevSelected, firefighterId];
      }

      setGlobalValue(
        "fire_fighter_works_attributes",
        updatedSelected.map((id) => ({
          fire_fighter_id: id,
        }))
      );

      return updatedSelected;
    });
  };

  const handleSubmitToAllChange = () => {
    setSelectedFirefighters([]);
    setGlobalValue("fire_fighter_works_attributes", []);
  };

  return (
    <Modal open={open} onClose={() => onClose(selectedFirefighters)}>
      <Typography
        variant="h3"
        component="h3"
        align="center"
        my={2}
        color="primary"
      >
        Select Firefighters
      </Typography>

      <Typography variant="body2" align="center" mb={2}>
        You can choose to submit your project directly to firefighters you've
        previously worked with.
      </Typography>

      <Typography variant="body2" align="center" mb={2}>
        Select up to 3 firefighters or submit your project to all firefighters
        in your area.
      </Typography>

      {loadingFirefighters ? (
        <Typography align="center">Loading...</Typography>
      ) : (
        <Box
          sx={{
            height: firefighters.length > 2 ? "420px" : undefined,
            overflowY: "scroll",
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              p: 2,
              mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "#fff",
              border: "2px solid #E0E0E0",
            }}
          >
            <Typography variant="h5">Submit to all firefighters</Typography>
            <Checkbox
              checked={selectedFirefighters.length === 0}
              onChange={() => handleSubmitToAllChange()}
              icon={<DarkCircleIcon />}
              checkedIcon={<BlueCircleCheckIcon />}
            />
          </Box>

          {firefighters.length > 0 &&
            firefighters.map((firefighter) => (
              <FirefighterOption
                key={firefighter.id}
                firefighter={firefighter}
                checked={selectedFirefighters.includes(firefighter.id)}
                onChange={() => handleFirefighterChange(firefighter.id)}
              />
            ))}
        </Box>
      )}

      <Button
        variant="contained"
        fullWidth
        sx={{ mt: 3 }}
        onClick={() => onSubmit(selectedFirefighters)}
      >
        Continue
      </Button>
    </Modal>
  );
};

export default SelectFirefighterModal;
