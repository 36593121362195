const BlueCircleCheckIcon = () => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM12.5312 7.03125H12.5C12.8125 6.75 12.8125 6.28125 12.5 5.96875C12.2188 5.6875 11.75 5.6875 11.4688 5.96875L8 9.46875L6.53125 8C6.21875 7.6875 5.75 7.6875 5.46875 8C5.15625 8.28125 5.15625 8.75 5.46875 9.03125L7.46875 11.0312C7.75 11.3438 8.21875 11.3438 8.53125 11.0312L12.5312 7.03125Z"
      fill="#2B8CED"
    />
  </svg>
);

export default BlueCircleCheckIcon;
