import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";

//Components
import Modal from "../../shared/Modal";
import ContactForm from "./ContactForm";
import VerificationForm from "./VerificationForm";

//Services
import UserService from "../../../services/userService";

//Hooks
import useSession from "../../../hooks/useSession";

//Actions
import { getUser } from "../../../redux/actions/userActions";

const CreateContact = ({ open, onClose, setExpanded }) => {
  const dispatch = useDispatch();
  const { authenticated, user } = useSession();

  const [showVerification, setShowVerification] = useState(false);
  const [verificationByEmail, setVerificationByEmail] = useState(false);
  const [verificationCode, setVerificationCode] = useState([]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      password: "",
    },
  });

  const signUp = useMutation({
    mutationFn: UserService.createAccount,
    onSuccess: () => setShowVerification(true),
    onError: (error) => {
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
    },
  });

  const verifyUser = useMutation({
    mutationFn: UserService.verifyUser,
    onSuccess: () => {
      toast.success(
        <Typography color="success">Account verified successfully.</Typography>,
        {
          position: "top-center",
        }
      );
      dispatch(getUser());
      setExpanded("panel5");
    },
    onError: (error) => {
      toast.error(
        <Typography color="error">
          The code is invalid. Please try again.
        </Typography>,
        {
          position: "top-center",
        }
      );
    },
  });

  const resendVerificationCode = useMutation({
    mutationFn: UserService.resendVerifyCode,
    onSuccess: () => {
      toast.success(
        <Typography color="success">
          A new code verification has been sended
        </Typography>,
        { position: "top-center" }
      );
    },
    onError: () => {
      toast.error(
        <Typography color="success">
          There was a problem, please try again.
        </Typography>,
        { position: "top-center" }
      );
    },
  });

  const resendVerificationCodeByEmail = useMutation({
    mutationFn: UserService.resendVerifyCodeByEmail,
    onSuccess: () => {
      toast.success(
        <Typography color="success">
          A new code verification has been sended
        </Typography>,
        { position: "top-center" }
      );
    },
    onError: () => {
      toast.error(
        <Typography color="success">
          There was a problem, please try again.
        </Typography>,
        { position: "top-center" }
      );
    },
  });

  const handleVerificationCode = (value) => {
    setVerificationCode(value);
  };

  const onSubmit = (user) => {
    signUp.mutate({ user });
  };

  const onVerifyUser = () => {
    verificationCode.length === 6 && verifyUser.mutate({ verificationCode });
  };

  const onResend = () => {
    resendVerificationCode.mutate();
  };

  const onResendByEmail = () => {
    resendVerificationCodeByEmail.mutate();
  };

  const handleResendByEmail = () => {
    onResendByEmail();
    setVerificationByEmail(true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      {showVerification || (authenticated && !!!user?.verifiedAt) ? (
        <VerificationForm
          verificationType={verificationByEmail ? "email" : "phone"}
          verificationCode={verificationCode}
          handleVerificationCode={handleVerificationCode}
          onVerifyUser={onVerifyUser}
          onResend={onResend}
          handleResendByEmail={handleResendByEmail}
          onResendByEmail={onResendByEmail}
          verifyUserLoading={verifyUser.isPending}
          resendVerificationCodeLoading={resendVerificationCode.isPending}
          resendVerificationCodeByEmailLoading={
            resendVerificationCodeByEmail.isPending
          }
          contactInfo={
            verificationByEmail ? getValues("email") : getValues("phoneNumber")
          }
        />
      ) : (
        <ContactForm
          control={control}
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          errors={errors}
          isSubmitting={signUp.isPending}
        />
      )}
    </Modal>
  );
};

export default CreateContact;
