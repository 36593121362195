import { useState } from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

//Components
import Modal from "../../../../components/shared/Modal";

//Services
import ProjectService from "../../../../services/projectService";

const useStyles = makeStyles((theme) => ({
  projectTitleInput: {
    width: "160px",
    display: "flex",
    alignSelf: "center",
    border: "none",
    outline: "none",
    fontSize: "60px",
    marginBottom: "30px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
    "& .MuiInputBase-input::placeholder": {
      color: "#7C92AA",
      textAlign: "center",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.primary.main,
      textAlign: "center",
    },
  },
}));

const FirefighterAddTipModal = ({ open, workId, onClose }) => {
  const classes = useStyles();
  const [tip, setTip] = useState("");

  const handleTipChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^0-9.]/g, "");
    const finalValue = sanitizedValue.replace(/\.([0-9]*)\./g, "$1.");
    const limitedValue = finalValue.replace(/^(\d{5}).*/, "$1");
    setTip(limitedValue);
  };

  const addTip = useMutation({
    mutationFn: ({ workId, tip }) => ProjectService.addTip(workId, tip),
    onSuccess: () => {
      toast.success(
        <Typography color="success">Tip sent successfully</Typography>,
        {
          position: "top-center",
        }
      );

      onClose();
    },
    onError: (error) => {
      toast.error(<Typography color="error">{error}</Typography>, {
        position: "top-center",
      });
    },
  });

  const handleAddTip = () => {
    addTip.mutate({ workId, tip: parseFloat(tip) });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box display="flex" flexDirection="column">
        <TextField
          placeholder="$0"
          variant="standard"
          value={tip}
          onChange={handleTipChange}
          className={classes.projectTitleInput}
          InputProps={{ disableUnderline: true, style: { fontSize: "60px" } }}
        />

        <Typography variant="h3" align="center" mb={3}>
          Add a tip
        </Typography>

        <Typography align="center">
          Would you like to add a tip? 100% of your tip goes directly to the
          Firefighter.
        </Typography>

        <Box display="flex" flexDirection="column" mt={5} gap={3}>
          <Button
            variant="contained"
            onClick={handleAddTip}
            disabled={addTip.isPending}
          >
            Add Tip ${tip || 0}
          </Button>

          <Button variant="text" onClick={onClose}>
            No Thanks
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default FirefighterAddTipModal;
