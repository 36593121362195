import { useState } from "react";
import { Typography, Box, Button } from "@mui/material";

const ReadMoreTextContainer = ({ text, textStyle, readMoreStyle, length }) => {
  const [readMore, setReadMore] = useState(false);
  const textToShow = readMore ? text : `${text.slice(0, length)}...`;

  return (
    <Box>
      {text.length > length ? (
        <Box>
          <Typography style={textStyle}>{textToShow}</Typography>
          <Button
            variant="text"
            onClick={() => setReadMore(!readMore)}
            style={readMoreStyle}
          >
            {readMore ? "Read Less" : "Read More"}
          </Button>
        </Box>
      ) : (
        <Typography style={textStyle}>{text}</Typography>
      )}
    </Box>
  );
};

export default ReadMoreTextContainer;
