/* eslint-disable no-unused-vars */
import { Button, CircularProgress, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import TopBar from "../../components/shared/TopBar";
import { useStatus } from "../../hooks/useStatus";
import { resetPassword } from "../../redux/actions/userActions";
import {
  FULFILLED,
  PENDING,
  REJECTED,
} from "../../redux/constants/actionStatusConstants";
import { makeStyles } from "@mui/styles";
import { useYupValidationResolver } from "../../helpers/useValidationResolver";
import { validationSchema } from "./validations";
import { useNavigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  resetPasswordBody: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "5rem",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      marginTop: "3rem",
    },
  },
  resetPasswordForm: {
    display: "flex",
    flexDirection: "column",
    marginTop: "2rem",
    width: "25rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ResetPassword = () => {
  const { status } = useStatus(resetPassword);
  const classes = useStyles();
  const dispatch = useDispatch();
  const resolver = useYupValidationResolver(validationSchema);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver });

  const onSubmit = async (data) => {
    const payload = {
      password: data.password,
      passwordConfirmation: data.passwordConfirmation,
    };
    const response = await dispatch(resetPassword(payload));
    if (response?.meta?.requestStatus === FULFILLED) {
      toast.success(
        <Typography color="success">
          The password has been changed succesfully
        </Typography>,
        {
          position: "top-center",
        }
      );
      navigate("/");
    }
    if (response?.meta?.requestStatus === REJECTED) {
      toast.error(
        <Typography color="error">
          There was a problem, please try again
        </Typography>,
        {
          position: "top-center",
        }
      );
    }
  };

  return (
    <div className={classes.signUp}>
      <TopBar />
      <div className={classes.resetPasswordBody}>
        <Typography variant="h3">Set your new password</Typography>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className={classes.resetPasswordForm}
        >
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...register("password", {
                  required: true,
                })}
                {...field}
                variant="outlined"
                placeholder="Password"
                type="password"
                sx={{ width: "100%", mt: "1rem", mb: "0.5rem" }}
                error={errors?.password}
                helperText={errors?.password?.message}
              />
            )}
          />
          <Controller
            name="passwordConfirmation"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...register("passwordConfirmation", {
                  required: true,
                })}
                {...field}
                variant="outlined"
                placeholder="Password confirmation"
                type="password"
                sx={{ width: "100%", mt: "1rem", mb: "0.5rem" }}
                error={errors?.passwordConfirmation}
                helperText={errors?.passwordConfirmation?.message}
              />
            )}
          />
          <Button
            type="submit"
            disabled={status === PENDING}
            sx={{ color: "white", mt: "2rem" }}
            variant="contained"
            color="navy"
          >
            {(status === PENDING && (
              <CircularProgress
                sx={{
                  color: "#022752",
                  width: "1.5rem !important",
                  height: "1.5rem !important",
                }}
              />
            )) ||
              "Reset Password"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
