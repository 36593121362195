import httpClient from "../httpClient";
import parseError from "../utils/parseError";

class ProjectService {
  static getAllProjectTypes() {
    return httpClient.get("/services");
  }

  static getProjectChatBoard(id) {
    return httpClient.get(`/user/job_posts/${id}/chat_boards`);
  }

  static getProjectByIdOld(id) {
    return httpClient.get(`/user/jobs/${id}`);
  }

  static postJob(job) {
    return httpClient.post("/user/jobs", job);
  }

  static postWork(work) {
    return httpClient.post("/user/works", work);
  }

  static updateJob(jobId, job) {
    return httpClient.put(`/user/jobs/${jobId}`, { job });
  }

  static sendMessage(jobId, data) {
    return httpClient.post(
      `/user/job_posts/${jobId}/chat_boards/messages`,
      data
    );
  }

  static verifyLocation(data) {
    return httpClient.get("/near", data);
  }

  static async addTip(workId, tip) {
    try {
      const response = await httpClient.post(
        `/user/job_posts/${workId}/tip_fire_fighter`,
        {
          tip,
        }
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getJobInterests(jobId) {
    try {
      const { data } = await httpClient.get(`/user/works/${jobId}/interests`);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getFirefighter(data) {
    const { ffId, workId } = data;

    try {
      const { data } = await httpClient.get(
        `/user/works/${workId}/interests/${ffId}`
      );
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getFirefighterReviews(ffId) {
    try {
      const { data } = await httpClient.get(
        `/user/fire_fighters/${ffId}/reviews`
      );
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async assignFFToWork(payload) {
    try {
      const response = await httpClient.post(
        `/user/works/${payload.workId}/assign`,
        {
          work: {
            ...payload.data,
          },
        }
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getProjectById(id) {
    try {
      const { data } = await httpClient.get(`/user/jobs/${id}`);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getWorkById(id) {
    try {
      const { data } = await httpClient.get(`/user/works/${id}`);
      return data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async cancelJob(id, reason) {
    try {
      const response = await httpClient.post(
        `/user/jobs/${id}/cancel?job[cancelation_reason]=${reason}`
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async cancelWork(id, reason) {
    try {
      const response = await httpClient.post(
        `/user/works/${id}/cancel?work[cancelation_reason]=${reason}`
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async updateWork(id, work) {
    try {
      const response = await httpClient.put(`/user/works/${id}`, { work });
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async postReview(payload) {
    try {
      const response = await httpClient.post(
        `/user/job_posts/${payload.workId}/reviews`,
        payload.data
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async getInvoice(workId) {
    try {
      const response = await httpClient.get(`/user/works/${workId}/invoices`);
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }

  static async confirmInvoice(workId) {
    try {
      const response = await httpClient.post(
        `/user/works/${workId}/invoices/confirm`
      );
      return response.data;
    } catch ({ response: { data } }) {
      throw parseError(data);
    }
  }
}

export default ProjectService;
