/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useDispatch } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Alert,
  Button,
  Typography,
  IconButton,
  Toolbar,
  Box,
  AppBar,
  Menu,
  MenuItem,
  Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import helpfulHeroesIcon from "../../../assets/icons/hh-icon.svg";
import { useNavigate } from "react-router";
import {
  getUser,
  logout,
  newNotifications,
  getUnseenMessages
} from "../../../redux/actions/userActions";
import useSession from "../../../hooks/useSession";
import HelpfulHeroesIconButton from "./IconButton";
import ChatWhiteIcon from "../../../assets/icons/messageON.svg";
import ChatNavyIcon from "../../../assets/icons/message.svg";
import useNewNotifications from "../../../hooks/useNewNotifications";
import useUnseenMessages from "../../../hooks/useUnseenMessages";

const useStyles = makeStyles((theme) => ({
  desktopMenu: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileMenu: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  mobileMenuIcon: {},
}));

const TopBar = () => {
  const pathname = window.location.pathname;
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authenticated, info, user } = useSession();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { unseenNotifications } = useNewNotifications();
  const { unseenMessages } = useUnseenMessages();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate("/menu");
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const notificationsPage = pathname === "/notifications";
  const messagesPage = pathname === "/messages";
  const myAccountPage = pathname === "/my-account";
  const loginSignUpPage = pathname === "/login" || pathname === "/sign-up";

  React.useEffect(() => {
    if (authenticated) {
      dispatch(getUser());
      dispatch(newNotifications());
      dispatch(getUnseenMessages());
    }
  }, [dispatch]);

  // if (loginSignUpPage) return null;
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!!info?.admin_user_uid && (
        <AppBar position="static">
          <Alert variant="outlined" severity="warning">
            Impersonating as {info.uid} ({user.fullName})
          </Alert>
        </AppBar>
      )}
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() =>
              authenticated ? navigate("/my-projects") : navigate("/")
            }
          >
            <img
              alt="helpful heroes logo"
              src={helpfulHeroesIcon}
              width="40px"
            />
          </IconButton>

          {(authenticated && !!info && !!!user?.verifiedAt && (
            <>
              <div className={classes.desktopMenu}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => dispatch(logout())}
                >
                  Log out
                </Button>
              </div>
              <div className={classes.mobileMenu}>
                <Button
                  id="basic-button"
                  aria-controls="basic-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MenuIcon
                    sx={{ width: "2.5rem", height: "auto" }}
                    color="black"
                  />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => dispatch(logout())}>
                    Log out
                  </MenuItem>
                </Menu>
              </div>
            </>
          )) ||
            (!authenticated && (
              <>
                <div className={classes.desktopMenu}>
                  <Button color="navy" onClick={() => navigate("/login")}>
                    Log in
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate("/sign-up")}
                  >
                    Sign Up
                  </Button>
                </div>
                <div className={classes.mobileMenu}>
                  <Button
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <MenuIcon
                      sx={{ width: "2.5rem", height: "auto" }}
                      color="black"
                    />
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>Log in</MenuItem>
                    <MenuItem onClick={handleClose}>Sign up</MenuItem>
                  </Menu>
                </div>
              </>
            )) || (
              <>
                {authenticated && !!user?.verifiedAt && (
                  <>
                    <div className={classes.desktopMenu}>
                      <HelpfulHeroesIconButton
                        selected={notificationsPage}
                        onClick={() => navigate("/notifications")}
                      >
                        <Badge
                          color="secondary"
                          variant="dot"
                          overlap="circular"
                          invisible={!unseenNotifications}
                        >
                          <NotificationsIcon
                            style={{
                              color: notificationsPage ? "#fff" : "#022752",
                            }}
                          />
                        </Badge>
                      </HelpfulHeroesIconButton>
                      <HelpfulHeroesIconButton
                        selected={messagesPage}
                        onClick={() => navigate("/messages")}
                      >
                        <Badge
                          color="secondary"
                          variant="dot"
                          overlap="circular"
                          invisible={!unseenMessages}
                        >
                          {(messagesPage && (
                            <img alt="chat" src={ChatWhiteIcon} />
                          )) || <img alt="chat" src={ChatNavyIcon} />}
                        </Badge>
                      </HelpfulHeroesIconButton>
                      <HelpfulHeroesIconButton
                        selected={myAccountPage}
                        onClick={() => navigate("/my-account")}
                      >
                        <Typography
                          style={{
                            color: myAccountPage ? "#fff" : "#022752",
                          }}
                          variant="h8"
                        >{`${user?.firstName?.charAt(
                          0
                        )}${user?.lastName?.charAt(0)}`}</Typography>
                      </HelpfulHeroesIconButton>
                    </div>
                    <div className={classes.mobileMenu}>
                      <Button
                        id="basic-button"
                        aria-controls="basic-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                      >
                        <MenuIcon
                          sx={{ width: "2.5rem", height: "auto" }}
                          color="black"
                        />
                      </Button>
                    </div>
                  </>
                )}
              </>
            )}
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopBar;
