import { Typography, Box, Avatar, Button, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@tanstack/react-query";

//Components
import Modal from "../../../components/shared/Modal";
import ReadMoreTextContainer from "../../../components/shared/ReadMoreTextContainer";
import RecentJobsList from "../RecentJobsList";
import Reviews from "../Reviews";

//Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import Construction from "@mui/icons-material/Construction";
import VerifiedUser from "@mui/icons-material/VerifiedUser";
import FirefighterCheck from "../../../assets/icons/firefighterCheck.svg";

//Services
import ProjectService from "../../../services/projectService";

//Helpers
import { formatDateToMonthYear } from "../../../helpers/dates";

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    position: "relative",
    width: "fit-content",
  },
  availabilityContainer: {
    borderRadius: "12px",
    backgroundColor: "#FAFAFA",
    padding: "15px",
    marginBottom: "12px",
  },
  divider: { marginBlock: "25px" },
  childrenContainer: { overflowY: "scroll", maxHeight: "650px" },
  firefigtherAvatar: {
    width: 75,
    height: 75,
    backgroundColor: "lightgrey",
  },
  firefigtherAvatarIcon: { position: "absolute", right: 0, bottom: 0 },
  profileInformationItem: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
  },
  profileInformationItemIcon: { height: 22, width: 22, marginRight: "7px" },
  verifiedHeros: {
    borderRadius: "12px",
    border: "1px solid #d2d2d2",
    padding: "16px",
    marginTop: "20px",
    gap: "10px",
  },
}));

const FirefighterProfileModal = ({
  open,
  firefighterId,
  workId,
  onClose,
  onSelected = () => {},
  onlyProfile = false,
}) => {
  const classes = useStyles();

  const { data: profile, isLoading } = useQuery({
    enabled: open && !!firefighterId && !!workId,
    queryKey: ["getFirefighterInformation", firefighterId, workId],
    queryFn: async () => {
      const data = await ProjectService.getFirefighter({
        ffId: firefighterId,
        workId,
      });
      return data.fireFighterWorkInterest || {};
    },
  });

  const {
    fireFighter,
    distance,
    availability,
    priceEstimate,
    estimateDescription,
  } = profile || {};

  const handleSelectFirefighter = () => {
    onSelected();
  };

  if (!profile) return null;

  return (
    <Modal open={open} onClose={onClose}>
      {isLoading ? (
        <Typography align="center">Loading...</Typography>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          className={classes.childrenContainer}
        >
          <Typography variant="h5" align="center" mb={4}>
            {onlyProfile ? "Your Firefighter" : "Select Firefighter"}
          </Typography>

          <div className={classes.avatarContainer}>
            <Avatar
              alt={fireFighter.fullName}
              src={fireFighter.profilePicture}
              className={classes.firefigtherAvatar}
            />

            <img
              alt="Check"
              src={FirefighterCheck}
              height={18}
              width={18}
              className={classes.firefigtherAvatarIcon}
            />
          </div>

          <Box display="flex" flexDirection="column">
            <Typography variant="h3" mt={2}>
              {fireFighter.firstName} {fireFighter.lastName}
            </Typography>

            <Typography
              variant="body2"
              className={classes.profileInformationItem}
            >
              <LocationOnIcon className={classes.profileInformationItemIcon} />
              <span>{distance} Miles away</span>
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              className={classes.profileInformationItem}
            >
              <StarIcon className={classes.profileInformationItemIcon} />
              <span>
                {fireFighter.averageRating} ({fireFighter.reviewsCount} ratings)
              </span>
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              className={classes.profileInformationItem}
            >
              <Construction className={classes.profileInformationItemIcon} />
              <span>{fireFighter.completedJobs} Projects Completed</span>
            </Typography>

            <Typography
              variant="body2"
              color="primary"
              className={classes.profileInformationItem}
            >
              <VerifiedUser className={classes.profileInformationItemIcon} />
              <span>
                Hero since {formatDateToMonthYear(fireFighter.createdAt)}
              </span>
            </Typography>
          </Box>

          {!onlyProfile && (
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleSelectFirefighter}
            >
              Select Firefighter
            </Button>
          )}

          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={classes.verifiedHeros}
          >
            <VerifiedUser color="primary" />

            <Box display="flex" flexDirection="column">
              <Typography variant="h5" mb={1}>
                Verified Heroes
              </Typography>

              <Typography>
                All Helpful Heroes are verified with ID and criminal background
                checks.
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography variant="h5" mt={3} mb={1}>
              Availability
            </Typography>

            {availability && availability.length > 0 ? (
              availability.map((item, index) => (
                <div key={index} className={classes.availabilityContainer}>
                  <Typography variant="h5" align="left">
                    {item.availableAt}
                  </Typography>
                </div>
              ))
            ) : (
              <Typography>No availability</Typography>
            )}
          </Box>

          <Divider className={classes.divider} />

          <Box display="flex" flexDirection="column">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Typography variant="h5" mb={1}>
                Project estimate
              </Typography>

              <Typography variant="h6" color="primary">
                ${priceEstimate || 0}
              </Typography>
            </Box>

            {estimateDescription ? (
              <Typography>{estimateDescription}</Typography>
            ) : (
              <Typography>No information</Typography>
            )}
          </Box>

          <Divider className={classes.divider} />

          <Box display="flex" flexDirection="column">
            <Typography variant="h5" mb={1}>
              Skills & Experience
            </Typography>

            {fireFighter.skillSetDescription ? (
              <ReadMoreTextContainer
                text={fireFighter.skillSetDescription}
                length={150}
              />
            ) : (
              <Typography>No information</Typography>
            )}
          </Box>

          <Divider className={classes.divider} />

          <Box display="flex" flexDirection="column">
            <Typography variant="h5" mb={1}>
              Recent jobs
            </Typography>

            {fireFighter.recentJobs.length > 0 ? (
              <RecentJobsList jobs={fireFighter.recentJobs} />
            ) : (
              <Typography>No information</Typography>
            )}
          </Box>

          <Divider className={classes.divider} />

          <Box display="flex" flexDirection="column">
            <Typography variant="h5" mb={1}>
              Reviews
            </Typography>

            {fireFighter.reviewsCount > 0 ? (
              <Reviews
                ffId={fireFighter.id}
                averageRating={fireFighter.averageRating}
                reviewsCount={fireFighter.reviewsCount}
              />
            ) : (
              <Typography>No information</Typography>
            )}
          </Box>

          {!onlyProfile && (
            <Button
              variant="contained"
              sx={{ mt: 3 }}
              onClick={handleSelectFirefighter}
            >
              Select Firefighter
            </Button>
          )}
        </Box>
      )}
    </Modal>
  );
};

export default FirefighterProfileModal;
