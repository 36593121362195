import { IconButton } from "@mui/material";

import { makeStyles } from "@mui/styles";

//Icons
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  photosContainer: {
    width: "100%",
    marginInline: "100px",
    maxHeight: "445px",
    overflowY: "auto",
    borderRadius: "12px",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  photosGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "0px",
    gridAutoRows: "150px",
  },
  photoItem: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    marginBottom: "-5px",
  },
  photoRemove: {
    position: "absolute",
    top: 5,
    right: 5,
    background: "white",
  },
}));

const CreateProjectPhotosList = ({ images, setValue }) => {
  const classes = useStyles();

  const handleRemoveImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setValue("images", updatedImages);
  };

  return (
    <div className={classes.photosContainer}>
      <div className={classes.photosGrid}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              gridColumn: index === 0 ? "span 2" : "span 1",
              position: "relative",
            }}
          >
            <img
              src={image?.data ? image.data : image.url}
              alt={image?.filename ? image.filename : "Item"}
              className={classes.photoItem}
            />
            <IconButton
              onClick={() => handleRemoveImage(index)}
              size="small"
              className={classes.photoRemove}
            >
              <CloseIcon style={{ color: "black" }} />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CreateProjectPhotosList;
